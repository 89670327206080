import { FC, useMemo } from 'react';
import { Input, Form, Typography, Space } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import DefaultTextView from './DefaultTextView';

const { Text } = Typography;
const { TextArea } = Input;

const FormItemLegalText: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
  language,
  defaultLanguage,
  hidden,
}) => {
  const fieldValue = Form.useWatch(['legalText', defaultLanguage]);
  const publishedItemValues = useMemo(
    () => ({
      legalText: fieldChanges?.legalText.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      key={`legalText-${language}`}
      hidden={hidden}
      name={['legalText', language!]}
      label={
        <Space direction="horizontal">
          {fieldChanges?.legalText.hasChanges ? (
            <Text mark>
              Legal text{' '}
              {
                <PublishedItem initialValues={publishedItemValues}>
                  <FormItemLegalText />
                </PublishedItem>
              }
            </Text>
          ) : (
            'Legal text'
          )}
          {language !== defaultLanguage && (
            <DefaultTextView defaultValue={fieldValue} />
          )}
        </Space>
      }
      rules={[{ required: true, message: 'Insert the legal text' }]}
    >
      <TextArea placeholder={fieldValue} disabled={!editMode} autoSize />
    </Form.Item>
  );
};

export default FormItemLegalText;
