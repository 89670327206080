import { FC, useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Select, FormInstance, Tooltip } from 'antd';
import { RootState } from '../../../store';
import {
  FormChild,
  FormFieldType,
  Option,
  FormDetailParams,
  FieldChanges,
  ApprovalType,
} from '../types';
import {
  getOptions,
  parseMetadataDependencies,
  areItemsDifferent,
  validateFormInstance,
} from '../utils';
import styles from '../formBuilderDetail.module.scss';

import FormItemLabel from './FormItemLabel';
import FormItemDisplayAs from './FormItemDisplayAs';
import FormItemContent from './FormItemContent';
import FormItemAttachments from './FormItemAttachments';
import FormItemAnswers from './FormItemAnswers';
import FormItemPhotoCount from './FormItemPhotoCount';
import FormItemReqAnswersCount from './FormItemReqAnswersCount';
import FormItemRequired from './FormItemRequired';
import FormItemLegalText from './FormItemLegalText';
import FormItemApprovalReq from './FormItemApprovalReq';
import FieldItemAdditionalData from './FieldItemAdditionalData';
import FieldItemDependsOn from './FieldItemDependsOn';
import FormItemMetadata from './FormItemMetadata';
import FormItemIsPrefill from './FormItemIsPrefill';
import { useIntl } from 'react-intl';
import { IFeatures } from '../../../store/rolesAndPrivileges';
import { SUPPORTED_LANGUAGES } from '../../../constants/types';

const defaultFieldProps = [
  'displayAs',
  'label',
  'content',
  'attachments',
  'additionalFieldData',
  'required',
  'isPrefill',
  'approvalRequired',
  'approvalType',
  'dependsOn',
];

const formFieldProps: { [key: string]: string[] } = {
  Choice: [
    ...defaultFieldProps,
    'answers',
    'defaultValue',
    'requiredAnswerCount',
    'metadata',
  ],
  Date: [...defaultFieldProps, 'metadata'],
  ImageUpload: [...defaultFieldProps, 'photoCount', 'metadata'],
  Input: [...defaultFieldProps, 'metadata'],
  Instruction: [...defaultFieldProps, 'metadata'],
  Section: ['label'],
  Signature: [...defaultFieldProps, 'legalText', 'metadata'],
  VideoUpload: [...defaultFieldProps, 'metadata'],
};

const MULTI_LANGUAGE_SUPPORTED_FIELDS: (keyof FormChild)[] = [
  'label',
  'content',
  'additionalFieldData',
  'legalText',
  'errorMessage',
];

const formItemLayout = {
  labelCol: {
    xs: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 16, offset: 1 },
  },
};

interface Props {
  selectedNode?: FormChild;
  editMode: boolean;
  formInstance: FormInstance;
  isNewField?: boolean;
  selectedLanguage: SUPPORTED_LANGUAGES;
  setTypeIsSelected?: (arg1: boolean) => void;
}

const FormGenerator: FC<Props> = ({
  selectedNode,
  editMode,
  formInstance,
  isNewField,
  selectedLanguage,
  setTypeIsSelected,
}) => {
  const { fieldId }: FormDetailParams = useParams();
  const intl = useIntl();
  const publishedFlatList = useSelector(
    (state: RootState) => state.formBuilderDetail.publishedFlatList
  );
  const privilegesState = useSelector(
    (state: RootState) => state.authentication?.privileges ?? {}
  );

  const form = useSelector((state: RootState) => state.formBuilderDetail.form);
  const [typeOptions, setTypeOptions] = useState<Option[]>([]);
  const [selectedFieldType, setSelectedFieldType] = useState<FormFieldType>();
  const [fieldChanges, setFieldChanges] = useState<FieldChanges | null>(null);

  const initialState: { [key: string]: any } = useMemo(
    () => ({
      type: selectedNode?.type,
      label: selectedNode?.label,
      displayAs: selectedNode?.displayAs,
      content: selectedNode?.content,
      additionalFieldData: selectedNode?.additionalFieldData,
      required: selectedNode?.required ?? true,
      isPrefill: selectedNode?.isPrefill || false,
      approvalRequired: selectedNode?.approvalRequired || false,
      approvalType: isNewField
        ? ApprovalType.SingleApprover
        : selectedNode?.approvalType,
      isValidate: selectedNode?.isValidate || false,
      range:
        {
          min: !!selectedNode?.validation?.conditions?.filter(
            (item) => item.operator === 'GreaterThanOrEqual'
          )[0]?.value
            ? parseFloat(
                selectedNode?.validation?.conditions
                  ?.filter((item) => item.operator === 'GreaterThanOrEqual')[0]
                  ?.value?.toString()
              )
            : undefined,
          max: !!selectedNode?.validation?.conditions?.filter(
            (item) => item.operator === 'LessThanOrEqual'
          )[0]?.value
            ? parseFloat(
                selectedNode?.validation?.conditions
                  ?.filter((item) => item.operator === 'LessThanOrEqual')[0]
                  ?.value.toString()
              )
            : undefined,
        } || {},
      validAnswers: selectedNode?.validation?.conditions,
      errorMessage: selectedNode?.validation?.message,
      attachments: selectedNode?.attachments,
      dependsOn: selectedNode?.dependsOn,
      answers: selectedNode?.answers,
      defaultValue: selectedNode?.defaultValue,
      requiredAnswerCount: {
        min: selectedNode?.requiredAnswerCount?.min || 1,
        max: selectedNode?.requiredAnswerCount?.max || 1,
      },
      photoCount: {
        min: selectedNode?.photoCount?.min || 1,
        max: selectedNode?.photoCount?.max || 1,
      },
      legalText: selectedNode?.legalText,
      commentRequired: selectedNode?.commentRequired,
      metadata: {
        dependOn: parseMetadataDependencies(
          selectedNode?.metadata?.dependOn || {}
        ),
        defaultValueKey: selectedNode?.metadata?.defaultValueKey,
        writeTo: selectedNode?.metadata?.writeTo,
      },
    }),
    [selectedNode, isNewField]
  );

  const getFieldItem = (item: keyof FormChild, id: number | string) => {
    const isFieldLocked = selectedLanguage !== form?.defaultLanguage;
    switch (item) {
      case 'label':
        return form?.languages.map((lang: SUPPORTED_LANGUAGES) => [
          <FormItemLabel
            key={id}
            language={lang}
            hidden={lang !== selectedLanguage}
            editMode={editMode}
            defaultLanguage={form?.defaultLanguage}
            fieldChanges={fieldChanges}
          />,
        ]);
      case 'displayAs':
        return (
          <FormItemDisplayAs
            key={id}
            editMode={editMode && !isFieldLocked}
            isNewField={isNewField}
            formInstance={formInstance}
            fieldChanges={fieldChanges}
          />
        );
      case 'content':
        return form?.languages.map((lang: SUPPORTED_LANGUAGES) => [
          <FormItemContent
            key={id}
            language={lang}
            hidden={lang !== selectedLanguage}
            isFieldLocked={lang !== form?.defaultLanguage}
            editMode={editMode}
            defaultLanguage={form?.defaultLanguage}
            fieldChanges={fieldChanges}
          />,
        ]);
      case 'additionalFieldData':
        return form?.languages.map((lang: SUPPORTED_LANGUAGES) => [
          <FieldItemAdditionalData
            key={id}
            language={lang}
            hidden={lang !== selectedLanguage}
            editMode={editMode}
            isFieldLocked={lang !== form?.defaultLanguage}
            defaultLanguage={form?.defaultLanguage}
            fieldChanges={fieldChanges}
          />,
        ]);
      case 'required':
        return (
          <FormItemRequired
            key={id}
            editMode={editMode && !isFieldLocked}
            fieldChanges={fieldChanges}
          />
        );
      case 'approvalRequired':
        return (
          <FormItemApprovalReq
            key={id}
            selectedLanguage={selectedLanguage}
            supportedLanguages={form?.languages!}
            editMode={editMode}
            defaultLanguage={form?.defaultLanguage}
            isFieldLocked={isFieldLocked}
            fieldChanges={fieldChanges}
          />
        );

      case 'attachments':
        return (
          <FormItemAttachments
            key={id}
            selectedLanguage={selectedLanguage}
            supportedLanguages={form?.languages!}
            editMode={editMode}
            fieldChanges={fieldChanges}
            isFieldLocked={isFieldLocked}
            defaultLanguage={form?.defaultLanguage}
            isNewField={isNewField}
          />
        );
      case 'dependsOn':
        return (
          <FieldItemDependsOn
            key={id}
            editMode={editMode && !isFieldLocked}
            fieldChanges={fieldChanges}
            selectedNodeId={selectedNode?.id}
            defaultLanguage={form?.defaultLanguage}
          />
        );
      case 'answers':
        return (
          <FormItemAnswers
            key={id}
            selectedLanguage={selectedLanguage}
            supportedLanguages={form?.languages!}
            editMode={editMode}
            fieldChanges={fieldChanges}
            isFieldLocked={isFieldLocked}
            defaultLanguage={form?.defaultLanguage}
            isNewField={isNewField}
          />
        );
      case 'requiredAnswerCount':
        return (
          <FormItemReqAnswersCount
            key={id}
            editMode={editMode && !isFieldLocked}
            fieldChanges={fieldChanges}
          />
        );
      case 'photoCount':
        return (
          <FormItemPhotoCount
            key={id}
            editMode={editMode && !isFieldLocked}
            fieldChanges={fieldChanges}
          />
        );
      case 'legalText':
        return form?.languages.map((lang: SUPPORTED_LANGUAGES) => [
          <FormItemLegalText
            key={id}
            language={lang}
            hidden={lang !== selectedLanguage}
            editMode={editMode}
            defaultLanguage={form?.defaultLanguage}
            fieldChanges={fieldChanges}
          />,
        ]);
      case 'metadata':
        return (
          <FormItemMetadata
            key={id}
            editMode={
              editMode &&
              privilegesState[IFeatures.SuperAdminAccess] &&
              !isFieldLocked
            }
            fieldChanges={fieldChanges}
          />
        );
      case 'isPrefill':
        return (
          <FormItemIsPrefill
            key={id}
            editMode={editMode && !isFieldLocked}
            fieldChanges={fieldChanges}
          />
        );
      default:
        return undefined;
    }
  };

  //When creating a new field, resets field items on field type change
  useEffect(() => {
    if (!selectedNode && selectedFieldType)
      formInstance.resetFields(formFieldProps[selectedFieldType]);
  }, [formInstance, selectedNode, selectedFieldType]);

  useEffect(() => {
    if (selectedNode?.type && !isNewField && form?.status !== 'Published') {
      const publishedField = publishedFlatList?.get(fieldId!);
      const fieldChanges = formFieldProps[selectedNode?.type].reduce(
        (changesObj, prop) => {
          const publishedItem = publishedField?.[prop];
          changesObj[prop] = {
            hasChanges: areItemsDifferent(selectedNode?.[prop], publishedItem),
            publishedItem,
          };
          return changesObj;
        },
        {} as FieldChanges
      );
      setFieldChanges(fieldChanges);
    }
  }, [selectedNode, fieldId, publishedFlatList, form?.status, isNewField]);

  useEffect(() => {
    if (selectedNode) setSelectedFieldType(selectedNode.type);
  }, [selectedNode, editMode]);

  //Resets field values to initial state on cancel edit
  useEffect(() => {
    formInstance.setFieldsValue(initialState);
  }, [formInstance, editMode, initialState]);

  useEffect(() => {
    if (initialState?.label?.default) {
      validateFormInstance(formInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  //Sets the field type options list
  useEffect(() => {
    setTypeOptions(getOptions(FormFieldType));

    return () => setSelectedFieldType(undefined);
  }, []);

  return (
    <Form
      {...formItemLayout}
      form={formInstance}
      initialValues={initialState}
      className={styles.formGenerator}
      labelWrap
      validateTrigger={['onChange', 'onBlur']}
      onValuesChange={(changedValues: any) => {
        // Check if all the field languages are updated
        let fieldUpdated = Object.keys(changedValues)[0] as keyof FormChild;
        if (MULTI_LANGUAGE_SUPPORTED_FIELDS.includes(fieldUpdated)) {
          let updatedLangField = Object.keys(changedValues[fieldUpdated])[0];
          let otherLangToValidate = form?.languages.filter(
            (l: SUPPORTED_LANGUAGES) => l !== updatedLangField
          );
          if (otherLangToValidate?.length) {
            formInstance.validateFields([
              ...otherLangToValidate.map((l: SUPPORTED_LANGUAGES) => [
                fieldUpdated,
                l,
              ]),
            ]);
          }
        }

        if ((['attachments'] as (keyof FormChild)[]).includes(fieldUpdated)) {
          let updatedItemIndex = Object.keys(changedValues[fieldUpdated])[0];
          if (changedValues[fieldUpdated][updatedItemIndex]) {
            let updatedLabelField = Object.keys(
              changedValues[fieldUpdated][updatedItemIndex]
            )[0];
            formInstance.validateFields([
              fieldUpdated,
              updatedItemIndex,
              updatedLabelField,
            ]);
          }
        }

        if ((['metadata'] as (keyof FormChild)[]).includes(fieldUpdated)) {
          let updatedMetadataField = Object.keys(
            changedValues[fieldUpdated]
          )[0];
          if (updatedMetadataField === 'writeTo') {
            formInstance.validateFields(['metadata', 'defaultValueKey']);
          } else if (updatedMetadataField === 'defaultValueKey') {
            formInstance.validateFields(['metadata', 'writeTo']);
          }
        }
      }}
    >
      <Form.Item
        key={0}
        name="type"
        label={
          <Tooltip
            placement="topLeft"
            title={intl.formatMessage({ id: 'field_type_tooltip' })}
          >
            <span>{intl.formatMessage({ id: 'field_type' })} </span>
          </Tooltip>
        }
        rules={[{ required: true, message: 'Select the field type' }]}
      >
        <Select
          disabled={!editMode || !!selectedNode}
          options={typeOptions}
          value={selectedFieldType}
          onChange={(value) => {
            setSelectedFieldType(value);
            if (setTypeIsSelected) setTypeIsSelected(true);
          }}
        />
      </Form.Item>
      {selectedFieldType &&
        formFieldProps[selectedFieldType].map((prop, i) =>
          getFieldItem(prop as keyof FormChild, i + 1)
        )}
    </Form>
  );
};

export default FormGenerator;
