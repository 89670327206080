import { FC, useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Select, Empty } from 'antd';
import styles from '../detailDrawer.module.scss';
import NoteForm from './noteForm';
import AccessControl from '../../../../components/accessControl';
import { IFeatures } from '../../../../store/rolesAndPrivileges';
import Note from './note';
import translation from '../../../../i18n/translation';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { CS_appointmentType, IAppointment } from '../../../../constants/types';
const REQUIRE_NOTE_SKILL_FILTER = ['Montage', CS_appointmentType.name];
const { Text } = Typography;
interface Props {
  fetchAppointmentData: () => void;
  notes?: any[];
  selectedAppointment?: IAppointment | null;
  editModeActive?: boolean;
}

const Notes: FC<Props> = ({
  fetchAppointmentData,
  notes = [],
  selectedAppointment,
  editModeActive = false,
}) => {
  const [editMode, setEditMode] = useState(editModeActive);
  const [workItemFilters, setWorkItemFilters] = useState<string[]>([]);
  const [filteredNotes, setFilteredNotes] = useState<any[]>(notes);
  const handleEditMode = (mode: boolean) => {
    setEditMode(mode);
  };

  const filterByWorkItems = () => {
    if (workItemFilters.length > 0 && workItemFilters.length < 3) {
      setFilteredNotes(
        notes.filter((item) => {
          let modifiedSATString = `${item.serviceAppointmentType}/`;
          return (
            modifiedSATString.includes(`${workItemFilters[0]}/`) ||
            modifiedSATString.includes(`${workItemFilters[1]}/`) ||
            modifiedSATString.includes(`${workItemFilters[0]} Backlog`) ||
            modifiedSATString.includes(`${workItemFilters[1]} Backlog`)
          );
        })
      );
    } else {
      setFilteredNotes(notes);
    }
  };

  useEffect(() => {
    filterByWorkItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workItemFilters]);

  return (
    <Col>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text strong className={styles.sectionHeader}>
          {translation('notes')}
        </Text>
        <Button
          type="link"
          icon={editMode ? <CloseOutlined /> : <EditOutlined />}
          style={{ padding: 0, margin: '6px 0', height: '22px' }}
          onClick={() => handleEditMode(!editMode)}
        >
          {' '}
          {editMode ? translation('cancel') : translation('add_new_note')}
        </Button>
      </Row>
      <Row wrap={false}>
        <Col flex="auto" style={{ padding: 0, width: '100%' }}>
          {editMode && (
            <div className={styles.addNoteButtonContainer}>
              <AccessControl featureKey={IFeatures.progressWriteMetadata}>
                <NoteForm
                  selectedNote={notes[0]}
                  editMode={editMode}
                  updateMode={false}
                  handleEditMode={handleEditMode}
                  fetchAppointmentData={fetchAppointmentData}
                />
              </AccessControl>
            </div>
          )}
          <div className={styles.notesFilterContainer}>
            <Row>
              {notes.length > 0 ? (
                REQUIRE_NOTE_SKILL_FILTER.includes(
                  selectedAppointment?.workOrderType?.name ?? ''
                ) ? (
                  <Select
                    showSearch={false}
                    placeholder={translation('filter_by_sat')}
                    optionFilterProp="children"
                    allowClear
                    mode="multiple"
                    onChange={(workItemFilterArray) => {
                      setWorkItemFilters(workItemFilterArray);
                    }}
                    style={{ width: '100%' }}
                    options={['DC', 'ACM', 'AC'].map((item: string) => {
                      return {
                        value: item,
                        label: item,
                      };
                    })}
                  />
                ) : null
              ) : (
                <Empty
                  description={false}
                  style={{ width: '100%', marginTop: '40px' }}
                />
              )}
            </Row>
          </div>
          <div className={styles.notesContainer}>
            {filteredNotes.map((note, i) => (
              <Note
                note={note}
                showDivider={i === 0 ? false : true}
                showServiceAppointmentType={
                  selectedAppointment?.workOrderType?.name === 'Montage'
                    ? true
                    : false
                }
                key={`note-item-${i}`}
                fetchAppointmentData={fetchAppointmentData}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Notes;
