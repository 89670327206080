import { FC, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Empty,
  FormInstance,
  Typography,
  Tooltip,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getAboveChoices } from '../utils';
import { FieldItemProps, FormChild, Answer, DependsOn } from '../types';
import styles from '../formBuilderDetail.module.scss';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';
import { GLOBAL_DEFAULT_LANGUAGE } from '../../../constants/types';

const { Text } = Typography;
interface Props {
  name: number;
  restField: {};
  editMode?: boolean;
  form: FormInstance;
  choicesList: FormChild[];
  remove: (name: number) => void;
}

const Dependency: FC<Props> = ({
  name,
  restField,
  editMode,
  form,
  choicesList,
  remove,
}) => {
  const dependsOn = Form.useWatch<DependsOn[]>('dependsOn', form);
  const [answersList, setAnswersList] = useState<Answer[]>([]);
  const defaultFieldId = useRef<string>();

  useEffect(() => {
    if (
      dependsOn?.length &&
      dependsOn[name]?.fieldId !== defaultFieldId.current &&
      editMode
    ) {
      const currentChoice = dependsOn[name];

      const newDependsOn = dependsOn.map((choice, i) =>
        choice.fieldId === currentChoice.fieldId && i === dependsOn.length + 1
          ? { ...currentChoice, answerId: '' }
          : choice
      );
      form.setFieldsValue({
        dependsOn: newDependsOn,
      });
    }
  }, [form, dependsOn, name, editMode]);

  useEffect(() => {
    if (choicesList.length > 0 && dependsOn?.length) {
      const fieldId = dependsOn[name]?.fieldId;
      setAnswersList(
        choicesList?.find((choice) => choice.id === fieldId)?.answers || []
      );
      defaultFieldId.current = fieldId;
    }
  }, [choicesList, dependsOn, name]);

  return (
    <Row justify="space-between" align="top">
      <Col span={10}>
        <Form.Item
          {...restField}
          name={[name, 'fieldId']}
          label="Field"
          rules={[
            {
              required: true,
              message: 'Select a field',
            },
          ]}
        >
          <Select
            disabled={!editMode || choicesList.length === 0}
            options={[
              ...choicesList.map((item) => {
                return {
                  label:
                    item?.label[
                      item?.label?.default ?? GLOBAL_DEFAULT_LANGUAGE
                    ],
                  value: item.id,
                };
              }),
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          {...restField}
          name={[name, 'answerId']}
          label="Answer"
          rules={[
            {
              required: true,
              message: 'Select an answer',
            },
          ]}
        >
          <Select
            disabled={!editMode}
            options={[
              ...answersList.map((item) => {
                return {
                  label:
                    item?.label[
                      item?.label?.default ?? GLOBAL_DEFAULT_LANGUAGE
                    ],
                  value: item.id,
                };
              }),
            ]}
          />
        </Form.Item>
      </Col>
      {editMode && (
        <MinusCircleOutlined
          onClick={() => remove(name)}
          style={{ marginTop: '16px' }}
        />
      )}
    </Row>
  );
};

const FieldItemDependsOn: FC<FieldItemProps> = ({
  editMode,
  selectedNodeId,
  fieldChanges,
}) => {
  const form = Form.useFormInstance();
  const flatList = useSelector(
    (state: RootState) => state.formBuilderDetail.flatList
  );
  const [choicesList, setChoicesList] = useState<FormChild[]>([]);

  const publishedItemValues = useMemo(
    () => ({
      dependsOn: fieldChanges?.dependsOn.publishedItem,
    }),
    [fieldChanges]
  );

  useEffect(() => {
    if (flatList && selectedNodeId) {
      setChoicesList(getAboveChoices(flatList, selectedNodeId));
    }
  }, [flatList, selectedNodeId]);

  return (
    <Form.Item
      label={
        fieldChanges?.dependsOn.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('depends_on_tooltip')}>
              <span>{translation('depends_on')} </span>{' '}
            </Tooltip>
            <PublishedItem initialValues={publishedItemValues}>
              <FieldItemDependsOn selectedNodeId={selectedNodeId} />
            </PublishedItem>
          </Text>
        ) : (
          <Tooltip title={translation('depends_on_tooltip')}>
            <span>{translation('depends_on')} </span>
          </Tooltip>
        )
      }
    >
      <Form.List name="dependsOn">
        {(fields, { add, remove }) => (
          <div className={styles.nestContent}>
            {fields.map(({ key, name, ...restField }) => (
              <Dependency
                key={key}
                name={name}
                restField={restField}
                editMode={editMode}
                form={form}
                choicesList={choicesList}
                remove={remove}
              />
            ))}
            {!editMode && fields.length === 0 && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No dependencies"
              />
            )}
            {editMode && (
              <Button
                type="dashed"
                disabled={!selectedNodeId}
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {' '}
                <span>{translation('add_dependency')} </span>
              </Button>
            )}
          </div>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default FieldItemDependsOn;
