import { AnyAction } from 'redux';
import ApiRepository from '../../services/api/apiRepository';
import { ProtocolActionTypes, appointmentTypeNames } from './types';
import { IAppointment, IAppointmentStatus } from '../../constants/types';

// Action for fetching protocol data
const getProtocolData = (
  externalCaseId: string,
  appointmentTypeName: string
) => {
  return async (dispatch: (arg0: AnyAction) => void) => {
    try {
      // Fetch work orders details data
      const responseWO = await ApiRepository.getWorkOrdersDetailsData(
        externalCaseId,
        appointmentTypeName
      );

      // Fetch documents data
      const responseDocument = await ApiRepository.getDocumentsData(
        externalCaseId,
        appointmentTypeNames[appointmentTypeName] as string
      );

      // Extract appointments from work orders data
      const woAppointments = responseWO?.response?.appointments;

      // Initialize variables for latest appointment details, work order start date, and metadata
      let latestAppointmentDetails: IAppointment | null = null;
      let workorderStartDate: string = '';
      let metaData;

      // Find the latest appointment that is not 'NotStarted' and not cancelled
      if (woAppointments?.length) {
        // Find the first appointment that is started
        const firstStartedAppointment = woAppointments.find(
          (app: IAppointment) => app?.status !== 'NotStarted'
        );

        // Set the work order start date to the start date of the first started appointment
        workorderStartDate = firstStartedAppointment.appointmentDate;

        // Get the last completed or in progress appointment
        const latestAppointment = woAppointments
          .reverse()
          .find(
            (app: IAppointment) =>
              app?.status !== 'NotStarted' &&
              app?.externalStatus !== IAppointmentStatus.cancelled
          );

        // Fetch appointment details data for the latest appointment
        const responseApp = await ApiRepository.getAppointmentsDetailsData(
          latestAppointment.id
        );

        latestAppointmentDetails = responseApp?.response;

        // If the latest appointment has a case ID, fetch metadata for that case ID
        if (latestAppointmentDetails?.caseId) {
          const responseMetadata = await ApiRepository.getMetadataInfos(
            latestAppointmentDetails?.caseId
          );
          metaData = responseMetadata?.response;
        }
      }

      // Dispatch action to update state with fetched data
      dispatch({
        type: ProtocolActionTypes.GET_PROTOCOL_DATA,
        payload: {
          workorder: responseWO?.response,
          questions: responseDocument?.response.questions,
          appointmentTypeName,
          latestAppointmentDetails,
          workorderStartDate,
          metaData,
        },
      });
    } catch (err: any) {
      // Dispatch error action with error payload
      dispatch({
        type: ProtocolActionTypes.GET_PROTOCOL_DATA_FAIL,
        payload: err,
      });
    }
  };
};

// Action for setting loading state
const setLoading = (loading: boolean) => {
  return {
    type: ProtocolActionTypes.SET_LOADING,
    payload: loading,
  };
};

// Action for resetting state
const resetState = () => {
  return {
    type: ProtocolActionTypes.GET_PROTOCOL_DATA_FAIL,
    payload: null,
  };
};

// Export actions object
export default {
  getProtocolData,
  setLoading,
  resetState,
};
