import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { Tag, Button, Popover, message, Modal } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { FormattedDate, useIntl } from 'react-intl';
import { fetchFormAsync, resetState } from '../actions';
import { getStatusColor } from '../utils';
import { EditMode, FormDetailParams } from '../types';
import ApiRepository from '../../../services/api/apiRepository';
import styles from '../formBuilderDetail.module.scss';
import * as routes from '../../../router/routes';
import translation from '../../../i18n/translation';
import Iframe from 'react-iframe';
import { getApiUrl, getEnv } from '../../../utils/environment';
import AccessControl from '../../../components/accessControl';
import { IFeatures } from '../../../store/rolesAndPrivileges';

interface Props {
  editMode: EditMode;
  openEditFormModal: () => void;
}

// Define the confirm function from Modal
const { confirm } = Modal;

const FormHeader: FC<Props> = ({ editMode, openEditFormModal }) => {
  // Initialize the navigate hook from react-router-dom
  const navigate = useNavigate();
  // Initialize the useParams hook from react-router-dom
  const { fieldId }: FormDetailParams = useParams();
  // Initialize the useDispatch hook from react-redux
  const dispatch = useDispatch();
  // Initialize the useIntl hook from react-intl
  const intl = useIntl();
  // Access the form state from the Redux store
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);
  // Initialize the state for showPreview
  const [showPreview, setShowPreview] = useState(false);

  // Define the handlePublishConfirm function
  const handlePublishConfirm = () => {
    // Show a confirmation dialog
    confirm({
      title: intl.formatMessage({ id: 'publish_confirm' }),
      icon: <ExclamationCircleFilled />,
      okText: intl.formatMessage({ id: 'publish' }),
      onOk: handlePublish,
    });
  };

  // Define the handlePublish function
  const handlePublish = async () => {
    // Check if the form exists
    if (form) {
      try {
        // Call the publishForm API
        await ApiRepository.publishForm(form.id);
        // Reset the state and fetch the form again
        dispatch(resetState());
        dispatch(fetchFormAsync(form.id));
        // Set showPreview to false
        setShowPreview(false);
        // Navigate to the formBuilderDetail page
        navigate({
          pathname: routes.formBuilderDetail.pathWithParams(
            '0',
            form.id,
            fieldId
          ),
        });
      } catch (error: any) {
        // If there's an error, show a message
        if (error?.message) {
          message.error(error?.message);
        }
      }
    }
  };

  // Define the handlePreview function
  const handlePreview = () => {
    // Check if the form exists and if it has an id
    if (form && form.id) {
      // Open a new window with the preview URL
      window.open(
        `https://app${getApiUrl()}.craftos.enpal.tech/preview/${form?.id}`,
        '_blank'
      );
    }
  };

  // Define the popoverContent variable
  const popoverContent = (
    <div>
      <p>
        <strong>{translation('revision')}:</strong> {form?.revision}
      </p>
      <p>
        <strong>{translation('created')}:</strong>{' '}
        {<FormattedDate value={form?.createdAt} />}
      </p>
      <p>
        <strong>{translation('updated')}:</strong>{' '}
        {<FormattedDate value={form?.updatedAt} />}
      </p>

      {form?.publishedAt && (
        <p>
          <strong>{translation('published')}:</strong>{' '}
          {<FormattedDate value={form?.publishedAt} />}
        </p>
      )}
    </div>
  );

  // Define the priorityTag variable
  const priorityTag =
    form?.priority === 'Priority'
      ? [
          <Tag key="1" color="success">
            {form?.priority}
          </Tag>,
        ]
      : [];

  // Return the JSX for the FormHeader component
  return (
    <>
      <PageHeader
        className={styles.formHeader}
        title={form?.name[form?.defaultLanguage]}
        subTitle={form?.formHandle}
        tags={[
          <Tag key="2" color={getStatusColor(form?.status)}>
            {form?.status}
          </Tag>,
          ...priorityTag,
        ]}
        onBack={() => navigate(`/${routes.ROOT_PAGES.formBuilder}`)}
        extra={[
          <Popover key="3" content={popoverContent} placement="bottomLeft">
            <InfoCircleOutlined style={{ fontSize: '1.1em' }} />
          </Popover>,
          <Button
            key="btnFromBuilderEdit"
            type="primary"
            disabled={editMode.formDetail || editMode.treeMenu}
            onClick={openEditFormModal}
          >
            {' '}
            {translation('edit')}
          </Button>,
          <AccessControl
            featureKey={IFeatures.formBuilderAccess}
            key="accFormBuilderPublish"
          >
            <Button
              key="btnFormBuilderPublish"
              disabled={
                editMode.formDetail ||
                editMode.treeMenu ||
                form?.status !== 'Draft'
              }
              onClick={handlePublishConfirm}
            >
              {' '}
              {translation('publish')}
            </Button>
          </AccessControl>,
          <AccessControl
            featureKey={IFeatures.formBuilderAccess}
            key="accFromBuilderPreview"
          >
            <Button
              key="btnFromBuilderPreview"
              disabled={editMode.formDetail || editMode.treeMenu}
              onClick={handlePreview}
            >
              {' '}
              {translation('preview_comments')}
            </Button>
          </AccessControl>,
        ]}
      />

      <Modal
        onOk={handlePublishConfirm}
        open={showPreview}
        style={{ top: 20 }}
        width={window.innerWidth / 4}
        styles={{ body: { padding: 0 } }}
        closable={false}
        onCancel={() => {
          setShowPreview(false);
        }}
        okText={translation('publish')}
        okButtonProps={{ disabled: form?.status !== 'Draft' }}
        destroyOnClose
        okType="primary"
        maskClosable={false}
        cancelText={translation('cancel')}
      >
        <Iframe
          url={`https://app${
            getEnv() !== 'production' ? '-test' : ''
          }.craftos.enpal.tech/preview/${form?.id}`}
          width={`${window.innerWidth / 4}px`}
          height={`${window.innerHeight - 100}px`}
          id="craft-app-preview"
          display="block"
          frameBorder={0}
          position="relative"
        />
      </Modal>
    </>
  );
};

// Export the FormHeader component
export default FormHeader;
