import { AnyAction } from 'redux';
import {
  workType,
  appointmentsDetailsTypes,
  IReasonCodeOccurance,
} from '../../constants/types';
import { store } from '../../store';
import {
  getArrayRevisionUniqueId,
  getFormFieldLabel,
} from '../../utils/helpers';
import ApiRepository from '../../services/api/apiRepository';

const actions = {
  getWorkTypeData(workTypeId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const answerData = await ApiRepository.getAnswerData(workTypeId);
        const userData = answerData?.response;

        const storeRevisionIds = Object.keys(
          store.getState().appointmentsDetails.formRevisions
        );
        const revisionId = [userData.formInstance.formRevisionId];
        const uniqueRevisionsId = getArrayRevisionUniqueId(
          revisionId,
          storeRevisionIds
        );

        if (Boolean(uniqueRevisionsId.length)) {
          const revisionsData = await ApiRepository.getFormRevisions(
            uniqueRevisionsId
          );
          const formFieldLabel = getFormFieldLabel(
            revisionsData,
            userData.formFieldId,
            userData.formInstance.formRevisionId
          );
          dispatch({
            type: workType.SET_FORM_FIELD_LABEL,
            payload: formFieldLabel,
          });
          dispatch({
            type: appointmentsDetailsTypes.GET_FORM_REVISIONS_DATA,
            payload: revisionsData,
          });
        }

        const revisionsData = store.getState().appointmentsDetails
          .formRevisions;
        const formFieldLabel = getFormFieldLabel(
          revisionsData,
          userData.formFieldId,
          userData.formInstance.formRevisionId
        );

        dispatch({
          type: workType.SET_FORM_FIELD_LABEL,
          payload: formFieldLabel,
        });

        dispatch({
          type: workType.GET_WORK_TYPE_DATA,
          payload: userData,
        });

        const reasonCodes = await ApiRepository.getReasonCodes(
          userData.formFieldId,
          revisionsData[userData.formInstance.formRevisionId].formId
        );

        dispatch({
          type: workType.SET_REASON_CODES,
          payload: reasonCodes,
        });
      } catch (err: any) {
        dispatch({
          type: workType.GET_WORK_TYPE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getAssignmentData(assignmentId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: workType.GET_ASSIGNMENT_DATA,
          payload: await ApiRepository.getAssignmentData(assignmentId),
        });
      } catch (err: any) {
        dispatch({
          type: workType.GET_WORK_TYPE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  setLoadingDetails(loading: boolean) {
    return {
      type: workType.SET_LOADING,
      payload: loading,
    };
  },

  setSubmitLoadingDetails(loading: boolean) {
    return {
      type: workType.SET_SUBMIT_LOADING,
      payload: loading,
    };
  },

  setDefaultValues() {
    return {
      type: workType.SET_DEFAULT_VALUES,
      payload: {},
    };
  },

  sendComment(
    id: string,
    comment: string,
    approved: boolean,
    appointmentId: string,
    answerSnapshotId: string,
    reasonCodeData: IReasonCodeOccurance,
    finalApproval: boolean = false,
    uploads?: string[]
  ) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: workType.SET_SUBMIT_LOADING,
          payload: true,
        });

        await ApiRepository.postComment(
          id,
          comment,
          approved,
          appointmentId,
          answerSnapshotId,
          finalApproval,
          uploads
        );
        if (reasonCodeData.reasonCodeDefinitionIds.length > 0) {
          await ApiRepository.postReasonCodes(reasonCodeData);
        }

        dispatch({
          type: workType.POST_WORK_TYPE_DATA,
          payload: await refreshAnswerData(id, answerSnapshotId),
        });
      } catch (err: any) {
        dispatch({
          type: workType.POST_WORK_TYPE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  confirmReviewComment(
    id: string,
    appointmentId: string,
    answerSnapshotId: string
  ) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: workType.SET_SUBMIT_LOADING,
          payload: true,
        });
        await ApiRepository.postConfirmReviewComment(
          id,
          appointmentId,
          answerSnapshotId
        );
        dispatch({
          type: workType.POST_WORK_TYPE_DATA,
          payload: await refreshAnswerData(id, answerSnapshotId),
        });
      } catch (err: any) {
        dispatch({
          type: workType.POST_WORK_TYPE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  sendReply(
    id: string,
    comment: string,
    appointmentId: string,
    answerSnapshotId: string,
    uploads?: string[]
  ) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: workType.SET_SUBMIT_LOADING,
          payload: true,
        });
        await ApiRepository.postReply(
          id,
          comment,
          appointmentId,
          answerSnapshotId,
          uploads
        );

        dispatch({
          type: workType.POST_WORK_TYPE_DATA,
          payload: await refreshAnswerData(id),
        });
      } catch (err: any) {
        dispatch({
          type: workType.POST_WORK_TYPE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  reopen(id: string, appointmentId: string, answerSnapshotId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: workType.SET_SUBMIT_LOADING,
          payload: true,
        });
        await ApiRepository.postReopen(id, appointmentId);
        dispatch({
          type: workType.POST_WORK_TYPE_DATA,
          payload: await refreshAnswerData(id, answerSnapshotId),
        });
      } catch (err: any) {
        dispatch({
          type: workType.POST_WORK_TYPE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  resetWorktypeDetailError() {
    return {
      type: workType.RESET_WORK_TYPE_DATA_FAIL,
      payload: null,
    };
  },

  getFreshAnswerData(id: string, answerSnapshotId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: workType.REFRESH_ANSWER_DATA,
          payload: await refreshAnswerData(id, answerSnapshotId),
        });
      } catch (err: any) {
        dispatch({
          type: workType.POST_WORK_TYPE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },
};

const refreshAnswerData = async (id: string, answerSnapshotId?: string) => {
  const answerDataRequest = ApiRepository.getAnswerData(id);
  const approvalsRequest = answerSnapshotId
    ? getReviewCommentApprovals(id, answerSnapshotId)
    : async () => {};

  const answerData = await answerDataRequest;
  const approvals = await approvalsRequest;

  return {
    ...answerData?.response,
    ...(answerSnapshotId ?? approvals),
  };
};

const getReviewCommentApprovals = async (
  id: string,
  answerSnapshotId: string
) => {
  const approvalComments = await ApiRepository.getApprovalComments(
    id,
    answerSnapshotId
  );
  const reviewComments = approvalComments?.response;
  return reviewComments.length > 0 &&
    reviewComments[0].approvals &&
    reviewComments[0].approvals.length > 0 &&
    !reviewComments[0].approvals[0].feedback
    ? reviewComments[0].approvals
    : null;
};

export default actions;
