import { EMPTY_ANSWER, ImageBuffer } from '../../pages/protocol/types';
import { generateDocumentFields } from '../../utils/generateDocumentFields';

//import docx from 'docx'

const docx = require('docx');
const dateFormat = require('dateformat');

const {
  Paragraph,
  Table,
  TableCell,
  TableRow,
  convertInchesToTwip,
  TextRun,
  AlignmentType,
  BorderStyle,
  WidthType,
  ImageRun,
  HeadingLevel,
} = docx;

const enpalAddress = {
  title: 'Enpal B.V.',
  addressLine1: 'Koppenstraße 8',
  city: '10243 Berlin',
};

const borders = {
  top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
  bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
  left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
  right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
};

const generateAdditionalHeaderData = (appointment: any, metadata: any) => {
  return appointment?.appointmentType?.name === 'Montage'
    ? [
        new TableRow({
          children: [
            new TableCell({
              margin: {
                top: convertInchesToTwip(2),
                bottom: convertInchesToTwip(2),
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'Anlagetyp',
                    }),
                  ],
                }),
              ],
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: metadata['WT_Anlagetyp'],
                      bold: true,
                    }),
                  ],
                }),
              ],
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              margin: {
                top: convertInchesToTwip(2),
                bottom: convertInchesToTwip(2),
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'Anzahl der Module',
                    }),
                  ],
                }),
              ],
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: metadata['WT_NumberOfModules'],
                      bold: true,
                    }),
                  ],
                }),
              ],
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              margin: {
                top: convertInchesToTwip(2),
                bottom: convertInchesToTwip(2),
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'Hersteller & Typ',
                    }),
                  ],
                }),
              ],
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: metadata['WT_Hersteller'],
                      bold: true,
                    }),
                  ],
                }),
              ],
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
          ],
        }),
        new TableRow({
          margin: {
            top: convertInchesToTwip(2),
            bottom: convertInchesToTwip(2),
          },
          children: [
            new TableCell({
              margin: {
                top: convertInchesToTwip(2),
                bottom: convertInchesToTwip(2),
              },
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'PV Nennleistung (kWp)',
                    }),
                  ],
                }),
              ],
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: 80,
                bottom: 80,
                left: 100,
                right: 100,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: metadata['WT_PvNennleistung'],
                      bold: true,
                    }),
                  ],
                }),
              ],
              borders: {
                top: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                bottom: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                left: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
                right: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: 'FFFFFF',
                },
              },
            }),
          ],
        }),
      ]
    : [getEmptyTableRow()];
};

const getHeaderOfDocument = (
  appointment: any,
  metadata: any,
  workorderStartDate?: string
) => {
  const { documentTitle } = generateDocumentFields(
    appointment?.appointmentType?.name
  );

  return [
    new Table({
      borders: {
        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
      },
      rows: [
        new TableRow({
          borders: {
            top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
          },
          children: [
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: metadata['ENPAL_LOGO'],
                      transformation: {
                        width: 150,
                        height: 50,
                      },
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
              alignment: AlignmentType.RIGHT,
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun(enpalAddress.title)],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun(enpalAddress.addressLine1)],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun(enpalAddress.city)],
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    new Paragraph({
      text: '',
    }),
    new Paragraph({
      alignment: AlignmentType.CENTER,
      heading: HeadingLevel.HEADING_1,
      text: documentTitle,
    }),
    new Table({
      borders: {
        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Table({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                  },
                  rows: [
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Einsatzart',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.appointmentType.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Geplant für',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: dateFormat.default(
                                    appointment.appointmentDate,
                                    'dd.mm.yyyy HH:MM'
                                  ),
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Abgeschlossen von',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.team?.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Beschreibung',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.appointmentType.description,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    ...generateAdditionalHeaderData(appointment, metadata),
                  ],
                }),
              ],
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
              margin: {
                top: convertInchesToTwip(2),
                bottom: convertInchesToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Table({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                  },
                  rows: [
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Kunde',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customer?.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Erster Vertragspartner',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customer?.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Adresse',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customerAddress.address1,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Ort (PLZ)',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${appointment.customerAddress.city}(${appointment.customerAddress.zipCode})`,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Telefonnummer',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customerAddress.telephone,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Stand',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: dateFormat.default(
                                    workorderStartDate,
                                    'dd.mm.yyyy HH:MM'
                                  ),
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                  ],
                }),
              ],
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
            }),
          ],
          borders: {
            top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
          },
        }),
      ],
    }),
    new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: appointment?.workOrderDescription,
          bold: true,
        }),
      ],
    }),
    new Paragraph(''),
  ];
};

const generateProtocolDocument = (chapters: Array<any>) => [
  new Table({
    borders: {
      ...borders,
      margins: {
        bottom: convertInchesToTwip(0.2),
      },
      bottom: { style: BorderStyle.SINGLE, size: 5, color: 'FF0000' },
    },
    rows: chapters.map(
      (chapter, i: number) =>
        new TableRow({
          children: [
            new TableCell({
              borders,
              margins: {
                left: convertInchesToTwip(0.2),
              },
              children: [
                ['Chapter', 'Section'].includes(chapter.type)
                  ? generateProtocolChapter(chapter)
                  : generateProtocolField(chapter),
              ],
            }),
          ],
        })
    ),
  }),
];

const generateProtocolChapter = (chapter: any) =>
  new Table({
    borders: {
      ...borders,
      margins: {
        bottom: convertInchesToTwip(0.2),
      },
      bottom: { style: BorderStyle.SINGLE, size: 5, color: 'FF0000' },
    },
    rows: [
      new TableRow({
        borders,
        children: [
          new TableCell({
            borders,
            margins: {
              bottom: convertInchesToTwip(0.25),
            },
            children: [
              new Paragraph({
                heading: HeadingLevel.HEADING_2,
                text: chapter?.protocolText || chapter?.label,
              }),
            ],
          }),
        ],
      }),
      ...chapter.questions.map(
        (item: any, i: number) =>
          new TableRow({
            children: [
              new TableCell({
                borders,
                margins: {
                  left: convertInchesToTwip(0.2),
                },
                children: [
                  item.type === 'Section'
                    ? generateProtocolSection(item)
                    : generateProtocolField(item),
                ],
              }),
            ],
          })
      ),
    ],
  });

const generateProtocolSection = (section: any) =>
  new Table({
    borders,
    rows: [
      new TableRow({
        borders,
        children: [
          new TableCell({
            borders,
            margins: {
              bottom: convertInchesToTwip(0.25),
            },
            children: [
              new Paragraph({
                heading: HeadingLevel.HEADING_3,
                text: section?.protocolText || section?.label,
              }),
            ],
          }),
        ],
      }),

      ...section.questions.map(
        (item: any, i: number) =>
          new TableRow({
            borders,
            children: [
              new TableCell({
                borders,
                margins: {
                  left: convertInchesToTwip(0.2),
                },
                children: [
                  item.type === 'Section'
                    ? generateProtocolSection(item)
                    : generateProtocolField(item),
                ],
              }),
            ],
          })
      ),
    ],
  });

const generateProtocolField = (field: any) =>
  new Table({
    borders,
    rows: [
      new TableRow({
        borders,
        children: [
          new TableCell({
            borders,
            margins: {
              bottom: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                heading: HeadingLevel.HEADING_5,
                children: [new TextRun(field?.protocolText || field?.label)],
              }),
            ],
          }),
        ],
      }),
      ...generateProtocolFieldValue(field),
    ],
  });

const generateProtocolFieldValue = (question: any) =>
  question?.answer && question.answer.length
    ? ['ImageUpload', 'Signature'].includes(question?.type) &&
      question?.imageList
      ? generateProtocolFieldImageRow(question?.imageList, question)
      : [
          new TableRow({
            borders,
            children: [
              new TableCell({
                borders,
                margins: {
                  bottom: convertInchesToTwip(0.25),
                },
                children: question.answer?.map(
                  (answer: any, answerIndex: number) =>
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: answer ?? EMPTY_ANSWER,
                          color: '8C8C8C',
                        }),
                      ],
                    })
                ),
              }),
            ],
          }),
        ]
    : [
        new TableRow({
          borders,
          children: [
            new TableCell({
              borders,
              margins: {
                bottom: convertInchesToTwip(0.25),
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: EMPTY_ANSWER,
                      color: '8C8C8C',
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ];

const getEmptyTableRow = () =>
  new TableRow({
    height: 0,
    borders,
    margins: {
      top: 0,
      bottom: 0,
    },
    children: [
      new TableCell({
        borders,
        height: 0,
        margins: {
          top: 0,
          bottom: 0,
        },
        children: [new Paragraph('')],
      }),
    ],
  });

const generateProtocolFieldImageRow = (
  imageList: ImageBuffer[],
  question: any
) =>
  question?.type === 'Signature'
    ? [
        question?.legalText
          ? new TableRow({
              margins: 0,
              children: [
                new TableCell({
                  borders,
                  margins: {
                    bottom: convertInchesToTwip(0.1),
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: question?.legalText,
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            })
          : getEmptyTableRow(),
        ...generateProtocolFieldImage(imageList),
      ]
    : generateProtocolFieldImage(imageList);

const generateProtocolFieldImage = (imageList: ImageBuffer[]) =>
  imageList.map(
    (image: ImageBuffer) =>
      new TableRow({
        borders,
        children: [
          new TableCell({
            borders,
            margins: {
              bottom: convertInchesToTwip(0.25),
            },
            children: [
              new Paragraph({
                children: [
                  new ImageRun({
                    data: image?.buffer,
                    transformation: {
                      width: image.width,
                      height: image.height,
                    },
                  }),
                ],
              }),
            ],
          }),
        ],
      })
  );

export { getHeaderOfDocument, generateProtocolDocument };
