import styles from './ProtocolDocument.module.scss';
import ProtocolChapter from './ProtocolChapter';
import ProtocolField from './ProtocolField';

interface ProtocolDocumentProps {
  chapters: Array<any>;
  showAllSubmissions: boolean;
}

const ProtocolDocument = ({
  chapters,
  showAllSubmissions,
}: ProtocolDocumentProps) => (
  <div className={styles.documentContainer}>
    {chapters.map((chapter, i: number) =>
      ['Chapter', 'Section'].includes(chapter.type) ? (
        <ProtocolChapter
          chapter={chapter}
          key={`chapter--${i}`}
          showAllSubmissions={showAllSubmissions}
        />
      ) : (
        <ProtocolField
          field={chapter}
          key={`section-field--${i}`}
          isRoot
          showAllSubmissions={showAllSubmissions}
        />
      )
    )}
  </div>
);

export default ProtocolDocument;
