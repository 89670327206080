import { IAppointment, IAppointmentStatus } from '../../constants/types';
import { Promise } from 'bluebird';
import {
  generateProtocolDocument,
  getHeaderOfDocument,
} from './protocolDocxGenerator';
import { generateDocumentFields } from '../../utils/generateDocumentFields';
import ApiRepository from '../api/apiRepository';
import { ImageBuffer, appointmentTypeNames } from '../../pages/protocol/types';
import { getHashMap } from '../../utils/helpers';
import { filterIgnoredFields } from '../../pages/protocol/helpers';

const docx = require('docx');
const sizeOf = require('buffer-image-size');
const ENPAL_LOGO_URL =
  'https://craftosstorage.blob.core.windows.net/enpal-logo/5ea1497819897597ff26526c_enpal_vector.png';

const { Document, Packer, Paragraph, SectionType } = docx;

interface ReportState {
  questions: any;
  workorder: any;
  metaData?: { [key: string]: any };
  latestAppointmentDetails?: IAppointment;
  workorderStartDate?: string;
  appointmentTypeName?: string;
  externalCaseId?: string;
}

const ProtocolDocxGenerator = {
  async generateReport(
    externalCaseId: string,
    appointmentTypeName: string
  ): Promise<void> {
    const {
      questions,
      workorder,
      metaData,
      latestAppointmentDetails,
      workorderStartDate,
    } = (await this.fetchProtocolData(externalCaseId, appointmentTypeName))!;

    await this.generateDocx({
      questions,
      workorder,
      metaData,
      latestAppointmentDetails,
      workorderStartDate,
      appointmentTypeName,
      externalCaseId,
    });
  },

  async fetchProtocolData(
    externalCaseId: string,
    appointmentTypeName: string
  ): Promise<ReportState> {
    const workorder = (
      await ApiRepository.getWorkOrdersDetailsData(
        externalCaseId,
        appointmentTypeName
      )
    )?.response;

    let questions = (
      await ApiRepository.getDocumentsData(
        externalCaseId,
        appointmentTypeNames[appointmentTypeName] as string
      )
    )?.response?.questions;

    questions = filterIgnoredFields(questions);

    questions = ['MVT', 'Sep. Zählertausch', 'Customer Service', 'ZT'].includes(
      appointmentTypeName
    )
      ? questions[0].questions
      : questions;

    const woAppointments = workorder?.appointments;

    const firstStartedAppointment = woAppointments.find(
      (app: IAppointment) => app?.status !== 'NotStarted'
    );

    const workorderStartDate = firstStartedAppointment?.appointmentDate ?? '';

    let metaData: { [key: string]: any } = {};
    if (woAppointments?.length) {
      const latestAppointment = woAppointments
        .reverse()
        .find(
          (app: IAppointment) =>
            app?.status !== 'NotStarted' &&
            app?.externalStatus !== IAppointmentStatus.cancelled
        );

      const latestAppointmentDetails: IAppointment = (
        await ApiRepository.getAppointmentsDetailsData(latestAppointment.id)
      )?.response;

      if (latestAppointmentDetails?.caseId) {
        const responseMetadata = await ApiRepository.getMetadataInfos(
          latestAppointmentDetails?.caseId
        );
        metaData = getHashMap(responseMetadata?.response);
        metaData['ENPAL_LOGO'] = await this.getImageBuffer(ENPAL_LOGO_URL);
      }

      await this.loadImagesData({ questions });

      return {
        workorder,
        questions,
        workorderStartDate,
        latestAppointmentDetails: latestAppointmentDetails as IAppointment,
        metaData,
      };
    }

    return {
      questions: [],
      workorder: {},
    };
  },

  async updateFieldAnswer(fields: any, fa: any) {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field?.children && field.children?.length > 0)
        await this.updateFieldAnswer(field.children, fa);

      if (field.id === fa.formFieldId) {
        field.answer = fa.answers[0];
        field.formFieldId = fa.formFieldId;
      }
    }
  },

  async loadImagesData(question: any) {
    if (question?.questions) {
      await Promise.map(
        question?.questions ?? [],
        async (item: any) => await this.loadImagesData(item)
      );
    } else if (
      question?.type &&
      ['ImageUpload', 'Signature'].includes(question?.type)
    ) {
      await this.loadAndCompressImages(question);
    }
  },

  async getImageBuffer(url: string): Promise<Buffer> {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return Buffer.from(new Uint8Array(arrayBuffer));
  },

  async loadAndCompressImages(question: any) {
    const imageList: ImageBuffer[] = await Promise.map(
      question?.answer ?? [],
      async (url: string) => {
        const buffer = await this.getImageBuffer(url);
        const size = sizeOf(buffer);
        const maxWidth = 600;
        const ratio = maxWidth / size.width;
        const height = size.height * ratio;
        const width = size.width * ratio;

        return {
          url,
          width,
          height,
          buffer,
        } as ImageBuffer;
      }
    );

    question['imageList'] = imageList;
  },

  async generateDocx({
    questions,
    workorder,
    metaData,
    latestAppointmentDetails,
    workorderStartDate,
    appointmentTypeName,
    externalCaseId,
  }: ReportState) {
    const header = getHeaderOfDocument(
      latestAppointmentDetails,
      metaData,
      workorderStartDate
    );
    const documentContent = generateProtocolDocument(questions);

    const doc = new Document({
      sections: [
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [...header, new Paragraph('')],
        },
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [...documentContent],
        },
      ],
      styles: {
        default: {
          run: {
            font: 'Calibri',
            fontFamily: 'Arial, sans-serif, Calibri',
            color: '000000',
          },
          heading1: {
            run: {
              size: 36,
              bold: true,
            },
            paragraph: {
              spacing: {
                after: 120,
              },
            },
          },
          heading2: {
            run: {
              size: 32,
              bold: true,
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 40,
              },
            },
          },
          heading3: {
            run: {
              size: 28,
              bold: true,
            },
            paragraph: {
              spacing: {
                before: 120,
                after: 40,
              },
            },
          },
          heading5: {
            run: {
              size: 22,
              bold: true,
            },
            paragraph: {
              spacing: {
                before: 120,
                after: 20,
              },
            },
          },
        },
      },
    });

    const byte = await Packer.toBuffer(doc);
    var blob = new Blob([byte], { type: 'application/docx' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var { fileNameStart, fileNameEnd } = generateDocumentFields(
      appointmentTypeName!
    );
    var fileName = `${fileNameStart}${workorder.customer?.name}${
      appointmentTypeName === 'Montage' ? '_' : '-'
    }${externalCaseId}${fileNameEnd}.docx`;
    link.download = fileName;
    link.click();
  },
};

export default ProtocolDocxGenerator;
