import { AnyAction } from 'redux';
import {
  progressTableTypes,
  WeeklyAppointmentsTableRequestType,
  progressTeamsTypes,
  DashboardType,
} from '../../constants/types';
import ApiRepository from '../../services/api/apiRepository';

const actions = {
  getTeamsData() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: progressTeamsTypes.GET_PROGRESS_TEAMS_DATA,
          payload: await ApiRepository.getTeamsData(),
        });
      } catch (err: any) {
        dispatch({
          type: progressTableTypes.GET_PROGRESS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getAppointmentTypesData() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: progressTableTypes.GET_ALL_APPOINTMENT_TYPES_DATA,
          payload: await ApiRepository.getAppointmentTypesData(),
        });
      } catch (err: any) {
        dispatch({
          type: progressTableTypes.GET_PROGRESS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getAppointmentAndWorkOrderStatusList() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      let appStatusPromise = ApiRepository.getEntityStatusList(
        'ServiceAppointment'
      );
      let woStatusPromise = ApiRepository.getEntityStatusList('WorkOrder');

      let appointmentStatusList = (await appStatusPromise)?.response;
      let workOrderStatusList = (await woStatusPromise)?.response;
      try {
        dispatch({
          type: progressTableTypes.GET_APPOINTMENT_WORKORDER_STATUS_LIST,
          payload: { appointmentStatusList, workOrderStatusList },
        });
      } catch (err: any) {
        dispatch({
          type: progressTableTypes.GET_PROGRESS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  setTeamsLoading(loading: boolean) {
    return {
      type: progressTeamsTypes.SET_PROGRESS_TEAMS_LOADING,
      payload: loading,
    };
  },

  getWeeklyAppointmentsData({
    week,
    team,
    filters,
    customerSearch,
    appointmentTypes,
    dashboardType,
  }: WeeklyAppointmentsTableRequestType) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const data = await ApiRepository.getWeeklyAppointmentsData({
          week,
          team,
          appointmentTypes,
          dashboardType,
        });
        dispatch({
          type: progressTableTypes.GET_PROGRESS_DATA,
          payload: { response: data?.response, filters, customerSearch },
        });
      } catch (err: any) {
        dispatch({
          type: progressTableTypes.GET_PROGRESS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  setDashboardType(payload: DashboardType) {
    return {
      type: progressTableTypes.SET_PROGRESS_DASHBOARD_TYPE,
      payload,
    };
  },

  setLoading(loading: boolean) {
    return {
      type: progressTableTypes.SET_PROGRESS_DATA_LOADING,
      payload: loading,
    };
  },

  resetAppointmentsTableError() {
    return {
      type: progressTableTypes.GET_PROGRESS_DATA_FAIL,
      payload: null,
    };
  },

  applyFilters(filter: string[], customerSearch: string = '') {
    return {
      type: progressTableTypes.APPLY_PROGRESS_FILTER,
      payload: { filter, customerSearch },
    };
  },
};

export default actions;
