import translation from '../../../i18n/translation';
import { Space, Typography } from 'antd';
import styles from '../delayAndStatusModal.module.scss';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { IAppointmentStatus } from '../../../constants/types';

const { Text } = Typography;

interface Props {
  status: string;
  isLastAppointment?: boolean;
}

const StatusChangeNotification: React.FC<Props> = ({
  status,
  isLastAppointment = false,
}) => {
  return (
    <Space direction="vertical">
      <Space className={styles.flexAlignCenter}>
        <ExclamationCircleFilled
          style={{ fontSize: '24px', color: '#FFC53D' }}
        />{' '}
        <Text className={styles.heading}>
          {status === IAppointmentStatus.completed
            ? translation('cannot_change_status_to_completed')
            : status === IAppointmentStatus.inProgress
            ? translation('cannot_change_status_to_in_progress')
            : translation('cannot_change_status_to_scheduled')}
        </Text>
      </Space>
      {status === IAppointmentStatus.completed ? (
        <Space direction="vertical" style={{ padding: '8px 8px 0 42px' }}>
          <Text>{translation('status_change_completed_message')}</Text>
          <Text>
            &#8226; {translation('status_change_completed_first_condition')}
          </Text>
          {isLastAppointment && (
            <Text>
              &#8226; {translation('status_change_completed_third_condition')}
            </Text>
          )}
          <Text>
            &#8226; {translation('status_change_completed_second_condition')}
          </Text>
        </Space>
      ) : status === IAppointmentStatus.inProgress ? (
        <Space direction="vertical" style={{ padding: '8px 8px 0 42px' }}>
          <Text>{translation('status_change_in_progress_message')}</Text>
        </Space>
      ) : null}
    </Space>
  );
};

export default StatusChangeNotification;
