import { FC, useMemo } from 'react';
import { Form, Space, Tooltip, Typography } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FieldItemProps, FormFieldType } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';
import DefaultTextView from './DefaultTextView';
import { SUPPORTED_LANGUAGES } from '../../../constants/types';
import { htmlToText, validateFormInstance } from '../utils';

const { Text } = Typography;

const modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
};

const formats = [
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'script',
  'indent',
  'link',
  'color',
  'background',
  'align',
];

const FormItemContent: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
  language,
  defaultLanguage,
  isFieldLocked,
  hidden,
}) => {
  const selectedFieldType = Form.useWatch('type');
  const fieldValue = Form.useWatch(['content', defaultLanguage]);
  const form = Form.useFormInstance();

  const publishedItemValues = useMemo(
    () => ({
      content: fieldChanges?.content.publishedItem,
    }),
    [fieldChanges?.content]
  );

  const handlePaste = (language: SUPPORTED_LANGUAGES) => {
    form.setFieldValue(['content', language], fieldValue);
    validateFormInstance(form);
  };

  return (
    <Form.Item
      hidden={hidden}
      rules={[
        {
          required: selectedFieldType === FormFieldType.Instruction,
          message: 'The instruction text is required for this field.',
        },
        isFieldLocked
          ? ({ getFieldValue }: any) => ({
              validator: () => {
                let currentValue = getFieldValue('content');
                if (
                  language !== defaultLanguage &&
                  currentValue &&
                  !!htmlToText(currentValue[defaultLanguage!]) !==
                    !!htmlToText(currentValue[language!])
                ) {
                  return Promise.reject(
                    `Field value not configured correctly. Please refer to ${defaultLanguage?.toUpperCase()} language`
                  );
                }
                return Promise.resolve();
              },
            })
          : {},
      ]}
      name={['content', language!]}
      label={
        <Space>
          {fieldChanges?.content.hasChanges ? (
            <Text mark>
              <Tooltip title={translation('instruction_text_tooltip')}>
                <span>{translation('instruction_text')} </span>{' '}
              </Tooltip>
              {
                <PublishedItem initialValues={publishedItemValues}>
                  <FormItemContent />
                </PublishedItem>
              }
            </Text>
          ) : (
            <Tooltip title={translation('instruction_text_tooltip')}>
              <span>{translation('instruction_text')} </span>
            </Tooltip>
          )}
          {language !== defaultLanguage && htmlToText(fieldValue!) && (
            <DefaultTextView
              asHTML
              defaultValue={fieldValue}
              onPasteContent={editMode ? () => handlePaste(language!) : null}
            />
          )}
        </Space>
      }
    >
      {editMode ? (
        <ReactQuill
          modules={modules}
          formats={formats}
          key={`richText-edit-${language}`}
          onKeyPress={handlePaste}
        />
      ) : (
        <ReactQuill
          readOnly
          modules={{ toolbar: false }}
          key={`richText-read-${language}`}
        />
      )}
    </Form.Item>
  );
};

export default FormItemContent;
