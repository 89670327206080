import { FC, useMemo } from 'react';
import {
  Form,
  Select,
  Typography,
  InputNumber,
  Space,
  Input,
  Tooltip,
} from 'antd';
import { FieldItemProps, Answer } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';
import { SUPPORTED_LANGUAGES } from '../../../constants/types';
import DefaultTextView from './DefaultTextView';
import { languageFieldDefaultCheckRule } from '../utils';

const { Text, Title } = Typography;
const { Option } = Select;

interface Props extends FieldItemProps {
  selectedLanguage: SUPPORTED_LANGUAGES;
  supportedLanguages: SUPPORTED_LANGUAGES[];
}

const FormItemAutoValidation: FC<Props> = ({
  editMode,
  fieldChanges,
  defaultLanguage,
  selectedLanguage,
  supportedLanguages,
  isFieldLocked,
}) => {
  const answers = Form.useWatch('answers');
  const type = Form.useWatch('type');
  const range = Form.useWatch('range');
  const validAnswers = Form.useWatch('validAnswers');
  const displayAs = Form.useWatch('displayAs');
  const errorMessageValue = Form.useWatch(['errorMessage', defaultLanguage]);

  const publishedItemValues = useMemo(
    () => ({
      autoValidation: fieldChanges?.autoValidation?.publishedItem,
    }),
    [fieldChanges]
  );

  return (type === 'Input' && displayAs === 'Numeric') || type === 'Choice' ? (
    <>
      {type === 'Input' && (
        <Form.Item
          style={{ marginBottom: 0 }}
          label={
            fieldChanges?.requiredAnswerCount?.hasChanges ? (
              <Text mark>
                <Tooltip title={translation('range_tooltip')}>
                  <span> {translation('range')} </span>{' '}
                </Tooltip>

                {
                  <PublishedItem initialValues={publishedItemValues}>
                    <FormItemAutoValidation
                      selectedLanguage={selectedLanguage}
                      defaultLanguage={defaultLanguage}
                      supportedLanguages={supportedLanguages}
                      isFieldLocked={isFieldLocked}
                    />
                  </PublishedItem>
                }
              </Text>
            ) : (
              <Tooltip title={translation('range_tooltip')}>
                <span> {translation('range')} </span>{' '}
              </Tooltip>
            )
          }
        >
          <Space style={{ alignItems: 'start' }}>
            <Form.Item
              name={['range', 'min']}
              rules={[
                {
                  type: 'number' || undefined || null,
                  max: range?.max,
                  message: 'The value has to be smaller or equal to Max value',
                },
              ]}
            >
              <InputNumber
                disabled={!editMode || isFieldLocked}
                type="number"
                style={{ maxWidth: '90px' }}
              />
            </Form.Item>
            <Title
              level={5}
              style={{
                padding: '0 16px',
                marginBottom: '24px',
                minWidth: '92px',
                height: '100%',
              }}
            >
              <div style={{ minWidth: '92px', height: '100%' }}>
                {'<=  N  <='}
              </div>
            </Title>
            <Form.Item
              name={['range', 'max']}
              rules={[
                {
                  type: 'number' || undefined || null,
                  min: range?.min,
                  message: 'The value has to be greater or equal to Min value',
                },
              ]}
            >
              <InputNumber
                disabled={!editMode || isFieldLocked}
                type="number"
                style={{ maxWidth: '90px' }}
              />
            </Form.Item>
          </Space>
        </Form.Item>
      )}
      {supportedLanguages?.map((lang: SUPPORTED_LANGUAGES) => [
        type === 'Choice' ? (
          <Form.Item
            hidden={lang !== selectedLanguage}
            key={`valid_answers-${lang}`}
            label={
              <Tooltip title={translation('valid_answers_tooltip')}>
                <span> {translation('valid_answers')} </span>
              </Tooltip>
            }
            name="validAnswers"
            tooltip={
              type === 'Choice' &&
              (!editMode || !answers?.some((answer: Answer) => answer?.id))
                ? translation('save_answer_first')
                : undefined
            }
          >
            <Select
              disabled={
                !editMode ||
                isFieldLocked ||
                !answers?.some((answer: Answer) => answer?.id)
              }
              mode="multiple"
            >
              {answers?.map(
                (answer: Answer) =>
                  answer?.id && (
                    <Option
                      key={`valid_answers-${lang}-${answer.id}`}
                      value={answer.id}
                    >
                      {answer?.label[selectedLanguage] ??
                        answer?.label[answer?.label.default]}
                    </Option>
                  )
              )}
            </Select>
          </Form.Item>
        ) : null,

        <Form.Item
          hidden={lang !== selectedLanguage}
          key={`error_message-${lang}`}
          name={['errorMessage', lang]}
          label={
            <Space direction="horizontal">
              <Tooltip title={translation('error_message_tooltip')}>
                <span> {translation('error_message')}</span>{' '}
              </Tooltip>

              {lang !== defaultLanguage && (
                <DefaultTextView defaultValue={errorMessageValue} />
              )}
            </Space>
          }
          tooltip={
            type === 'Choice' &&
            (!editMode || !answers?.some((answer: Answer) => answer?.id))
              ? translation('save_error_message_after_answers')
              : editMode
              ? translation('error_message_required_with_choice')
              : undefined
          }
          rules={[
            {
              required:
                !!range?.min ||
                range?.min === 0 ||
                !!range?.max ||
                range?.max === 0 ||
                (!!validAnswers?.length && validAnswers.length > 0)
                  ? true
                  : false,
              message:
                'The error message is required if the correct answer is selected.',
            },
            languageFieldDefaultCheckRule(
              'errorMessage',
              lang!,
              defaultLanguage!
            ),
          ]}
        >
          <Input
            placeholder={errorMessageValue}
            disabled={
              type === 'Choice'
                ? !editMode || !answers?.some((answer: Answer) => answer?.id)
                : !editMode
            }
          />
        </Form.Item>,
      ])}
    </>
  ) : (
    <></>
  );
};
export default FormItemAutoValidation;
