import { EnviromentType } from 'craftos-ui';
import { domainIncludes } from './helpers';

export const getEnv = (): EnviromentType => {
  if (isTest()) {
    return 'test';
  } else if (isAcademy()) {
    return 'academy';
  } else if (isIntegration()) {
    return 'integration';
  } else if (isDevelopment()) {
    return 'dev';
  } else if (isDynamo()) {
    return 'dynamo';
  } else if (isLocalhost()) {
    return 'localhost';
  }

  return 'production';
};

export const getApiUrl = (): string => {
  if (isProduction()) {
    return '';
  } else if (isAcademy()) {
    return '-academy';
  } else if (isIntegration()) {
    return '-integration';
  } else if (isSandbox01()) {
    return '-dev-sb01';
  } else if (isSandbox02()) {
    return '-dev-sb02';
  } else if (isDevelopment()) {
    return '-dev';
  }

  return '-test';
};

const getSFUatEnvUrl = (): string => {
  if (
    isAcademy() ||
    isIntegration() ||
    isTest() ||
    isLocalhost() ||
    isDevelopment() ||
    isSandbox01() ||
    isSandbox02()
  ) {
    return 'enpalgmbh2--opsuat2.sandbox';
  }

  return 'enpalgmbh2';
};

export const getBuildabilityAppUrl = (): string =>
  isAcademy() || isIntegration() || isTest() || isLocalhost() || isDevelopment()
    ? 'https://buildability-development.craftos.enpal.tech'
    : `https://ipa.craftos.enpal.tech`;

export const getPlannerAppUrl = (): string =>
  isAcademy() || isIntegration()
    ? 'https://planner-test.craftos.enpal.tech'
    : `https://planner${getApiUrl()}.craftos.enpal.tech`;

export const getSFIntegrationUrl = (): string =>
  isAcademy()
    ? 'https://enpalgmbh2--craftacad.sandbox.my.salesforce.com/lightning'
    : `https://${getSFUatEnvUrl()}.lightning.force.com/lightning`;

const isTest = (): boolean => domainIncludes('-test');
const isLocalhost = (): boolean => domainIncludes('localhost');
const isAcademy = (): boolean => domainIncludes('-academy');
const isDynamo = (): boolean => domainIncludes('-dynamo');
const isIntegration = (): boolean => domainIncludes('integration');
const isSandbox01 = (): boolean => domainIncludes('-dev-sb01');
const isSandbox02 = (): boolean => domainIncludes('-dev-sb02');
const isDevelopment = (): boolean => domainIncludes('-dev');
const isProduction = (): boolean =>
  domainIncludes('fulfilment.craftos.enpal.tech') ||
  domainIncludes('fulfilment.craftos.enpal.io');
