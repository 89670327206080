import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Checkbox,
  Table,
  Tag,
  Space,
  Layout,
  Progress,
  Button,
  TreeSelect,
  Typography,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { DownloadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { RootState } from '../../store';
import { AppointmentType, ITeam } from '../../constants/types';
import AppointmentsTableActions from './actions';
import * as routes from '../../router/routes';
import styles from './workorders.module.scss';
import translation from '../../i18n/translation';
import {
  isValidObjectId,
  isValidExtCaseId,
  getTheCurrentDay,
  groupBy,
} from '../../utils/helpers';
import Search from '../../components/search';
import { showNotificationMessage } from '../../utils/notification';
import { Content } from 'antd/lib/layout/layout';
import useAutoFetchData from '../../utils/useAutoFetchData';
import { TreeNode } from 'antd/lib/tree-select';
import PageLayout from '../../components/appLayout';
import { useSaveLocationSearchParams } from 'craftos-ui';

const { Link } = Typography;

const findStatusTagLabel = (status: string) => {
  switch (status) {
    case 'Stopped': {
      return translation('stopped');
    }
    case 'Closed': {
      return translation('closed');
    }
    case 'Active': {
      return translation('active');
    }
    default:
      return '';
  }
};

const findStatusTagColor = (status: string): string => {
  switch (status) {
    case 'Active': {
      return '#64D59F';
    }
    default:
      return 'default';
  }
};

interface IChangeUrl {
  appointmentStatus?: string;
  status?: string;
  pageNumber?: number;
  pageSize?: number;
  presentDay?: boolean;
  customer?: string;
  caseId?: string;
  externalCaseId?: string;
  team?: string;
  questions?: boolean;
}

interface ITeams {
  [key: string]: ITeam[];
}

interface ICheckboxValue {
  value: string;
  label: any;
}

const checkboxValue: ICheckboxValue[] = [
  { value: 'Open', label: translation('open') },
  { value: 'Submitted', label: translation('submitted') },
  { value: 'Resubmitted', label: translation('re_submitted') },
  { value: 'InReview', label: translation('in_review') },
  { value: 'Rejected', label: translation('rejected') },
  { value: 'Approved', label: translation('approved') },
];

const Appointment: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();

  const appointmentsTableState = useSelector(
    (state: RootState) => state.appointmentsTable
  );
  const appointmentsTeamsState = useSelector(
    (state: RootState) => state.appointmentsTeams
  );

  const getQueryString = useCallback((string: string | null): IChangeUrl => {
    if (!string) return {};
    return string
      .slice(1)
      .split('&')
      .reduce((total: object, current: string) => {
        const res = current.split('=');
        return {
          ...total,
          [res[0]]: res[1],
        };
      }, {});
  }, []);

  const [teamsList, setTeamsList] = useState<ITeam[]>([]);
  const [teamsGroup, setTeamsGroup] = useState<ITeams>({});

  let statusParam = getQueryString(location.search).status ?? '';

  const [status, setStatus] = useState<string[]>(
    statusParam ? statusParam.split(',') : []
  );

  const [questions, setQuestions] = useState<boolean>(
    getQueryString(location.search).questions
      ? Boolean(getQueryString(location.search).questions)
      : false
  );

  const [pageSize, setPageSize] = useState<number>(
    getQueryString(location.search).pageSize
      ? Number(getQueryString(location.search).pageSize)
      : 10
  );

  const [appointmentStatus, setAppointmentStatus] = useState(
    getQueryString(location.search).appointmentStatus
      ? getQueryString(location.search).appointmentStatus
      : ''
  );
  const [presentDay, setPresentDay] = useState(
    getQueryString(location.search).presentDay
      ? Boolean(getQueryString(location.search).presentDay)
      : false
  );

  const [searchValue, setSearchValue] = useState(
    getQueryString(location.search).customer
      ? decodeURI(getQueryString(location.search).customer || '')
      : getQueryString(location.search).caseId
      ? getQueryString(location.search).caseId
      : getQueryString(location.search).externalCaseId
      ? getQueryString(location.search).externalCaseId
      : ''
  );

  let teamsParam = getQueryString(location.search).team ?? '';
  const [teams, setTeams] = useState<string[]>(
    teamsParam ? decodeURIComponent(teamsParam).split(',') : []
  );

  useEffect(() => {
    dispatch(AppointmentsTableActions.setTeamsLoading(true));
    dispatch(AppointmentsTableActions.getTeamsData());
  }, [dispatch]);

  useEffect(() => {
    sessionStorage.setItem('appointmentUrl', `${location.search}`);
    const queryUrl = sessionStorage.getItem('appointmentUrl')
      ? sessionStorage.getItem('appointmentUrl')
      : '';
    setAppointmentStatus(
      getQueryString(`${queryUrl}`).appointmentStatus
        ? getQueryString(queryUrl).appointmentStatus
        : ''
    );
    setPresentDay(Boolean(getQueryString(queryUrl).presentDay));
    const currentDay = presentDay
      ? { ...getTheCurrentDay() }
      : { startDate: '', endDate: '' };

    dispatch(AppointmentsTableActions.setLoading(true));
    dispatch(
      AppointmentsTableActions.getAppointmentsData({
        startDate: currentDay.startDate,
        endDate: currentDay.endDate,
        status: status[0] !== '0' ? status : [],
        appointmentStatus: getQueryString(queryUrl).appointmentStatus
          ? getQueryString(queryUrl).appointmentStatus
          : '',
        pageNumber: getQueryString(queryUrl).pageNumber
          ? Number(getQueryString(queryUrl).pageNumber)
          : 1,
        pageSize: pageSize,
        customer: getQueryString(queryUrl).customer,
        caseId: getQueryString(queryUrl).caseId,
        team: teams,
        questions,
        externalCaseId: getQueryString(queryUrl).externalCaseId,
      })
    );
  }, [
    location.search,
    getQueryString,
    presentDay,
    dispatch,
    pageSize,
    teams,
    status,
    questions,
  ]);

  useAutoFetchData(() => {
    const queryUrl = sessionStorage.getItem('appointmentUrl')
      ? sessionStorage.getItem('appointmentUrl')
      : '';
    const currentDay = presentDay
      ? { ...getTheCurrentDay() }
      : { startDate: '', endDate: '' };
    dispatch(
      AppointmentsTableActions.getAppointmentsData({
        startDate: currentDay.startDate,
        endDate: currentDay.endDate,
        status: status[0] !== '0' ? status : [],
        appointmentStatus: getQueryString(queryUrl).appointmentStatus
          ? getQueryString(queryUrl).appointmentStatus
          : '',
        pageNumber: getQueryString(queryUrl).pageNumber
          ? Number(getQueryString(queryUrl).pageNumber)
          : 1,
        pageSize: pageSize,
        customer: getQueryString(queryUrl).customer,
        caseId: getQueryString(queryUrl).caseId,
        externalCaseId: getQueryString(queryUrl).externalCaseId,
        team: teams,
        questions,
      })
    );
  });

  useEffect(() => {
    if (appointmentsTableState.error) {
      showNotificationMessage({
        message: intl.formatMessage({
          id:
            appointmentsTableState.error.errorKey ?? 'error_fetch_apointments',
        }),
        error: appointmentsTableState.error,
        onClose: () =>
          dispatch(AppointmentsTableActions.resetAppointmentsTableError()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentsTableState.error]);

  useEffect(() => {
    if (
      !appointmentsTeamsState.loading &&
      appointmentsTeamsState.appointmentsTeamsData.length
    ) {
      const teamGroups = groupBy(
        appointmentsTeamsState.appointmentsTeamsData,
        'location'
      );
      setTeamsList(appointmentsTeamsState.appointmentsTeamsData);
      setTeamsGroup(teamGroups);
    }
  }, [
    appointmentsTableState.error,
    appointmentsTeamsState.appointmentsTeamsData,
    appointmentsTeamsState.loading,
  ]);

  // Redirect to last page with items
  useEffect(() => {
    const pageParam = getQueryString(location.search).pageNumber;
    if (pageParam && appointmentsTableState.pagination.totalCount !== 0) {
      var lastPage = Math.ceil(
        appointmentsTableState.pagination.totalCount /
          appointmentsTableState.pagination.pageSize
      );
      if (pageParam > lastPage) {
        onChangePagination(
          lastPage,
          appointmentsTableState.pagination.pageSize
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentsTableState.pagination]);

  useSaveLocationSearchParams(routes.workOrders.path);

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) =>
        (status === 'Stopped' ||
          status === 'Active' ||
          status === 'Closed') && (
          <Tag className={styles.statusText} color={findStatusTagColor(status)}>
            {findStatusTagLabel(status)}
          </Tag>
        ),
      width: '90px',
    },
    {
      title: translation('customer_name'),
      dataIndex: 'name',
      key: 'customer_name',
    },
    {
      title: translation('case_id'),
      dataIndex: 'workOrderId',
      key: 'workOrderId',
    },
    {
      title: translation('work_order_type'),
      dataIndex: 'appointment',
      key: 'appointment',
    },
    {
      title: translation('appointmentDate'),
      dataIndex: 'appointmentDate',
      key: 'appointmentDate',
    },
    {
      title: translation('team'),
      dataIndex: 'teamName',
      key: 'teamName',
    },
    {
      title: translation('progress'),
      dataIndex: 'total',
      key: 'progress',
      render: (total: number, field: any) => (
        <Link
          onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            navigate(
              {
                pathname: routes.appointmentsDetails.pathWithParams(
                  routes.ROOT_PAGES.workOrders,
                  field?.status === 'NotStarted' && field?.previousAppointmentId
                    ? field!.previousAppointmentId
                    : field!.id
                ),
              },
              { state: { url1: location.search } }
            );
          }}
        >
          <Progress
            percent={parseInt(String((field.approved / total) * 100))}
            size="small"
          />
        </Link>
      ),
    },
    {
      title: 'AP',
      dataIndex: 'id',
      width: '60px',
      render: (id: string, field: any) => (
        <Button
          key="btn_report_generating"
          loading={appointmentsTableState.generatingReportId === id}
          disabled={
            appointmentsTableState.generatingReportId &&
            appointmentsTableState.generatingReportId !== id
          }
          type="text"
          style={{ border: 'none' }}
          icon={<DownloadOutlined />}
          onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            dispatch(
              AppointmentsTableActions.generateReport(field?.externalId, id)
            );
          }}
        />
      ),
    },
  ];

  const changeUrl = ({
    status,
    appointmentStatus,
    pageNumber,
    pageSize,
    presentDay,
    customer,
    caseId,
    externalCaseId,
    team,
    questions,
  }: IChangeUrl) => {
    const statusUrl = status ? `&status=${status}` : '';

    const pageNumberUrl = pageNumber ? `&pageNumber=${pageNumber}` : '';
    const pageSizeUrl = pageSize ? `&pageSize=${pageSize}` : '';

    const presentDayUrl = presentDay ? `&presentDay=${presentDay}` : ``;
    const customerUrl = customer ? `&customer=${customer}` : ``;
    const caseIdUrl = caseId ? `&caseId=${caseId}` : ``;
    const externalCaseIUrl = externalCaseId
      ? `&externalCaseId=${externalCaseId}`
      : ``;
    const teamUrl = team ? `&team=${encodeURIComponent(team)}` : ``;
    const appointmentStatusUrl = appointmentStatus
      ? `&appointmentStatus=${appointmentStatus}`
      : ``;

    const questionsUrl = questions ? `&questions=${questions}` : ``;

    const url = `${statusUrl}${appointmentStatusUrl}${pageNumberUrl}${pageSizeUrl}${presentDayUrl}${customerUrl}${caseIdUrl}${externalCaseIUrl}${teamUrl}${questionsUrl}`.slice(
      1
    );

    navigate({
      pathname: location.pathname,
      search: `?${url}`,
    });
  };

  const onStatusChange = (checkedValues: any) => {
    setStatus(checkedValues.length ? checkedValues : ['0']);
    changeUrl({
      status: checkedValues.length ? checkedValues.join(',') : '0',
      appointmentStatus,
      presentDay,
      team: teams.join(','),
      customer:
        !isValidExtCaseId(searchValue) && !isValidObjectId(searchValue)
          ? searchValue
          : undefined,
      caseId: isValidObjectId(searchValue) ? searchValue : undefined,
      externalCaseId: isValidExtCaseId(searchValue) ? searchValue : undefined,
      pageSize,
      questions,
    });
  };

  const onChangePagination = (page: number, pageSize: number | undefined) => {
    if (pageSize) setPageSize(pageSize);
    changeUrl({
      status: status.join(','),
      appointmentStatus,
      pageNumber: page,
      pageSize,
      presentDay,
      team: teams.join(','),
      questions,
      customer:
        !isValidExtCaseId(searchValue) && !isValidObjectId(searchValue)
          ? searchValue
          : undefined,
      caseId: isValidObjectId(searchValue) ? searchValue : undefined,
      externalCaseId: isValidExtCaseId(searchValue) ? searchValue : undefined,
    });
  };

  const dataSourceTable = appointmentsTableState.appointmentsTableData.map(
    (item: AppointmentType) => ({
      ...item,
      key: item.id,
      questions: item.questions,
      name: item.customer?.name,
      workOrderId: item.externalCaseId,
      teamName: item.team?.name,
      appointment: item.appointmentType?.name,
      appointmentDate: intl.formatDate(item.appointmentDate),
      approved:
        typeof item.answerCounts?.approved === 'number'
          ? item.answerCounts?.approved
          : '-',
      total:
        typeof item.answerCounts?.total === 'number'
          ? item.answerCounts?.approvalPending +
            (item.appointmentType?.name === 'Sep. Zählertausch' || 'MVT'
              ? item.answerCounts?.openRequired
              : item.answerCounts?.openApprovable) +
            item.answerCounts?.rejected +
            item.answerCounts?.approved
          : '-',
      openItem: item.id,
    })
  );
  const onSetPresentDay = () => {
    setPresentDay((state) => {
      dispatch(AppointmentsTableActions.setDefaultPagination());
      changeUrl({
        status: status.join(','),
        appointmentStatus,
        team: teams.join(','),
        presentDay: !state,
        customer:
          !isValidExtCaseId(searchValue) && !isValidObjectId(searchValue)
            ? searchValue
            : undefined,
        caseId: isValidObjectId(searchValue) ? searchValue : undefined,
        externalCaseId: isValidExtCaseId(searchValue) ? searchValue : undefined,
        pageSize,
        questions,
      });
      return !state;
    });
  };

  const onSetAppointmentStatus = (value: string) => {
    setAppointmentStatus((state) => {
      changeUrl({
        status: status.join(','),
        appointmentStatus: state !== value ? value : '',
        team: teams.join(','),
        presentDay: presentDay,
        customer:
          !isValidExtCaseId(searchValue) && !isValidObjectId(searchValue)
            ? searchValue
            : undefined,
        caseId: isValidObjectId(searchValue) ? searchValue : undefined,
        externalCaseId: isValidExtCaseId(searchValue) ? searchValue : undefined,
        pageSize,
        questions,
      });
      return state !== value ? value : '';
    });
  };

  const onSetSearch = (val: string) => {
    setSearchValue(val);
    changeUrl({
      presentDay,
      status: status.join(','),
      appointmentStatus,
      customer:
        !isValidExtCaseId(val) && !isValidObjectId(val) ? val : undefined,
      caseId: isValidObjectId(val) ? val : undefined,
      externalCaseId: isValidExtCaseId(val) ? val : undefined,
      team: teams.join(','),
      pageSize,
      questions,
    });
  };

  const onSetQuestions = () => {
    setQuestions((state) => {
      changeUrl({
        status: status.join(','),
        appointmentStatus: appointmentStatus,
        team: teams.join(','),
        presentDay: presentDay,
        customer:
          !isValidExtCaseId(searchValue) && !isValidObjectId(searchValue)
            ? searchValue
            : undefined,
        caseId: isValidObjectId(searchValue) ? searchValue : undefined,
        externalCaseId: isValidExtCaseId(searchValue) ? searchValue : undefined,
        questions: !state,
        pageSize,
      });
      return !state;
    });
  };

  const onTeamsFilterItemSelect = (teams: string[]) => {
    let expandedTeams: string[] = [];
    teams.forEach((t: string) => {
      if (teamsGroup[t]) {
        expandedTeams = [
          ...expandedTeams,
          ...teamsGroup[t].map((tm: ITeam) => tm.id),
        ];
      } else {
        expandedTeams.push(t);
      }
    });

    setTeams(expandedTeams);
    changeUrl({
      status: status.join(','),
      appointmentStatus,
      team: expandedTeams.join(','),
      presentDay,
      customer:
        !isValidExtCaseId(searchValue) && !isValidObjectId(searchValue)
          ? searchValue
          : undefined,
      caseId: isValidObjectId(searchValue) ? searchValue : undefined,
      externalCaseId: isValidExtCaseId(searchValue) ? searchValue : undefined,
      pageSize,
      questions,
    });
  };

  const onResetTeamFilter = (): void => {
    setTeams([]);
    changeUrl({
      status: status.join(','),
      appointmentStatus,
      presentDay,
      questions,
    });
  };

  return (
    <PageLayout>
      <Layout className={styles.contentLayout}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PageHeader
            className={styles.header}
            subTitle={intl.formatDate(new Date())}
            title={translation('workorders')}
          />
          <Space className={styles.headerExtras}>
            <TreeSelect
              className={styles.worktypeFilterTree}
              style={{ width: '40vw', paddingRight: '8px' }}
              value={teamsList.length > 0 ? teams : []}
              onChange={onTeamsFilterItemSelect}
              onClear={onResetTeamFilter}
              allowClear
              treeCheckable
              maxTagCount="responsive"
              loading={!teamsList.length}
              popupMatchSelectWidth={false}
              treeNodeLabelProp="title"
              showCheckedStrategy="SHOW_PARENT"
              placeholder={translation('select_teams')}
              filterTreeNode={(input: String, option: any) =>
                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teamsGroup &&
                Object.keys(teamsGroup)
                  .sort()
                  .map((groupName: string) => (
                    <TreeNode
                      value={groupName}
                      key={groupName}
                      title={groupName}
                    >
                      {teamsGroup[groupName].length > 0 &&
                        teamsGroup[groupName].map((team: ITeam) => (
                          <TreeNode
                            value={team.id}
                            key={team.id}
                            title={team.name}
                          />
                        ))}
                    </TreeNode>
                  ))}
            </TreeSelect>
            <Checkbox
              className="checkbox"
              checked={appointmentStatus === 'Active'}
              onChange={(value) => onSetAppointmentStatus(value.target.value)}
              value={'Active'}
            >
              {translation('active')}
            </Checkbox>
            <Checkbox
              className="checkbox"
              checked={presentDay}
              onChange={onSetPresentDay}
              value={presentDay}
            >
              {translation('today')}
            </Checkbox>
          </Space>
        </div>
        <Content className={styles.wrapper}>
          <div className={styles.tableContent}>
            <div className={styles.filtersContainer}>
              <div style={{ width: '20vw' }}>
                <Search
                  onSearch={(val) => onSetSearch(val.trim())}
                  placeholder={intl.formatMessage({
                    id: 'customer_name_order_number',
                  })}
                  defaultValue={searchValue}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox.Group
                  className="checkbox"
                  options={checkboxValue}
                  onChange={onStatusChange}
                  defaultValue={status}
                />
                <Checkbox
                  className="checkbox"
                  checked={questions}
                  onChange={onSetQuestions}
                  value={questions}
                >
                  {translation('questions')}
                </Checkbox>
              </div>
            </div>
            <Table
              className={styles.table}
              columns={columns}
              dataSource={dataSourceTable}
              showHeader
              sticky
              loading={appointmentsTableState.loading}
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate(
                      {
                        pathname: routes.workorder.pathWithParams(
                          routes.ROOT_PAGES.workOrders,
                          record.workOrderId ?? '',
                          record?.appointmentType?.name ?? ''
                        ),
                      },
                      {
                        state: { url1: location.search },
                      }
                    );
                  },
                };
              }}
              pagination={{
                position: ['bottomCenter'],
                hideOnSinglePage: false,
                current: appointmentsTableState.pagination.currentPage,
                total: appointmentsTableState.pagination.totalCount,
                pageSize: pageSize,
                onChange: onChangePagination,
                showSizeChanger: true,
              }}
            />
          </div>
        </Content>
      </Layout>
    </PageLayout>
  );
};

export default Appointment;
