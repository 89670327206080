export const WorkOrderActionTypes = {
  SET_LOADING: 'SET_WORKORDER_LOADING',
  GET_WORKORDER_DATA: 'GET_WORKORDER_DATA',
  GET_WORKORDER_DATA_FAIL: 'GET_WORKORDER_DATA_FAIL',
  APPLY_FILTER: 'APPLY_FILTER',
};

export interface WorkOrderState {
  workOrder: any;
  loading: boolean;
  error: any;
}

export const ORDER_TYPES_CONSTANT = {
  chronological: 'Chronological',
  workItem: 'Work Item',
};

export const STATUS_FILTER_CONSTANT: any = {
  scheduled: 'Scheduled',
  inProgress: 'In Progress',
  completed: 'Completed',
  cannotComplete: 'Cannot Complete',
  canceled: 'Canceled',
};

export const SAT_FILTER_CONSTANT: any = {
  dc: 'DC',
  dc_backlog: 'DC Backlog',
  acm: 'ACM',
  acm_backlog: 'ACM Backlog',
  ac: 'AC',
  ac_backlog: 'AC Backlog',
  dc_acm_ac: 'DC/ACM/AC',
  dc_acm_ac_backlog: 'DC/ACM/AC Backlog',
  clarification: 'Klärfall',
};
