export enum ROOT_PAGES {
  workOrders = 'workorders',
  installtionProgress = 'installation-progress',
  appointments = 'appointments',
  approvalPackages = 'approval-packages',
  formBuilder = 'form-builder',
  appointmentTypes = 'appointment-types',
  noAccess = 'no-access',
}

const noAccess = {
  path: `/${ROOT_PAGES.noAccess}`,
};

const workorder = {
  path: `/:page/workorder/:externalCaseId/:appointmentTypeName`,
  pathWithParams: (
    page: string,
    externalCaseId: string,
    appointmentTypeName: string
  ) => `/${page}/workorder/${externalCaseId}/${appointmentTypeName}`,
};

const protocol = {
  path: `/:page/protocol/:externalCaseId/:appointmentTypeName`,
  pathWithParams: (
    page: string,
    externalCaseId: string,
    appointmentTypeName: string
  ) => `/${page}/protocol/${externalCaseId}/${appointmentTypeName}`,
};

const workOrders = {
  path: `/${ROOT_PAGES.workOrders}`,
};

const installtionProgressTable = {
  path: `/${ROOT_PAGES.installtionProgress}`,
};

const genericProgressTable = {
  path: `/${ROOT_PAGES.appointments}`,
};

const appointmentsDetails = {
  path: '/:page/appointment/:appointId',
  pathWithParams: (page: string, id: string) => `/${page}/appointment/${id}`,
};

const login = {
  signin: '/signin',
  signup: '/signup',
};

const workType = {
  path: '/:page/appointment/:appointId/field/:fieldId',
  pathWithParams: (page: string, appointId: string, fieldId: string) =>
    `/${page}/appointment/${appointId}/field/${fieldId}`,
};

const workTypeAssignment = {
  path: '/:page/appointment/:appointId/assignment/:assignmentId',
  pathField: `/:page/appointment/:appointId/assignment/:assignmentId/field/:fieldId`,
  pathWithParams: (appointId: string, assignmentId: string, fieldId?: string) =>
    `/${
      ROOT_PAGES.approvalPackages
    }/appointment/${appointId}/assignment/${assignmentId}${
      fieldId ? `/field/${fieldId}` : ''
    }`,
};

const approvalPackages = {
  path: `/${ROOT_PAGES.approvalPackages}`,
};

const appointmentTypesTable = {
  path: `/${ROOT_PAGES.appointmentTypes}`,
};

const appointmentTypeDetail = {
  path: '/appointment-types/:id',
  pathWithParams: (id: string) => `/appointment-types/${id}`,
};

const formBuilderTable = {
  path: `/${ROOT_PAGES.formBuilder}`,
};

const formBuilderDetail = {
  path: '/form-builder/:formId/:id/:fieldId',
  pathWithParams: (formId: string, id: string, fieldId: string = '0') =>
    `/form-builder/${formId}/${id}/${fieldId}`,
};

export {
  workOrders,
  workorder,
  installtionProgressTable,
  genericProgressTable,
  appointmentsDetails,
  login,
  workType,
  workTypeAssignment,
  approvalPackages,
  appointmentTypesTable,
  appointmentTypeDetail,
  formBuilderTable,
  formBuilderDetail,
  noAccess,
  protocol,
};
