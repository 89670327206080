import { FC, useEffect, useState } from 'react';
import { Modal, Form, FormInstance, Tabs, Space } from 'antd';
import FormGenerator from './FormGenerator';
import styles from '../formBuilderDetail.module.scss';
import { useIntl } from 'react-intl';
import {
  GLOBAL_DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from '../../../constants/types';
import { LanguageTabSvg } from '../../../components/icons/languageTabSvg';
import translation from '../../../i18n/translation';

interface Props {
  visible: boolean;
  saveLoading: boolean;
  hideModal: () => void;
  handleNewField: (form: FormInstance, onSuccess: () => void) => void;
  parentForm?: any;
}

const AddNewFieldModal: FC<Props> = ({
  visible,
  saveLoading,
  hideModal,
  handleNewField,
  parentForm,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [typeIsSelected, setTypeIsSelected] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<SUPPORTED_LANGUAGES>(
    parentForm?.languages?.length
      ? parentForm?.languages[0]
      : GLOBAL_DEFAULT_LANGUAGE
  );

  useEffect(() => {
    if (parentForm) {
      setSelectedLanguage(
        parentForm?.languages?.length
          ? parentForm?.languages[0]
          : GLOBAL_DEFAULT_LANGUAGE
      );
    }
  }, [parentForm]);

  const onCancel = () => {
    setTypeIsSelected(false);
    hideModal();
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      styles={{ body: { padding: '8px 16px 24px 16px' } }}
      maskClosable={false}
      title={intl.formatMessage({ id: 'new_field' })}
      open={visible}
      onCancel={onCancel}
      onOk={() => {
        handleNewField(form, () => {
          onCancel();
        });
      }}
      okButtonProps={{
        loading: saveLoading,
      }}
      cancelButtonProps={{
        disabled: saveLoading,
      }}
    >
      {typeIsSelected && (
        <Tabs
          className={styles.tabSetupModal}
          defaultActiveKey={selectedLanguage}
          items={parentForm?.languages.map((lang: SUPPORTED_LANGUAGES) => ({
            key: lang,
            label: (
              <Space>
                <div style={{ display: 'flex' }}>
                  <LanguageTabSvg />
                </div>
                <Space>{translation(lang)}</Space>
              </Space>
            ),
          }))}
          onChange={(lang: string) =>
            setSelectedLanguage(lang as SUPPORTED_LANGUAGES)
          }
          children={null}
        />
      )}
      <div className={styles.newFieldContainer}>
        <FormGenerator
          editMode={true}
          formInstance={form}
          isNewField
          selectedLanguage={selectedLanguage}
          setTypeIsSelected={setTypeIsSelected}
        />
      </div>
    </Modal>
  );
};

export default AddNewFieldModal;
