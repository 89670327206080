import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Switch,
  Space,
  Tabs,
  Button,
  Popover,
  Radio,
  Row,
  Col,
  RadioChangeEvent,
  Typography,
  Checkbox,
} from 'antd';
import { FormType, FlatList } from '../types';
import { useIntl } from 'react-intl';
import { IRoles } from '../../../store/rolesAndPrivileges';
import { LanguageTabSvg } from '../../../components/icons/languageTabSvg';
import { ToolOutlined } from '@ant-design/icons';
import {
  GLOBAL_DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from '../../../constants/types';
import translation from '../../../i18n/translation';
import {
  MULTI_LANGUAGE_SUPPORTED_FORM_FIELDS,
  updateFlatList,
  updateFormLanguageProps,
} from '../utils';
import styles from '../formBuilderDetail.module.scss';

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;
const APPROVAL_ROLES = [IRoles.QualityManager, IRoles.ProjectLead];
const DEFAULT_APPROVAL_ROLES = [IRoles.SuperAdmin, IRoles.FulfilmentAdmin];

interface Props {
  visible: boolean;
  saveLoading: boolean;
  hideModal: () => void;
  handleSave: (form: FormType, updatedFlatList: FlatList) => void;
  isNew?: boolean;
}

const EditFormModal: FC<Props> = ({
  visible,
  saveLoading,
  hideModal,
  handleSave,
  isNew = false,
}) => {
  const [formInstance] = Form.useForm();
  const intl = useIntl();
  const flatList = useSelector(
    (state: RootState) => state.formBuilderDetail.flatList
  );
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);

  const [allowedLanguages, setAllowedLanguages] = useState<
    SUPPORTED_LANGUAGES[]
  >(form?.languages ?? [GLOBAL_DEFAULT_LANGUAGE]);
  const [defaultLanguage, setDefaultLanguage] = useState<SUPPORTED_LANGUAGES>(
    form?.languages?.length ? form?.languages[0] : GLOBAL_DEFAULT_LANGUAGE
  );
  const [selectedLanguage, setSelectedLanguage] = useState<SUPPORTED_LANGUAGES>(
    form?.languages?.length ? form?.languages[0] : GLOBAL_DEFAULT_LANGUAGE
  );

  useEffect(() => {
    setAllowedLanguages(form?.languages ?? [GLOBAL_DEFAULT_LANGUAGE]);
    setDefaultLanguage(form?.defaultLanguage ?? GLOBAL_DEFAULT_LANGUAGE);
  }, [form]);

  // Remove the Default Roles from the allowedRolesForApproval
  if (form?.allowedRolesForApproval?.length) {
    form.allowedRolesForApproval =
      form.allowedRolesForApproval?.filter(
        (roles: IRoles) => !DEFAULT_APPROVAL_ROLES.includes(roles)
      ) ?? [];
  }

  const handleFormDetailSave = async () => {
    if (isNew || form) {
      try {
        let values = await formInstance.validateFields();
        const newForm: FormType = {
          ...form,
          ...values,
          // Include the Default Roles in the allowedRolesForApprovalzz§
          allowedRolesForApproval: values.allowedRolesForApproval?.length
            ? [...values.allowedRolesForApproval, ...DEFAULT_APPROVAL_ROLES]
            : [],
          ...updateFormLanguageProps(
            { ...form, ...values },
            defaultLanguage,
            allowedLanguages
          ),
          ...{ languages: allowedLanguages },
        };
        const newFlatList = updateFlatList(
          flatList!,
          defaultLanguage,
          allowedLanguages
        );
        handleSave(newForm, newFlatList);
        hideModal();
      } catch (error: any) {
        if (error?.errorFields) {
          for (let err of error.errorFields) {
            message.error(err.errors[0]);
          }
        }
      }
    }
  };

  const generateForm = () => (
    <Form
      form={formInstance}
      layout="vertical"
      initialValues={{
        name: form?.name ?? {},
        description: form?.description ?? {},
        priority: form?.priority ?? 'Default',
        doNotSendToQM: form?.doNotSendToQM ?? false,
        allowedRolesForApproval: form?.allowedRolesForApproval ?? [],
      }}
      onValuesChange={(changedValues: any) => {
        // Check if all the field languages are updated
        let fieldUpdated = Object.keys(changedValues)[0] as keyof FormType;
        if (MULTI_LANGUAGE_SUPPORTED_FORM_FIELDS.includes(fieldUpdated)) {
          let updatedLangField = Object.keys(changedValues[fieldUpdated])[0];
          let otherLangToValidate = allowedLanguages.filter(
            (l: SUPPORTED_LANGUAGES) => l !== updatedLangField
          );
          if (otherLangToValidate.length) {
            formInstance.validateFields([
              ...otherLangToValidate.map((l: SUPPORTED_LANGUAGES) => [
                fieldUpdated,
                l,
              ]),
            ]);
          }
        }
      }}
    >
      {allowedLanguages.map((lang: SUPPORTED_LANGUAGES) => {
        return [
          <Form.Item
            name={['name', lang]}
            key={`name-${lang}`}
            label="Name"
            hidden={lang !== selectedLanguage}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Insert a name',
              },
            ]}
          >
            <Input />
          </Form.Item>,
          <Form.Item
            name={['description', lang]}
            key={`description-${lang}`}
            label={intl.formatMessage({ id: 'description' })}
            hidden={lang !== selectedLanguage}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Insert a description',
              },
            ]}
          >
            <TextArea />
          </Form.Item>,
        ];
      })}
      <Form.Item name="priority" label="Priority" key="Priority">
        <Select disabled={selectedLanguage !== defaultLanguage}>
          <Option value="Default">
            {intl.formatMessage({ id: 'default' })}
          </Option>
          <Option value="Priority">Priority</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="doNotSendToQM"
        label="Skip Approval Packages"
        valuePropName="checked"
      >
        <Switch disabled={selectedLanguage !== defaultLanguage} />
      </Form.Item>
      <Form.Item
        name="allowedRolesForApproval"
        label="Approval By"
        key="allowedRolesForApproval"
      >
        <Select
          mode="multiple"
          disabled={selectedLanguage !== defaultLanguage}
          placeholder={`Default: ${IRoles.QualityManager}`}
        >
          {APPROVAL_ROLES.map((key: string) => (
            <Option value={key} key={key}>
              {key}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {allowedLanguages.map((lang: SUPPORTED_LANGUAGES) => {
        return (
          <Form.Item
            name="defaultLanguage"
            key={`defaultLanguage-${lang}`}
            hidden={lang !== selectedLanguage}
          >
            <Checkbox
              value={lang}
              title="Select default language"
              disabled={
                !isNew &&
                (!form?.hasValidChildren ||
                  !allowedLanguages.includes(lang) ||
                  form?.languages?.length < allowedLanguages?.length)
              }
              checked={lang === defaultLanguage}
              onChange={(e: any) => handleDefaultLanguageChange(e)}
            />{' '}
            Make this default language
          </Form.Item>
        );
      })}
    </Form>
  );

  const handleSelectedLanguageChange = (
    checked: boolean,
    lang: SUPPORTED_LANGUAGES
  ) => {
    setAllowedLanguages((prevState: SUPPORTED_LANGUAGES[]) => {
      if (checked) {
        // Add the language if it's not already in the array
        if (!prevState.includes(lang)) {
          return [...prevState, lang];
        }
      } else {
        // Remove the language if it exists in the array
        setSelectedLanguage(defaultLanguage);
        let newState = prevState.filter((item) => item !== lang);
        return newState.length ? newState : prevState;
      }
      return prevState; // No changes
    });
  };

  const handleDefaultLanguageChange = (e: RadioChangeEvent) => {
    let value = e.target.value;
    setDefaultLanguage(value);
    setSelectedLanguage(value);
    setAllowedLanguages((prevState: SUPPORTED_LANGUAGES[]) => {
      let newState = prevState.filter((item) => item !== value);
      newState.unshift(value);
      return newState;
    });
  };

  const allowedLanguagesRadioItem = Object.keys(SUPPORTED_LANGUAGES).map(
    (lang: any) => (
      <Row
        justify="space-between"
        style={{ width: '180px' }}
        key={`lang-list-${lang}`}
      >
        <Col>
          {' '}
          <Text>
            {' '}
            {translation(lang.toLowerCase())}{' '}
            {lang === defaultLanguage && '(default)'}
          </Text>
        </Col>
        <Col>
          <Switch
            disabled={lang === defaultLanguage}
            checked={allowedLanguages.includes(lang)}
            onChange={(checked: boolean) =>
              handleSelectedLanguageChange(checked, lang)
            }
          />
        </Col>
      </Row>
    )
  );

  return (
    <Modal
      destroyOnClose
      title={intl.formatMessage({ id: isNew ? 'new_form' : 'edit_form' })}
      open={visible}
      onOk={handleFormDetailSave}
      onCancel={hideModal}
      maskClosable={false}
      okText={isNew ? 'Create' : intl.formatMessage({ id: 'save' })}
      cancelText={intl.formatMessage({ id: 'cancel_editing' })}
      okButtonProps={{
        loading: saveLoading,
      }}
      cancelButtonProps={{
        disabled: saveLoading,
      }}
      width={600}
      className={styles.editFormModal}
    >
      <Tabs
        defaultActiveKey={selectedLanguage}
        tabBarExtraContent={
          <Popover
            placement="bottomRight"
            arrow={false}
            trigger="click"
            content={
              <div>
                <Radio.Group
                  value={defaultLanguage}
                  onChange={handleDefaultLanguageChange}
                >
                  <Space direction="vertical">
                    {allowedLanguagesRadioItem}
                  </Space>
                </Radio.Group>
              </div>
            }
          >
            <Button icon={<ToolOutlined />}> {translation('languages')}</Button>
          </Popover>
        }
        items={allowedLanguages.map((lang: SUPPORTED_LANGUAGES) => ({
          key: lang,
          label: (
            <Space key={`lang-tab-space-${lang}`}>
              <div style={{ display: 'flex' }}>
                <LanguageTabSvg />
              </div>
              <Space>{translation(lang)}</Space>
            </Space>
          ),
        }))}
        onChange={(lang: string) =>
          setSelectedLanguage(lang as SUPPORTED_LANGUAGES)
        }
        children={null}
      />
      {generateForm()}
    </Modal>
  );
};

export default EditFormModal;
