import { IAppointment } from '../../constants/types';

export const ProtocolActionTypes = {
  SET_LOADING: 'SET_PROTOCOL_LOADING',
  GET_PROTOCOL_DATA: 'GET_PROTOCOL_DATA',
  GET_PROTOCOL_DATA_FAIL: 'GET_PROTOCOL_DATA_FAIL',
};

export const IGNORED_FIELDS = ['VideoUpload'];

export const appointmentTypeNames: { [key: string]: string } = {
  Montage: 'Installation',
  MVT: 'InstallationPreparation',
  'Sep. Zählertausch': 'SeparateMeterExchange',
  ZT: 'MeterExchange',
  'Customer Service': 'CustomerService',
  'Home Improvement': 'HomeImprovement',
};

export const EMPTY_ANSWER = '--';
export interface ImageBuffer {
  url: string;
  width: number;
  height: number;
  buffer: Buffer;
}

export const WorktypesNames: any = {
  Montage: 'Installation',
  MVT: 'Installation Preparation',
};
export interface ProtocolState {
  questions: any;
  workorder: any;
  customer: any;
  metaData?: { [key: string]: string };
  latestAppointmentDetails?: IAppointment;
  workorderStartDate?: string;
  loading: boolean;
  error: any;
  appointmentTypeName: string;
}
