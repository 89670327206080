import { AnyAction } from 'redux';
import { generateFlatList, validateFlatList } from './utils';
import { FormBuilderDetailActions, FormType, FlatList } from './types';
import {
  GLOBAL_DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from '../../constants/types';

interface FormBuilderDetailState {
  loadingForm: boolean;
  loadingPublishedForm: boolean;
  form: FormType | null;
  publishedForm: FormType | null;
  flatList: FlatList | null;
  publishedFlatList: FlatList | null;
  metadata: string[];
  error: any;
}

const initialState: FormBuilderDetailState = {
  loadingForm: false,
  loadingPublishedForm: false,
  form: null,
  publishedForm: null,
  flatList: null,
  publishedFlatList: null,
  metadata: [],
  error: null,
};

const formatFormResponse = (form: FormType): FormType => ({
  ...form,
  defaultLanguage:
    typeof form.name === 'object'
      ? form.name.default!
      : GLOBAL_DEFAULT_LANGUAGE,
  languages: form.languages.length
    ? form.languages.map(
        (lang: SUPPORTED_LANGUAGES) =>
          lang.toLocaleLowerCase() as SUPPORTED_LANGUAGES
      )
    : [],
});

export const formBuilderDetailReducer = (
  state: FormBuilderDetailState = initialState,
  action: AnyAction
): FormBuilderDetailState => {
  switch (action.type) {
    case FormBuilderDetailActions.FETCH_FORM_START:
      return {
        ...state,
        loadingForm: true,
      };

    case FormBuilderDetailActions.FETCH_FORM_SUCCESS:
      const form = formatFormResponse(action.payload.response ?? {});
      const { flatList, hasValidChildren } = form.children
        ? validateFlatList(
            generateFlatList(form.children),
            form.languages[0],
            form.languages
          )
        : {
            flatList: null,
            hasValidChildren: true,
          };

      return {
        ...state,
        loadingForm: false,
        form: { ...form, hasValidChildren },
        flatList,
      };
    case FormBuilderDetailActions.FETCH_FORM_FAILED:
      return {
        ...state,
        loadingForm: false,
        error: action.payload,
      };
    case FormBuilderDetailActions.FETCH_PUBLISHED_FORM_START:
      return {
        ...state,
        loadingPublishedForm: true,
      };

    case FormBuilderDetailActions.FETCH_PUBLISHED_FORM_SUCCESS:
      const publishedForm = action.payload.response;
      return {
        ...state,
        loadingPublishedForm: false,
        publishedForm: publishedForm,
        publishedFlatList: publishedForm.children
          ? generateFlatList(publishedForm.children)
          : null,
      };
    case FormBuilderDetailActions.FETCH_PUBLISHED_FORM_FAILED:
      return {
        ...state,
        loadingPublishedForm: false,
        error: action.payload,
      };
    case FormBuilderDetailActions.FETCH_METADATA_SUCCESS:
      const metadata = action.payload.response;
      return {
        ...state,
        metadata: metadata,
      };
    case FormBuilderDetailActions.FETCH_METADATA_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case FormBuilderDetailActions.RESET_STATE:
      return {
        loadingForm: false,
        loadingPublishedForm: false,
        form: null,
        publishedForm: null,
        flatList: null,
        publishedFlatList: null,
        metadata: [],
        error: null,
      };
    default:
      return state;
  }
};
