import { AnyAction } from 'redux';
import { ProtocolState, ProtocolActionTypes } from './types';
import { getHashMap } from '../../utils/helpers';
import { filterIgnoredFields } from './helpers';

// Initial state of the protocol reducer
const initialState: ProtocolState = {
  loading: false, // Indicates if the data is currently loading
  error: null, // Error message if there was a problem fetching data
  appointmentTypeName: '', // Type of appointment
  questions: [], // Questions related to the appointment type
  workorder: {}, // Workorder details
  customer: null, // Customer details
};

/**
 * Parses the questions by removing the root Chapter name, as all appointment types are single chaptered
 * @param questions - Questions array from the payload
 * @param appointmentTypeName - Appointment type name from the payload
 * @returns Parsed questions array
 */
const parseQuestions = (questions: any, appointmentTypeName: string): any =>
  [
    'MVT',
    'Sep. Zählertausch',
    'Customer Service',
    'ZT',
    'Home Improvement',
  ].includes(appointmentTypeName)
    ? questions[0].questions
    : questions;

// Protocol reducer function
export const protocolReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case ProtocolActionTypes.GET_PROTOCOL_DATA:
      // Destructuring payload properties
      let {
        workorder,
        questions,
        appointmentTypeName,
        metaData,
        latestAppointmentDetails,
        workorderStartDate,
      } = payload;

      // Return a new state object with updated properties
      return {
        ...state,
        workorder, // Workorder details
        customer: workorder.customer, // Customer details
        appointmentTypeName, // Appointment type name
        metaData: getHashMap(metaData), // Meta data as a hash map
        latestAppointmentDetails, // Latest appointment details
        workorderStartDate, // Workorder start date
        questions: parseQuestions(
          filterIgnoredFields(questions),
          appointmentTypeName
        ), // Parsed questions
        error: null, // Reset error message
        loading: false, // Set loading to false
      };

    case ProtocolActionTypes.SET_LOADING:
      // Return a new state object with the loading property updated
      return {
        ...state,
        loading: payload,
      };

    case ProtocolActionTypes.GET_PROTOCOL_DATA_FAIL:
      // Return a new state object with the error property updated
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      // Return the current state if no action matches
      return state;
  }
};
