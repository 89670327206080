import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Button, Space, Spin, Form, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import {
  normalizeFormValues,
  updateFieldLanguageProps,
  validateFormInstance,
} from '../utils';
import { FormChild, EditMode, FormType, FlatList } from '../types';
import FormGenerator from './FormGenerator';
import styles from '../formBuilderDetail.module.scss';
import translation from '../../../i18n/translation';
import { TrashSvg } from '../../../components/icons/trashSvg';
import { SUPPORTED_LANGUAGES } from '../../../constants/types';

interface Props {
  selectedNode: FormChild | undefined;
  editMode: EditMode;
  saveLoading: boolean;
  selectedLanguage: SUPPORTED_LANGUAGES;
  openDeleteFieldModal: () => void;
  handleEditMode: (key: keyof EditMode, mode: boolean) => void;
  handleSave: (form: FormType, updatedFlatList: FlatList) => void;
}

const FormDetail: FC<Props> = ({
  selectedNode,
  editMode,
  saveLoading,
  openDeleteFieldModal,
  selectedLanguage,
  handleEditMode,
  handleSave,
}) => {
  const loading = useSelector(
    (state: RootState) =>
      state.formBuilderDetail.loadingForm ||
      state.formBuilderDetail.loadingPublishedForm
  );
  const flatList = useSelector(
    (state: RootState) => state.formBuilderDetail.flatList
  );
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);
  const [formInstance] = Form.useForm();

  const handleSaveField = async () => {
    if (selectedNode && flatList && form) {
      try {
        const values = await formInstance.validateFields();
        const normalizedValues = updateFieldLanguageProps(
          normalizeFormValues(values),
          form.defaultLanguage,
          form.languages
        );
        let currentNode = {
          ...selectedNode,
          ...normalizedValues,
        };
        if (!currentNode.approvalRequired && currentNode.validation) {
          currentNode.isValidate = false;
          delete currentNode.validation;
        }
        const newFlatList: FlatList = flatList.set(
          selectedNode.id,
          currentNode
        );
        handleSave(form, newFlatList);
      } catch (error: any) {
        if (!!error?.errorFields && error?.errorFields?.length > 0) {
          for (let err of error.errorFields) {
            message.error(err.errors[0]);
          }
        } else {
          message.error(error?.message);
        }
      }
    }
  };

  return (
    <div className={styles.contentWrapper}>
      {loading ? (
        <Spin
          size="large"
          className={styles.spin}
          style={{ marginTop: '300px' }}
        />
      ) : (
        selectedNode && (
          <div
            style={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <PageHeader
              title={translation('selected_field')}
              className={styles.contentHeader}
              extra={
                !editMode.formDetail ? (
                  [
                    <Button
                      key="2"
                      type="default"
                      disabled={editMode.treeMenu || saveLoading}
                      onClick={() => handleEditMode('formDetail', true)}
                      icon={<EditOutlined />}
                    >
                      {' '}
                      {translation('edit')}
                    </Button>,
                    <Button
                      key="1"
                      type="default"
                      disabled={editMode.formDetail || editMode.treeMenu}
                      onClick={openDeleteFieldModal}
                      icon={
                        <div className={styles.deleteButtonIcon}>
                          <TrashSvg />
                        </div>
                      }
                      style={{ marginRight: '16px' }}
                    ></Button>,
                  ]
                ) : (
                  <Space>
                    <Button
                      type="primary"
                      loading={saveLoading}
                      onClick={handleSaveField}
                      icon={<SaveOutlined />}
                    >
                      {' '}
                      {translation('save')}
                    </Button>
                    <Button
                      onClick={() => {
                        handleEditMode('formDetail', false);
                        validateFormInstance(formInstance);
                      }}
                      style={{ marginRight: '16px' }}
                    >
                      {' '}
                      {translation('cancel_editing')}
                    </Button>
                  </Space>
                )
              }
            />
            <div className={styles.contentContainer}>
              <FormGenerator
                selectedNode={selectedNode}
                editMode={editMode.formDetail}
                formInstance={formInstance}
                selectedLanguage={selectedLanguage}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FormDetail;
