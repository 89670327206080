import { IDocumentTitle } from '../constants/types';

export const generateDocumentFields = (
  appointmentType: string
): IDocumentTitle => {
  switch (appointmentType) {
    case 'Montage':
      return {
        documentTitle: 'Abnahme- und Inbetriebnahmeprotokoll',
        fileNameStart: '',
        fileNameEnd: '_AP_gesamt',
      };
    case 'MVT':
      return {
        documentTitle: 'MVT Protokoll',
        fileNameStart: 'MVT PRO_',
        fileNameEnd: '',
      };
    case 'Sep. Zählertausch':
    case 'Separater Zählertausch':
      return {
        documentTitle: 'Zählertausch Protokoll',
        fileNameStart: 'Separater_ZT_',
        fileNameEnd: '',
      };
    case 'Customer Service':
      return {
        documentTitle: 'Customer Service',
        fileNameStart: 'CustomerService_CS_',
        fileNameEnd: '',
      };
    default:
      return {
        documentTitle: 'Protokoll',
        fileNameStart: 'Protokoll_',
        fileNameEnd: '',
      };
  }
};
