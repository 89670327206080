export const LanguageTabSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M8.68164 10.5506C8.20996 10.9416 7.6125 11.1562 7 11.1562C6.3875 11.1562 5.79004 10.9402 5.31836 10.5506C4.94934 10.2447 4.67012 9.84449 4.51035 9.39258H3.17188V12.1406H10.8281V9.39258H9.48965C9.33105 9.84102 9.05215 10.2443 8.68164 10.5506Z"
      fill="#F5F5F5"
    />
    <path
      d="M4.375 6.84961H9.625C9.68516 6.84961 9.73438 6.80039 9.73438 6.74023V6.08398C9.73438 6.02383 9.68516 5.97461 9.625 5.97461H4.375C4.31484 5.97461 4.26563 6.02383 4.26563 6.08398V6.74023C4.26563 6.80039 4.31484 6.84961 4.375 6.84961Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M11.375 0.875H2.625C2.38301 0.875 2.1875 1.07051 2.1875 1.3125V12.6875C2.1875 12.9295 2.38301 13.125 2.625 13.125H11.375C11.617 13.125 11.8125 12.9295 11.8125 12.6875V1.3125C11.8125 1.07051 11.617 0.875 11.375 0.875ZM10.8281 12.1406H3.17188V9.39258H4.51035C4.66895 9.84102 4.94785 10.2443 5.31836 10.5506C5.79004 10.9402 6.3875 11.1563 7 11.1563C7.6125 11.1563 8.20996 10.9416 8.68164 10.5506C9.05215 10.2443 9.33105 9.84102 9.48965 9.39258H10.8281V12.1406ZM10.8281 8.53125H8.79922L8.72813 8.86895C8.56406 9.68652 7.83672 10.2813 7 10.2813C6.16328 10.2813 5.43594 9.68652 5.27051 8.86895L5.19941 8.53125H3.17188V1.85938H10.8281V8.53125Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M4.375 4.66211H9.625C9.68516 4.66211 9.73438 4.61289 9.73438 4.55273V3.89648C9.73438 3.83633 9.68516 3.78711 9.625 3.78711H4.375C4.31484 3.78711 4.26563 3.83633 4.26563 3.89648V4.55273C4.26563 4.61289 4.31484 4.66211 4.375 4.66211Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);
