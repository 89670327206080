import { FC } from 'react';
import { Button, Col, Popover, Row, Typography } from 'antd';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { showNotificationMessage } from '../../../utils/notification';
import { useIntl } from 'react-intl';
import { NotificationType } from '../../../constants/types';
import DOMPurify from 'dompurify';

const { Text } = Typography;

interface Props {
  defaultValue: string;
  asHTML?: boolean;
  onPasteContent?: any;
}

const DefaultTextView: FC<Props> = ({
  defaultValue,
  asHTML = false,
  onPasteContent,
}) => {
  const intl = useIntl();
  return defaultValue ? (
    <Popover
      content={
        <Row align="middle">
          <Col>
            <div
              style={{
                overflowY: 'auto',
                maxHeight: '300px',
                maxWidth: '500px',
              }}
            >
              {asHTML ? (
                <div
                  id="html-marked-text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(defaultValue),
                  }}
                />
              ) : (
                defaultValue
              )}
            </div>
          </Col>
          <Col>
            <Button
              icon={defaultValue ? <CopyOutlined /> : null}
              type="link"
              onClick={
                onPasteContent
                  ? onPasteContent
                  : async () => {
                      await window.navigator.clipboard.writeText(
                        defaultValue ?? ''
                      );
                      showNotificationMessage({
                        message: '',
                        description: intl.formatMessage({
                          id: 'escalate_notify',
                        }),
                        notificationType: NotificationType.info,
                        duration: 2,
                      });
                    }
              }
            />
          </Col>
        </Row>
      }
    >
      <Text>
        <EyeOutlined />
      </Text>
    </Popover>
  ) : null;
};

export default DefaultTextView;
