import { FC, useMemo } from 'react';
import { Form, Input, Space, Tooltip, Typography } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';
import DefaultTextView from './DefaultTextView';
import { languageFieldDefaultCheckRule } from '../utils';

const { Text } = Typography;

const FieldItemAdditionalData: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
  language,
  defaultLanguage,
  isFieldLocked,
  hidden,
}) => {
  const fieldDefaultValue = Form.useWatch([
    'additionalFieldData',
    defaultLanguage,
  ]);
  const publishedItemValues = useMemo(
    () => ({
      additionalFieldData: fieldChanges?.additionalFieldData.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      key={`additionalFieldData-${language}`}
      hidden={hidden}
      name={['additionalFieldData', language!]}
      label={
        <Space direction="horizontal">
          {fieldChanges?.additionalFieldData.hasChanges ? (
            <Text mark>
              <Tooltip title={translation('protocol_text_tooltip')}>
                <span>{translation('protocol_text')} </span>{' '}
              </Tooltip>
              {
                <PublishedItem initialValues={publishedItemValues}>
                  <FieldItemAdditionalData />
                </PublishedItem>
              }
            </Text>
          ) : (
            <Tooltip title={translation('protocol_text_tooltip')}>
              <span>{translation('protocol_text')} </span>{' '}
            </Tooltip>
          )}
          {language !== defaultLanguage && (
            <DefaultTextView defaultValue={fieldDefaultValue} />
          )}
        </Space>
      }
      rules={[
        isFieldLocked
          ? languageFieldDefaultCheckRule(
              'additionalFieldData',
              language!,
              defaultLanguage!
            )
          : undefined,
      ]}
    >
      <Input placeholder={fieldDefaultValue} disabled={!editMode} />
    </Form.Item>
  );
};

export default FieldItemAdditionalData;
