import { Button, Space, Typography } from 'antd';
import styles from './jobDetails.module.scss';
import {
  AcHealingMTWIcon,
  AccountManagerMTWIcon,
  InstallationPLCaseMTWIcon,
  WorkOrderMTWIcon,
} from '../../../../components/icons';
import { LinkMTWIcon } from '../../../../components/icons/linkMTW';
import { config } from '../../../../config';
import translation from '../../../../i18n/translation';
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { MTWorkOrderParams } from '../..';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import WorkOrderDescriptionModal from '../../../../components/workOrderDescriptionModal';
import {
  CS_appointmentType,
  HI_appointmentType,
} from '../../../../constants/types';
const { Text, Link } = Typography;
const REQUIRE_DESCRIPTION = [CS_appointmentType.name, HI_appointmentType.name];

interface CustomerInfoProps {
  workOrder?: any;
  fetchData: () => void;
}

const JobDetails: React.FC<CustomerInfoProps> = ({ workOrder, fetchData }) => {
  const params: MTWorkOrderParams = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Space direction="vertical" size={0} style={{ width: '100%', padding: 0 }}>
      <div className={styles.detailWrapper}>
        <Space>
          <div className={styles.flexCenter}>
            <WorkOrderMTWIcon />
          </div>
          <Text type="secondary">Work order</Text>
        </Space>
        <Space>
          <Text strong>{workOrder?.externalWorkOrderId}</Text>
          {workOrder?.externalWorkOrderId && (
            <Link
              id="ga4-detail-drawer-sf-link"
              href={`${config.sfIntegrationUrl}/r/WorkOrder/${workOrder?.externalWorkOrderId}/view`}
              target="_blank"
              className={styles.flexCenter}
            >
              <LinkMTWIcon />
            </Link>
          )}
        </Space>
      </div>
      <div className={styles.detailWrapper}>
        <Space>
          <div className={styles.flexCenter}>
            <InstallationPLCaseMTWIcon />
          </div>
          <Text type="secondary">
            {params?.appointmentTypeName === CS_appointmentType.name
              ? 'Customer Service Case'
              : 'Installation PL Case'}
          </Text>
        </Space>
        {params?.appointmentTypeName === CS_appointmentType.name
          ? workOrder?.externalFulfilmentCaseId && (
              <Space>
                <Text strong> {workOrder?.externalFulfilmentCaseId} </Text>

                <Link
                  href={`${config.sfIntegrationUrl}/r/Case/${workOrder?.externalFulfilmentCaseId}/view`}
                  target="_blank"
                  className={styles.flexCenter}
                >
                  <LinkMTWIcon />
                </Link>
              </Space>
            )
          : workOrder?.plCaseId && (
              <Space>
                <Text strong> {workOrder?.plCaseId} </Text>{' '}
                <Link
                  href={`${config.sfIntegrationUrl}/r/Case/${workOrder?.plCaseId}/view`}
                  target="_blank"
                  className={styles.flexCenter}
                >
                  <LinkMTWIcon />
                </Link>
              </Space>
            )}
      </div>
      <div className={styles.detailWrapper}>
        <Space>
          <div className={styles.flexCenter}>
            <AccountManagerMTWIcon />
          </div>
          <Text type="secondary">Account Manager</Text>
        </Space>
        <Text strong>{workOrder?.customer?.account?.ownerName}</Text>
      </div>
      {!['MVT', ...REQUIRE_DESCRIPTION].includes(
        params?.appointmentTypeName ?? ''
      ) && (
        <div className={styles.detailWrapper}>
          <Space>
            <div className={styles.flexCenter}>
              <AcHealingMTWIcon />
            </div>
            <Text type="secondary">{translation('ac_healing_necessary')}</Text>
          </Space>
          <Text style={{ paddingRight: '8px' }}>
            {workOrder?.isAcHealingRequired ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <CloseCircleOutlined style={{ color: '#A9A9A9' }} />
            )}
          </Text>
        </div>
      )}
      {REQUIRE_DESCRIPTION.includes(params?.appointmentTypeName ?? '') && (
        <div className={styles.detailWrapper}>
          <Space>
            <div className={styles.flexCenter}>
              <WorkOrderMTWIcon />
            </div>
            <Text type="secondary">{translation('description')}</Text>
          </Space>
          <Space>
            <Text ellipsis style={{ maxWidth: '280px' }}>
              {workOrder?.description}
            </Text>
            <Button
              type="link"
              onClick={() => setModalOpen(true)}
              style={{ marginLeft: '8px', padding: 0 }}
            >
              {workOrder?.description
                ? translation('more')
                : translation('add_issue_description')}
            </Button>
          </Space>
          <WorkOrderDescriptionModal
            visible={modalOpen}
            description={workOrder?.description}
            workOrderId={workOrder?.workOrderId}
            handleNewFormSuccess={() => fetchData()}
            hideModal={() => setModalOpen(false)}
            isEditable={true}
          />
        </div>
      )}
    </Space>
  );
};

export default JobDetails;
