import { ArgsProps } from 'antd/lib/notification/interface';
import { IUser } from 'craftos-ui';

export const CS_appointmentType = { name: 'Customer Service', acronym: 'CS' };
export const HI_appointmentType = { name: 'Home Improvement', acronym: 'HI' };
export const DMG_appointmentType = { name: 'Damage Case', acronym: 'DMG' };

export interface FetchOptions extends RequestInit {
  method?: string;
  headers?: {
    'Content-Type'?: string;
    Authorization?: string;
    Accept?: string;
    Language?: string;
  };
  body?: string;
}

export enum IStatus {
  open = 'Open',
  submitted = 'Submitted',
  reSubmitted = 'Resubmitted',
  inReview = 'InReview',
  approved = 'Approved',
  rejected = 'Rejected',
}

export enum IAppointmentStatusType {
  serviceAppointment = 'ServiceAppointment',
  workOrder = 'WorkOrder',
}

export enum SUPPORTED_LANGUAGES {
  de = 'de',
  en = 'en',
  pl = 'pl',
  ro = 'ro',
  sk = 'sk',
  uk = 'uk',
}

export const GLOBAL_DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.de;

export interface AppointmentType {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: AtedBy;
  updatedBy: AtedBy;
  externalId: string;
  externalCaseId: string;
  externalCustomerOrderNumber?: string;
  caseId: string;
  appointmentDate: string;
  appointmentScheduledDate: string;
  customer: Customer;
  customerAddress: CustomerAddress;
  files: File[];
  team: Team;
  appointment?: string; //workOrderTypeName
  appointmentType: Type;
  workOrderType: Type;
  appointmentNumber?: string;
  answerCounts: AnswerCounts;
  status: string;
  externalStatus?: IAppointmentStatus;
  source: string;
  questions: boolean;
  autoStopped: boolean;
  document: Document;
  groupStatus: Group[];
  followUp: boolean;
  activity: Activity;
  appointmentEndDate: string;
  appointmentScheduledEndDate: string;
  additionalInformation: IAdditionalInformation;
  numberOfModules: string;
  note: Note;
  atRisk: boolean;
  serviceAppointmentType: string;
  isDelayed: boolean;
  isLast?: boolean;
  isDelayedEnd?: boolean;
  isDelayedStart?: boolean;
  delayedEndReason?: string | null;
  [key: string]: any;
}

export interface Activity {
  startedAt: string;
  stoppedAt: string;
  completedAt: string;
}
export interface Group {
  groupId: string;
  name: string;
  status: string;
  updatedTime: string;
}

export interface Type {
  id: string;
  name: string;
  description: string;
  workTypes?: Type[];
}

export interface AtedBy {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}
export interface Customer {
  firstName: string;
  lastName: string;
  name: string;
}
export interface Note {
  text: string;
  updatedAt: string;
  updatedBy: AtedBy;
}
export interface Team {
  id: string;
  name: string;
  location: string;
  accountId: string;
  assignedPlId: string;
  assignedPlName: string;
}

export enum IAppointmentStatus {
  none = 'None',
  scheduled = 'Scheduled',
  dispatched = 'Dispatched',
  inProgress = 'In Progress',
  cannotComplete = 'Cannot Complete',
  completed = 'Completed',
  cancelled = 'Canceled',
  new = 'New',
  technicalCancellation = 'Technical Cancellation',
  customerCancellation = 'Customer Cancellation',
  backlog = 'Backlog',
  installationPreparation = 'Installation Preparation',
  onHold = 'On Hold',
  closed = 'Closed',
}

export enum IAppointmentTypeSalesForce {
  dc = 'DC',
  dc_backlog = 'DC Backlog',
  acm = 'ACM',
  acm_backlog = 'ACM Backlog',
  ac = 'AC',
  ac_backlog = 'AC Backlog',
  dc_acm_ac = 'DC/ACM/AC',
  dc_acm_ac_backlog = 'DC/ACM/AC Backlog',
  clarification = 'Klärfall',
  ach = 'ACH',
  dch = 'DCH',
  hak = 'HAK',
  ach_dch = 'ACH/DCH',
  ach_hak = 'ACH/HAK',
  dch_hak = 'DCH/HAK',
  ach_dch_hak = 'ACH/DCH/HAK',
}

export interface Form {
  id: string;
  formId: string;
  title: string;
  description: string;
  workType: IWorkType;
  status: string;
  workTypeId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: IUserInfo;
  updatedBy: IUserInfo;
}

export interface NewForm {
  id?: number;
  title: string;
  description?: string;
  workType?: IWorkType;
}

export interface Forms {
  [key: string]: Form;
}

export interface FormsState {
  allForms?: Form[];
  formResponse?: FormResponseType;
  workTypes?: IWorkType[];
  pagination: Pagination;
  formsLoading: boolean;
  emptyData?: boolean;
}

export interface FormDetailsState extends StandardState {
  createdAt?: string;
  createdBy?: IUserInfo;
  description: string;
  id?: string;
  name: string;
  status: string;
  updatedAt?: string;
  updatedBy?: IUserInfo;
}

export interface FormResponseType {
  description?: string;
  status?: string;
  workTypeId?: string;
  id?: string;
}

export interface AuthenticationState {
  token?: string;
  loginUser: IUser;
  privileges?: { [key: string]: boolean };
  rolesList?: any;
}

export interface AnswerCounts {
  total: number;
  open: number;
  submitted: number;
  resubmitted: number;
  inReview: number;
  approved: number;
  rejected: number;
  approvalPending: number;
  openApprovable: number;
  autoApproved: number;
  openRequired: number;
  questions: number;
}

export interface IWorkType {
  id: string;
  name: string;
  description?: string;
  status?: IStatus;
  key?: string;
  value?: string;
  label?: string;
  title?: string;
  answerCounts?: AnswerCounts;
}

export interface IWorkTypeGroup {
  id: string;
  name: string;
  description?: string;
  status?: IStatus;
  worktypes?: IWorkType[];
  answerCounts?: AnswerCounts;
}

export interface IGroupStatus {
  groupId: string;
  name: string;
  status: IStatus;
  updatedTime: string;
  statusColor?: string;
  plName?: string;
  plBranch?: string;
  completesAt?: string;
  updatedAt?: string;
}

export const formDetails = {
  GET_DATA: 'FORM_DETAILS_GET_DATA',
  GET_DATA_FAIL: 'FORM_DETAILS_GET_DATA_FAIL',
  SET_LOADING: 'FORM_DETAILS_SET_LOADING',
};

export interface Pagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
}

export interface IAppointmentType {
  id?: string;
  name?: string;
  description?: string;
  externalType?: string;
  workTypes: IWorkType[];
  group: IWorkTypeGroup[];
}

export interface IWorkOrderType {
  id?: string;
  name?: string;
}

export interface CustomerAddress {
  address?: string;
  address1?: string;
  address2?: string;
  zipCode?: string;
  city?: string;
  telephone?: string;
  latLong?: string;
}

export interface AppointmentFiles {
  fileName?: string;
  url?: string;
}

export interface AppointmentsTableRequestType {
  startDate?: string;
  endDate?: string;
  status?: string[];
  appointmentStatus?: string;
  pageNumber?: number;
  pageSize?: number;
  caseId?: string;
  externalCaseId?: string;
  customer?: string;
  team?: string[];
  questions?: boolean;
  note?: string;
  selectedExternalCaseId?: string;
  appointmentId?: string;
}

export interface WeeklyAppointmentsTableRequestType {
  week?: number;
  team?: string[];
  filters?: string[];
  appointmentTypes?: string;
  customerSearch?: string;
  dashboardType: DashboardType;
}

export interface ICustomer {
  firstName: string;
  lastName: string;
  name: string;
}

export interface ITeam {
  name: string;
  location: string;
  id: string;
  assignedPlId?: string;
  assignedPlName?: string;
}

export interface IAdditionalInformation {
  appointmentNumber: string;
  serviceAppointmentId: string;
  fullfillmentCaseId: string;
  workOrderId: string;
  plCaseId?: string;
  appointmentStatus: IAppointmentStatus;
  workOrderStatus: IAppointmentStatus;
}

export interface IAppointment {
  id: string;
  key?: string;
  previousAppointmentId?: string;
  activity?: {
    startedAt?: string;
    stoppedAt?: string;
  };
  appointmentDate?: string;
  appointmentEndDate?: string;
  appointmentScheduledDate?: string;
  appointmentScheduledEndDate?: string;
  customer?: string;
  customerAddress?: CustomerAddress;
  team?: ITeam;
  teamName?: string;
  files: AppointmentFiles[];
  assignedTo?: [string];
  workOrderType?: IWorkOrderType;
  answerCounts?: AnswerCounts;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: IUserInfo;
  updatedBy?: IUserInfo;
  status?: string;
  caseId?: string;
  externalCaseId?: string;
  externalId?: string;
  questions?: boolean;
  followUp?: true;
  numberOfModules?: string;
  note?: string;
  lastFieldUpdateTime?: string;
  AC?: IGroupStatus;
  UK?: IGroupStatus;
  DC?: IGroupStatus;
  ZT?: IGroupStatus;
  'AC-M'?: IGroupStatus;
  Wallbox?: IGroupStatus;
  isGroupHeader?: boolean;
  appointmentType?: string;
  additionalInformation?: IAdditionalInformation;
  isDelayedStart?: boolean;
  isDelayedEnd?: boolean;
  delayedEndReason?: string;
  calculatedApproved?: string;
  [key: string]: any;
}

export interface Appointment {
  id: string;
  key?: string;
  previousAppointmentId?: string;
  activity?: {
    startedAt?: string;
    stoppedAt?: string;
  };
  appointmentDate?: string;
  appointmentEndDate?: string;
  appointmentScheduledDate?: string;
  appointmentScheduledEndDate?: string;
  customer?: ICustomer;
  customerAddress?: CustomerAddress;
  team?: ITeam;
  teamName?: string;
  files: AppointmentFiles[];
  assignedTo?: [string];
  answerCounts?: AnswerCounts;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: IUserInfo;
  updatedBy?: IUserInfo;
  status?: string;
  caseId?: string;
  externalCaseId?: string;
  externalId?: string;
  questions?: boolean;
  followUp?: true;
  numberOfModules?: string;
  note?: string;
  lastFieldUpdateTime?: string;
  AC?: IGroupStatus;
  UK?: IGroupStatus;
  DC?: IGroupStatus;
  ZT?: IGroupStatus;
  'AC-M'?: IGroupStatus;
  Wallbox?: IGroupStatus;
  appointmentType?: IAppointmentType;
  additionalInformation?: IAdditionalInformation;
  latLong?: string;
  delayedEndReason?: string;
  calculatedApproved?: string;
  [key: string]: any;
}

export interface RequestAnswersData {
  appointmentId?: string;
  approvalRequired?: boolean;
  status?: string[];
  pageNumber?: number;
  pageSize?: number;
  questions?: boolean;
  worktypeFilter?: string[];
  formRevisionId?: string;
}

export interface StandardState {
  loading?: Boolean;
  error?: any;
}

export interface AppInfoBannerState extends StandardState {
  text?: string;
}

export interface AppointmentsTableState extends StandardState {
  startDate: string;
  endDate: string;
  pagination: Pagination;
  generatingReportId: string;
  appointmentsTableData: IAppointment[];
}

export interface AppointmentsTeamsState extends StandardState {
  appointmentsTeamsData: string[];
  teamGroups?: any;
  branchTLGroups?: any;
  teamLeads?: any;
}

export type DashboardType = 'installation' | 'appointment';

export interface ProgressTableState extends StandardState {
  appointmentsTableData: IAppointment[];
  appointmentTypes: IAppointmentType[];
  initialData: IAppointment[];
  dashboardType: DashboardType;
  filterTree: any;
  flatFilter: any;
  filters: string[];
  total: number;
  completed: number;
  filteredTotal: number;
  workOrderStatusList: string[];
  appointmentStatusList: string[];
}

export interface Answer {
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: IUserInfo;
  updatedBy: IUserInfo;
}

export interface Comment {
  text: string;
  timestamp: string;
  author: string;
  actor: string;
}

export interface AnswersDataState {
  id: string;
  revision: number;
  formInstanceId: string;
  formInstance: {
    id: string;
    formRevisionId: string;
    appointmentId: string;
    workTypeId: string;
  };
  formFieldId: string;
  status: string;
  answers: Answer[];
  comments: Comment[];
  createdAt: string;
  updatedAt: string;
  createdBy: IUserInfo;
  updatedBy: IUserInfo;
  questions: boolean;
}

export interface IFormRevisions {
  id: string;
  formId: string;
  status: string;
  revision: number;
  name: string;
  description: string;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  createdBy: IUserInfo;
  updatedBy: IUserInfo;
  formStatus: string;
  formHandle: string;
  workType: IWorkType;
  children: [
    {
      id: string;
      type: string;
      displayAs: string;
      label: string;
    }
  ];
}

export interface IAdditionalInfo {
  key: string;
  value: string;
  title?: string;
}

export interface AppointmentsDetailsState extends StandardState {
  userData?: IAppointment;
  answersData?: AnswersDataState[];
  relatedAppointments?: AppointmentType[];
  formRevisions?: object;
  pagination: Pagination;
  reportGenerating: boolean;
  worktypes: IWorkType[];
  worktypeGroups: IWorkTypeGroup[];
  additionalInfos: IAdditionalInfo[];
  description?: string;
}

export interface IComment {
  creationDate?: string;
  text?: string;
  author?: any;
  actor?: string;
  timestamp?: string;
  approval?: string;
  isReviewComment?: boolean;
  isHighlighted?: boolean;
  uploads?: string[];
  setSelectedImageUrl: (value: string) => void;
  isEditable: boolean;
}

//[String id, String code, String Description, String type <blocker | warning>]

export enum ReasonCodeType {
  blocker = 'Blocker',
  warning = 'NonBlocker',
}

export interface IReasonCode {
  id: string;
  code: string;
  description?: string;
  severity: ReasonCodeType;
}

export interface IReasonCodeOccurance {
  reasonCodeDefinitionIds: string[];
  comment: string;
  formId: string;
  formFieldId: string;
  appointment: {
    caseId: string;
    appointmentId: string;
    appoitmentType: string;
    customer: string;
    team: string;
    dateOfAppointment: string;
  };
  workTypeId: string;
  answerSnapshotId: string;
  answerId: string;
}

export interface IDocumentTitle {
  documentTitle: string;
  fileNameStart: string;
  fileNameEnd: string;
}

export const appInfoBannerTypes = {
  GET_APP_INFO_DATA: 'GET_APP_INFO_DATA',
  GET_APP_INFO_DATA_FAIL: 'GET_APP_INFO_DATA_FAIL',
};

export const appointmentsTableTypes = {
  SET_LOADING: 'SET_APPOINTMENTS_TABLE_LOADING',
  GET_APPOINTMENTS_DATA: 'GET_APPOINTMENTS_TABLE_DATA',
  GET_APPOINTMENTS_DATA_FAIL: 'GET_APPOINTMENTS_TABLE_DATA_FAIL',
  SET_DEFAULT_PAGINATION: 'SET_DEFAULT_PAGINATION',
  SET_GENERATE_REPORT_ID: 'SET_GENERATE_REPORT_ID',
};

export const appointmentsTeamsTypes = {
  SET_LOADING: 'SET_APPOINTMENTS_TEAMS_LOADING',
  GET_APPOINTMENTS_TEAMS_DATA: 'GET_APPOINTMENTS_TEAMS_DATA',
  GET_APPOINTMENTS_TEAMS_DATA_FAIL: 'GET_APPOINTMENTS_TEAMS_DATA_FAIL',
};

export const progressTableTypes = {
  SET_PROGRESS_DATA_LOADING: 'SET_PROGRESS_DATA_LOADING',
  GET_PROGRESS_DATA: 'GET_PROGRESS_TABLE_DATA',
  SET_PROGRESS_DASHBOARD_TYPE: 'SET_PROGRESS_DASHBOARD_TYPE',
  GET_ALL_APPOINTMENT_TYPES_DATA: 'GET_ALL_APPOINTMENT_TYPES_DATA',
  GET_APPOINTMENT_WORKORDER_STATUS_LIST:
    'GET_APPOINTMENT_WORKORDER_STATUS_LIST',
  GET_PROGRESS_DATA_FAIL: 'GET_PROGRESS_TABLE_DATA_FAIL',
  APPLY_PROGRESS_FILTER: 'APPLY_PROGRESS_FILTER',
};

export const progressTeamsTypes = {
  SET_PROGRESS_TEAMS_LOADING: 'SET_PROGRESS_TEAMS_LOADING',
  GET_PROGRESS_TEAMS_DATA: 'GET_PROGRESS_TEAMS_DATA',
  GET_PROGRESS_TEAMS_DATA_FAIL: 'GET_PROGRESS_TEAMS_DATA_FAIL',
};

export const appointmentsDetailsTypes = {
  SET_LOADING_DETAILS: 'SET_APPOINTMENTS_DETAILS_LOADING',
  GET_APPOINTMENTS_DETAILS_DATA: 'GET_APPOINTMENTS_DETAILS_DATA',
  GET_APPOINTMENTS_DETAILS_DATA_FAIL: 'GET_APPOINTMENTS_DETAILS_DATA_FAIL',
  GET_RELATED_APPOINTMENTS_DATA: 'GET_RELATED_APPOINTMENTS_DATA',
  GET_RELATED_APPOINTMENTS_DATA_FAIL: 'GET_RELATED_APPOINTMENTS_DATA_FAIL',
  GET_APPOINTMENT_TYPES_DATA: 'GET_APPOINTMENT_TYPES_DATA',
  GET_APPOINTMENTS_DETAILS_ANSWERS_DATA:
    'GET_APPOINTMENTS_DETAILS_ANSWERS_DATA',
  GET_FORM_REVISIONS_DATA: 'GET_FORM_REVISIONS_DATA',
  SET_DEFAULT_PAGINATION: 'SET_DEFAULT_PAGINATION',
  SET_DEFAULT_APPOINTMENTS_DETAILS_DATA:
    'SET_DEFAULT_APPOINTMENTS_DETAILS_DATA',
  SET_GENERATE_REPORT: 'SET_GENERATE_REPORT_LOADING',
  GET_WORKTYPE_GROUPS_DATA: 'GET_WORKTYPE_GROUPS_DATA',
  GET_WORKTYPE_GROUPS_DATA_FAIL: 'GET_WORKTYPE_GROUPS_DATA_FAIL',
  RESET_APPOINTMENTS_DETAILS_ERRORS: 'RESET_APPOINTMENTS_DETAILS_ERRORS',
  RESET_APPOINTMENTS_DETAILS_STATE: 'RESET_APPOINTMENTS_DETAILS_STATE',
  GET_ADDITIONAL_INFOS: 'GET_ADDITIONAL_INFOS',
  GET_DESCRIPTION_INFOS: 'GET_DESCRIPTION_INFOS',
};

export const workType = {
  SET_LOADING: 'SET_WORK_TYPE_LOADING',
  SET_SUBMIT_LOADING: 'SET_WORK_TYPE_SUBMIT_LOADING',
  GET_WORK_TYPE_DATA: 'SET_WORK_TYPE_DATA',
  REFRESH_ANSWER_DATA: 'REFRESH_ANSWER_DATA',
  GET_WORK_TYPE_DATA_FAIL: 'GET_WORK_TYPE_DATA_FAIL',
  POST_WORK_TYPE_DATA: 'POST_WORK_TYPE_DATA',
  POST_WORK_TYPE_DATA_FAIL: 'POST_WORK_TYPE_DATA_FAIL',
  RESET_WORK_TYPE_DATA_FAIL: 'RESET_WORK_TYPE_DATA_FAIL',
  SET_FORM_FIELD_LABEL: 'FORM_FIELD_LABEL',
  SET_REASON_CODES: 'SET_REASON_CODES',
  GET_ASSIGNMENT_DATA: 'GET_ASSIGNMENT_DATA',
  SET_DEFAULT_VALUES: 'SET_DEFAULT_VALUES',
};

export const approvalPackageTypes = {
  SET_APPROVAL_PACKAGE_LOADING: 'SET_APPROVAL_PACKAGE_LOADING',
  GET_APPROVAL_PACKAGE_DATA: 'GET_APPROVAL_PACKAGE_DATA',
  SET_APPROVAL_PACKAGE_DATA_FAIL: 'SET_APPROVAL_PACKAGE_DATA_FAIL',
  GET_APPROVAL_PACKAGE_QUEUE: 'GET_APPROVAL_PACKAGE_QUEUE',
  GET_APPROVAL_PACKAGE_COUNT: 'GET_APPROVAL_PACKAGE_COUNT',
  GET_APPROVAL_PACKAGE_INFO: 'GET_APPROVAL_PACKAGE_INFO',
  GET_USER_APPROVAL_PACKAGE_HISTORY: 'GET_USER_APPROVAL_PACKAGE_HISTORY',
  SET_USER_APPROVAL_PACKAGE_HISTORY_ERROR:
    'SET_USER_APPROVAL_PACKAGE_HISTORY_ERROR',
  SET_USER_APPROVAL_PACKAGE_HISTORY_LOADING:
    'SET_USER_APPROVAL_PACKAGE_HISTORY_LOADING',
  RESET_USER_APPROVAL_PACKAGE_HISTORY: 'RESET_USER_APPROVAL_PACKAGE_HISTORY',
  UNASSIGN_APPROVAL_PACKAGE: 'UNASSIGN_APPROVAL_PACKAGE',
  SET_DEFAULT_VALUES: 'SET_DEFAULT_VALUES',
};

export const metaDataTypes = {
  POST_META_DATA: 'POST_META_DATA',
  GET_META_DATA: 'GET_META_DATA',
  SET_META_DATA_LOADING: 'SET_META_DATA_LOADING',
  GET_META_DATA_FAIL: 'GET_META_DATA_FAIL',
};

export interface IMetaData {
  id: string;
  key: string;
  description: string;
}

export interface IUserInfo {
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  name?: string;
  username?: string;
  idTokenClaims?: any;
}

export interface IWorkTypeAnswers {
  id?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: IUserInfo;
  updatedBy?: IUserInfo;
  actor?: string;
  selections?: Array<string>;
  content?: string;
  uploads?: Array<string>;
  dateContent?: string;
  reopened?: boolean;
  reopenedBy?: IUserInfo;
  comments?: IComment[];
  videoUploads?: string[];
  preferredLanguage?: string;
  imageUploadFlag?: string;
}

export interface ICurrentFormField {
  workType?: string;
  subsection?: string;
  formField?: any;
}

export interface INote {
  text?: string;
  updatedTime?: string;
}

export interface IFormInstance {
  id?: string;
  formRevisionId?: string;
  appointmentId?: string;
  workTypeId?: string;
}

export interface IWorkTypeData {
  id?: string;
  revision?: number;
  formInstanceId?: string;
  note?: INote;
  formInstance?: IFormInstance;
  formFieldId?: string;
  status?: string;
  answers?: IWorkTypeAnswers[];
  comments?: IComment[];
  createdAt?: string;
  updatedAt?: string;
  createdBy?: IUserInfo;
  updatedBy?: IUserInfo;
  questions?: any;
}

export enum IQueue {
  First = 'First',
  FirstPriority = 'FirstPriority',
  Second = 'Second',
  SecondPriority = 'SecondPriority',
}

export interface IAssignmentDetails {
  id?: string;
  formInstance?: IFormInstance;
  queue?: IQueue;
  assignedUser?: string;
  assignedTime?: string;
  completed?: boolean;
  unassignedTime?: string;
  recordStatus?: string;
}

export interface IAssignment {
  assignmentId?: string;
  caseId?: string;
  appointmentId?: string;
  formRevisionId?: string;
  formInstanceId?: string;
}

export interface workTypeState extends StandardState {
  workTypeData?: IWorkTypeData;
  currentFormField?: ICurrentFormField;
  reasonCodes?: IReasonCode[];
  errorSubmit?: boolean;
  assignment?: IAssignmentDetails;
}

export interface IPackageInfo {
  firstCount?: number;
  firstPriorityCount?: number;
  secondCount?: number;
  secondPriorityCount?: number;
  assignment?: IAssignmentDetails | null;
}

export interface approvalPackageState extends StandardState {
  assignment?: IAssignment | null;
  isUnassigned?: boolean;
  packageInfo?: IPackageInfo;
  userHistory?: any;
  userHistoryLoading?: boolean;
}

export interface EditedImage {
  position: number;
  newUrl: string;
}

export enum IAppointmentStatusActionType {
  close,
  open,
}

export enum NotificationType {
  error,
  info,
  warn,
  success,
  default,
}

export interface INotificationProps extends ArgsProps {
  title?: string;
  error?: any;
  notificationType?: NotificationType;
  onClose?: () => void;
}
