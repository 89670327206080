import {
  Badge,
  Col,
  Drawer,
  Row,
  Space,
  Spin,
  Switch,
  Tabs,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './approvalPackages.module.scss';
import ApprovalPackageActions from './actions';
import * as routes from '../../router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from '../../store';
import UserApprovalPackageHistoryIcon from '../../components/icons/userApprovalPackageHistoryIcon';
import UserApprovalPackageHistoryEmptyIcon from '../../components/icons/userApprovalPackageHistoryEmptyIcon';
import translation from '../../i18n/translation';

interface Props {
  open: boolean;
  onClose: () => void;
}
enum UserHistoryStatusType {
  completed = 'Completed',
  skipped = 'Skipped',
  inProgress = 'InProgress',
}
enum PackageStatusType {
  open = 'Open',
  submitted = 'Submitted',
  reSubmitted = 'Resubmitted',
  inReview = 'InReview',
  approved = 'Approved',
  rejected = 'Rejected',
}
type UserHistoryPackageType = {
  appointmentId: string;
  workTypeId: string;
  externalCaseId: string;
  assignedTime: string;
  autoUnassigned: boolean;
  customer: {
    firstName: string;
    lastName: string;
    name: string;
  };
  packageName: string;
  packageStatus: PackageStatusType;
  status: UserHistoryStatusType;
  unassignedTime: string;
};

const { Text } = Typography;

const UserApprovalPackagesDrawer: React.FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const approvalPackageState = useSelector(
    (state: RootState) => state.approvalPackage
  );
  const [includeSkippedPackages, setIncludeSkippedPackages] = useState(false);
  const [filteredApprovalPackages, setFilteredApprovalPackages] = useState<
    UserHistoryPackageType[]
  >([]);

  useEffect(() => {
    if (open) {
      dispatch(ApprovalPackageActions.setUserAppprovalPackageHistoryLoading());
      dispatch(ApprovalPackageActions.getUserAppprovalPackageHistory());
    } else {
      dispatch(ApprovalPackageActions.resetUserAppprovalPackageHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open && approvalPackageState?.userHistory?.length) {
      includeSkippedPackages
        ? setFilteredApprovalPackages(approvalPackageState.userHistory)
        : setFilteredApprovalPackages(
            approvalPackageState.userHistory.filter(
              (item: UserHistoryPackageType) =>
                item.status === UserHistoryStatusType.completed
            )
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalPackageState.userHistory, includeSkippedPackages]);

  const handleSwitch = () => {
    setIncludeSkippedPackages(!includeSkippedPackages);
  };

  const approvalPackage = (
    reviewedPackage: UserHistoryPackageType,
    index: number
  ) => {
    return (
      <div
        className={styles.reviewedPackagesDrawerItem}
        key={`userApprovalPackage-${index}`}
        onClick={() =>
          window.open(
            `${routes.appointmentsDetails.pathWithParams(
              'worktype',
              reviewedPackage?.appointmentId
            )}?group=all&worktype=${reviewedPackage?.workTypeId}`,
            '_blank'
          )
        }
      >
        <Row>
          {reviewedPackage?.packageStatus && (
            <Badge
              className={styles.packageStatusBadge}
              offset={[4, 0]}
              color={
                reviewedPackage.packageStatus === PackageStatusType.approved
                  ? 'var(--color-green-0)'
                  : reviewedPackage.packageStatus === PackageStatusType.rejected
                  ? 'var(--color-red-0)'
                  : 'var(--color-brand)'
              }
            />
          )}
          <Text className={styles.reviewedPackageName}>
            {reviewedPackage?.packageName}
          </Text>
          {reviewedPackage?.status !== UserHistoryStatusType.completed && (
            <Text style={{ paddingLeft: '8px' }} type="secondary">
              (
              {reviewedPackage?.status !== UserHistoryStatusType.skipped
                ? translation('inReview')
                : translation('skipped')}
              )
            </Text>
          )}
          {reviewedPackage?.assignedTime && (
            <Text type="secondary" style={{ paddingLeft: '8px' }}>
              {intl.formatTime(reviewedPackage?.assignedTime, {
                timeStyle: 'short',
                hour12: false,
              })}
            </Text>
          )}
        </Row>
        <Row>
          <Text>{reviewedPackage?.customer?.name ?? ''}</Text>
        </Row>
        <Row>
          <Text type="secondary">{reviewedPackage?.externalCaseId ?? ''} </Text>
        </Row>
      </div>
    );
  };

  return (
    <Drawer
      title={translation('history')}
      placement="right"
      width="600px"
      onClose={onClose}
      open={open}
      closable={false}
      extra={
        <Space
          style={{
            width: '260px',
            justifyContent: 'flex-end',
          }}
        >
          <Text style={{ fontSize: '15px' }}>
            {translation('show-skipped-packages')}
          </Text>
          <Switch
            size="small"
            checked={includeSkippedPackages}
            onChange={handleSwitch}
          />
        </Space>
      }
      styles={{ body: { padding: '0 0 16px 24px' } }}
    >
      {approvalPackageState.userHistoryLoading ? (
        <Row
          style={{
            padding: '54px 24px 0 0',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" />
        </Row>
      ) : filteredApprovalPackages?.length > 0 ? (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: translation('packages_assigned_to_me'),
                children: (
                  <>
                    <Text type="secondary">
                      {translation('latest')} (
                      {!includeSkippedPackages
                        ? filteredApprovalPackages?.length
                        : approvalPackageState.userHistory?.length}
                      )
                    </Text>
                    {filteredApprovalPackages.map(
                      (reviewedPackage: UserHistoryPackageType, i: number) =>
                        approvalPackage(reviewedPackage, i)
                    )}
                  </>
                ),
              },
            ]}
          />
          <Col
            style={{
              padding: '48px 0',
            }}
          >
            <Row
              style={{
                justifyContent: 'center',
              }}
            >
              <UserApprovalPackageHistoryIcon />
            </Row>
            <Row
              style={{
                justifyContent: 'center',
                paddingTop: '4px',
              }}
            >
              <Text>{translation('all_todays_packages')}</Text>
            </Row>
          </Col>
        </>
      ) : (
        <Col
          style={{
            paddingTop: '48px',
          }}
        >
          <Row
            style={{
              justifyContent: 'center',
            }}
          >
            <UserApprovalPackageHistoryEmptyIcon />
          </Row>
          <Row
            style={{
              justifyContent: 'center',
              paddingTop: '4px',
            }}
          >
            <Text>{translation('no_reviewed_packages_in_history')}</Text>
          </Row>
        </Col>
      )}
    </Drawer>
  );
};

export default UserApprovalPackagesDrawer;
