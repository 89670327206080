import { AnyAction } from 'redux';
import { AppointmentType, IAppointmentStatus } from '../../../constants/types';
import { DetailDrawerActions } from './types';
import moment from 'moment';
const IGNORED_LAST_APPOINTMENT_STATUSES = [
  IAppointmentStatus.cancelled,
  IAppointmentStatus.cannotComplete,
];

interface DetailDrawerState {
  loadingAppointmentDetail: boolean;
  appointmentDetail: AppointmentType | null;
  appointments: AppointmentType[];
  workOrderDetail: any;
  isLast: boolean;
  error: any;
}

const initialState: DetailDrawerState = {
  loadingAppointmentDetail: false,
  appointmentDetail: null,
  appointments: [],
  workOrderDetail: null,
  isLast: false,
  error: null,
};

const handleSortAppointments = (
  appointmentsRes: AppointmentType[],
  externalId = '',
  montageWorkOrderId = ''
) => {
  let currentAppointmentDetail: any = {};
  let appointments: any[] = [];
  let moments: moment.Moment[] = [];
  if (appointmentsRes) {
    appointmentsRes.forEach((appointment: AppointmentType | null) => {
      if (
        !IGNORED_LAST_APPOINTMENT_STATUSES.includes(
          appointment?.externalStatus ?? IAppointmentStatus.none
        ) &&
        montageWorkOrderId &&
        appointment?.appointmentDate
      ) {
        moments.push(moment(appointment?.appointmentDate));
      }
      if (appointment?.externalId !== externalId) {
        appointments.push(appointment);
      } else {
        currentAppointmentDetail = appointment;
      }
    });
  }

  let lastAppointmentMoment = moments?.length ? moment.max(moments) : null;

  const handleCheckAppointmentIsLast = (appointment: any) => {
    return (
      (moment(appointment?.appointmentDate).isSame(lastAppointmentMoment) ||
        moment(appointment?.appointmentDate).isSame(
          lastAppointmentMoment,
          'week'
        )) &&
      !IGNORED_LAST_APPOINTMENT_STATUSES.includes(
        appointment?.externalStatus ?? IAppointmentStatus.none
      )
    );
  };

  return {
    currentAppointmentDetail: currentAppointmentDetail,
    appointments: lastAppointmentMoment
      ? [
          ...appointments.map((appointment) =>
            handleCheckAppointmentIsLast(appointment)
              ? { ...appointment, isLast: true }
              : appointment
          ),
        ]
      : appointments,
    currentAppointmentIsLast: lastAppointmentMoment
      ? handleCheckAppointmentIsLast(currentAppointmentDetail)
      : false,
  };
};

export const detailDrawerReducer = (
  state: DetailDrawerState = initialState,
  action: AnyAction
): DetailDrawerState => {
  switch (action.type) {
    case DetailDrawerActions.FETCH_APPOINTMENT_DETAIL_START:
      return {
        ...state,
        loadingAppointmentDetail: true,
      };
    case DetailDrawerActions.FETCH_WORK_ORDER_DETAIL_SUCCESS:
      let {
        currentAppointmentDetail,
        currentAppointmentIsLast,
        appointments,
      } = handleSortAppointments(
        action.payload?.workOrderDetail?.appointments,
        action.payload?.externalId,
        action.payload?.appointmentTypeName === 'Montage'
          ? action.payload?.workOrderDetail?.externalWorkOrderId
          : ''
      );
      return {
        ...state,
        loadingAppointmentDetail: false,
        workOrderDetail: action.payload?.workOrderDetail,
        appointmentDetail: currentAppointmentDetail,
        appointments: appointments,
        isLast: currentAppointmentIsLast,
      };
    case DetailDrawerActions.FETCH_WORK_ORDER_DETAIL_FAILED:
      return {
        ...state,
        loadingAppointmentDetail: false,
        error: action.payload,
      };
    case DetailDrawerActions.RESET_STATE:
      return {
        loadingAppointmentDetail: false,
        appointmentDetail: null,
        appointments: [],
        workOrderDetail: null,
        isLast: false,
        error: null,
      };
    default:
      return state;
  }
};
