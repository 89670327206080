import { FC, useState } from 'react';
import { Modal, Form, Input, Typography, message } from 'antd';
import ApiRepository from '../../services/api/apiRepository';
import { useIntl } from 'react-intl';
import translation from '../../i18n/translation';
const { Paragraph } = Typography;

interface Props {
  visible: boolean;
  isEditable: boolean;
  description?: string;
  workOrderId?: string;
  handleNewFormSuccess?: () => void;
  hideModal: () => void;
}

const DescriptionModal: FC<Props> = ({
  visible,
  description,
  isEditable,
  workOrderId,
  handleNewFormSuccess,
  hideModal,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(!description);

  const handleOk = async () => {
    setLoading(true);
    try {
      const value = await form.validateFields();
      const updateRes = await ApiRepository.updateAppointmentDescription(
        workOrderId ?? '',
        value?.description
      );
      if (updateRes?.response?.workOrderId) {
        if (handleNewFormSuccess) handleNewFormSuccess();
        setLoading(false);
        hideModal();
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.message) {
        message.error(error?.message);
      }
      if (error?.errorFields) {
        for (let err of error.errorFields) {
          message.error(err.errors[0]);
        }
      }
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      title={translation('issue_description')}
      okText={
        isEditable
          ? editMode
            ? !description
              ? translation('save')
              : translation('update')
            : translation('edit')
          : ''
      }
      cancelText={
        isEditable
          ? editMode
            ? translation('cancel_edit_mode')
            : translation('close')
          : translation('close')
      }
      open={visible}
      onOk={editMode ? handleOk : () => setEditMode(true)}
      onCancel={
        !!description && isEditable && editMode
          ? () => setEditMode(false)
          : hideModal
      }
      okButtonProps={{ loading: loading, hidden: !isEditable }}
      cancelButtonProps={{ disabled: loading }}
      width={800}
      styles={{ body: { padding: '32px 16px' } }}
    >
      {editMode ? (
        <Form form={form} layout="vertical">
          <Form.Item
            name="description"
            label={intl.formatMessage({ id: 'description' })}
            initialValue={description}
            rules={[
              {
                required: true,
                message: 'Insert a description',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Insert a description"
              maxLength={32000}
              showCount
              autoSize={{ minRows: 3, maxRows: 10 }}
            />
          </Form.Item>
        </Form>
      ) : (
        <Paragraph>{description}</Paragraph>
      )}
    </Modal>
  );
};

export default DescriptionModal;
