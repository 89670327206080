import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '../../components/appLayout';
import { PageHeader } from '@ant-design/pro-layout';
import ReactToPrint from 'react-to-print';

import styles from './protocol.module.scss';
import ProtocolActions from './actions';

import {
  Anchor,
  Button,
  Descriptions,
  Divider,
  Image,
  Layout,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import BreadcrumNavigation from '../../components/breadcrumNavigation';
import Sider from 'antd/es/layout/Sider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import ProtocolDocument from './components/ProtocolDocument';
import moment from 'moment';
import { generateDocumentFields } from '../../utils/generateDocumentFields';
import { IDocumentTitle } from '../../constants/types';
import ProtocolDocxGenerator from '../../services/protocolDocxGenerator';
import { showNotificationMessage } from '../../utils/notification';
import { useIntl } from 'react-intl';
import translation from '../../i18n/translation';

const { Title, Text } = Typography;
export interface ProtocolParams {
  page?: string;
  externalCaseId?: string;
  appointmentTypeName?: string;
}

export const ProtocolPage: React.FC = () => {
  const params: ProtocolParams = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const printRef = useRef<HTMLDivElement>(null);
  const scrollProtocolContainerRef = useRef<HTMLElement>(null);

  const protocolState = useSelector((state: RootState) => state.protocol);

  const [chaptersList, setChaptersList] = useState<any>([]);
  const [anchorsList, setAnchorsList] = useState<any>([]);
  const [documentTitle, setDocumentTitle] = useState<IDocumentTitle | null>(
    null
  );
  const [showAllSubmissions, setShowAllSubmissions] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const fetchProtocolData = () => {
    dispatch(ProtocolActions.setLoading(true));
    dispatch(
      ProtocolActions.getProtocolData(
        params?.externalCaseId ?? '',
        params?.appointmentTypeName ?? ''
      )
    );
  };

  useEffect(() => {
    if (protocolState.questions) {
      setChaptersList(protocolState.questions);
      setAnchorsList(getAnchorItems(protocolState.questions));
    }

    if (protocolState.latestAppointmentDetails) {
      setDocumentTitle(
        generateDocumentFields(
          protocolState?.latestAppointmentDetails?.appointmentType?.name ??
            'Montage'
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocolState.questions, protocolState.latestAppointmentDetails]);

  useEffect(() => {
    if (
      !protocolState?.loading &&
      params?.externalCaseId &&
      params?.appointmentTypeName
    ) {
      fetchProtocolData();
    }
    return () => {
      dispatch(ProtocolActions.resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getAnchorItems = (items: any, parent: any = null) =>
    items.map((item: any, index: number) => ({
      key: index,
      href: `#${
        !parent ? item?.formInstanceId ?? item.formFieldId : item.formFieldId
      }`,
      title: (
        <Text strong={!!item?.questions?.length}>
          {item?.protocolText || item?.label}
        </Text>
      ),
      children: item?.questions?.length
        ? getAnchorItems(item?.questions, item)
        : [],
    }));

  const generateDocx = async () => {
    try {
      setGeneratingReport(true);
      await ProtocolDocxGenerator.generateReport(
        params?.externalCaseId!,
        params?.appointmentTypeName!
      );
    } catch (error: any) {
      showNotificationMessage({
        message: intl.formatMessage({
          id: 'error_report_generation',
        }),
        error,
      });
    } finally {
      setGeneratingReport(false);
    }
  };

  const generatedAdditionalData =
    params?.appointmentTypeName === 'Montage'
      ? [
          {
            key: '8',
            label: 'PV Nennleistung (kWp)',
            children:
              protocolState.metaData &&
              protocolState.metaData['WT_PvNennleistung'],
          },
          {
            key: '9',
            label: 'Anlagetyp',
            span: 2,
            children:
              protocolState.metaData && protocolState.metaData['WT_Anlagetyp'],
          },
          {
            key: '10',
            label: 'Anzahl der Module',
            contentStyle: { maxWidth: '300px' },
            children:
              protocolState.metaData &&
              protocolState.metaData['WT_NumberOfModules'],
          },
          {
            key: '11',
            label: 'Anzahl der Module',
            span: 2,
            children:
              protocolState.metaData && protocolState.metaData['WT_Hersteller'],
          },
        ]
      : [];

  return (
    <PageLayout>
      {protocolState?.loading || !protocolState?.customer ? (
        <Spin className={styles.pageSpin} />
      ) : (
        <Layout className={styles.contentLayout}>
          <div className={styles.headerContent}>
            <div className={styles.breadcrumbContainer}>
              <BreadcrumNavigation
                page={params?.page ?? ''}
                workorderId={params?.externalCaseId}
                customerName={protocolState?.customer?.name}
              />
            </div>
            <PageHeader
              className={styles.header}
              title={translation('Protocol')}
              extra={[
                <Space key="showAllSubmissions">
                  <Text>{translation('Show All Submissions')}</Text>
                  <Switch
                    checked={showAllSubmissions}
                    onChange={setShowAllSubmissions}
                  />
                </Space>,

                <Divider type="vertical" key="verticalDivider" />,

                <ReactToPrint
                  key="ReactToPrint"
                  bodyClass={styles.printPadded}
                  pageStyle={styles.pdfPageStyle}
                  documentTitle={`${documentTitle?.fileNameStart}${
                    protocolState?.customer?.name
                  }${params?.appointmentTypeName === 'Montage' ? '_' : '-'}${
                    params?.externalCaseId
                  }${documentTitle?.fileNameEnd}.pdf`}
                  content={() => printRef?.current}
                  trigger={() => (
                    <Button
                      type="primary"
                      disabled={protocolState?.loading}
                      loading={protocolState?.loading}
                    >
                      {translation('Print')}
                    </Button>
                  )}
                />,

                <Button
                  key="downloadPDFBtn"
                  type="primary"
                  disabled={generatingReport || protocolState?.loading}
                  loading={generatingReport || protocolState?.loading}
                  onClick={generateDocx}
                >
                  DocX
                </Button>,
              ]}
            />
          </div>
          <Layout hasSider>
            <Content
              className={styles.wrapper}
              ref={scrollProtocolContainerRef}
            >
              <Space className={styles.flexSpaceBetween}>
                <Space
                  ref={printRef}
                  direction="vertical"
                  className={styles.printableContainer}
                >
                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Image
                      width={120}
                      preview={false}
                      src="https://craftosstorage.blob.core.windows.net/enpal-logo/5ea1497819897597ff26526c_enpal_vector.png"
                    />
                    <Space direction="vertical" size={0}>
                      <Text>Enpal B.V.</Text>
                      <Text>Koppenstraße 8</Text>
                      <Text>10243 Berlin</Text>
                    </Space>
                  </Space>
                  <Space style={{ marginBottom: '16px' }}>
                    <Descriptions
                      size="small"
                      title={
                        <Title level={2}>{documentTitle?.documentTitle}</Title>
                      }
                      bordered
                      items={[
                        {
                          key: '1',
                          label: 'Einsatzart',
                          children: params?.appointmentTypeName,
                        },
                        {
                          key: '2',
                          label: 'Customer',
                          children: protocolState?.customer?.name,
                          span: 2,
                        },
                        {
                          key: '3',
                          label: 'Address',
                          children:
                            protocolState?.customer?.address?.address1 ?? '',
                        },
                        {
                          key: '4',
                          span: 2,
                          label: 'Telephone',
                          children:
                            protocolState?.customer?.address?.telephone ?? '',
                        },
                        {
                          key: '5',
                          label: 'Geplant für',
                          children: moment(
                            protocolState?.latestAppointmentDetails
                              ?.appointmentDate ?? Date.now()
                          ).format('DD.MM.YYYY hh:mm A'),
                        },
                        {
                          key: '6',
                          label: 'Stand',
                          span: 2,
                          children: moment(
                            protocolState?.workorderStartDate ?? Date.now()
                          ).format('DD.MM.YYYY hh:mm A'),
                        },
                        {
                          key: '7',
                          label: 'Beschreibung',
                          contentStyle: { maxWidth: '300px' },
                          span: 3,
                          children:
                            protocolState.latestAppointmentDetails
                              ?.appointmentType.description,
                        },
                        ...generatedAdditionalData,
                        {
                          key: '12',
                          label: 'Abgeschlossen von',
                          children:
                            protocolState.latestAppointmentDetails?.team?.name,
                        },
                      ]}
                    />
                  </Space>
                  <ProtocolDocument
                    chapters={chaptersList}
                    showAllSubmissions={showAllSubmissions}
                  />
                </Space>
              </Space>
            </Content>
            <Sider
              width={360}
              theme="light"
              style={{
                maxHeight: '100%',
                overflow: 'auto',
              }}
            >
              {scrollProtocolContainerRef && anchorsList.length ? (
                <Anchor
                  items={anchorsList}
                  getContainer={() =>
                    scrollProtocolContainerRef.current as HTMLDivElement
                  }
                  affix={false}
                  targetOffset={16}
                  showInkInFixed
                  style={{ padding: '16px 8px 0 0' }}
                />
              ) : null}
            </Sider>
          </Layout>
        </Layout>
      )}
    </PageLayout>
  );
};
