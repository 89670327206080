export const TrashSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.66663 4.66699H13.3333"
      stroke="#161618"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66663 7.33301V11.333"
      stroke="#161618"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33337 7.33301V11.333"
      stroke="#161618"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33337 4.66699L4.00004 12.667C4.00004 13.0206 4.14052 13.3598 4.39056 13.6098C4.64061 13.8598 4.97975 14.0003 5.33337 14.0003H10.6667C11.0203 14.0003 11.3595 13.8598 11.6095 13.6098C11.8596 13.3598 12 13.0206 12 12.667L12.6667 4.66699"
      stroke="#161618"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
      stroke="#161618"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
