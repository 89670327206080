export const PriorityCustomerSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_391_46573)">
      <rect width="16" height="16" rx="3" fill="#D9363E" />
      <path
        d="M9.67468 12.4716H4.65063C4.35453 12.4716 4.07056 12.354 3.86118 12.1446C3.65181 11.9352 3.53418 11.6512 3.53418 11.3551V4.6564C3.53418 4.3603 3.65181 4.07633 3.86118 3.86695C4.07056 3.65757 4.35453 3.53995 4.65063 3.53995H11.3494C11.6455 3.53995 11.9294 3.65757 12.1388 3.86695C12.3482 4.07633 12.4658 4.3603 12.4658 4.6564V7.44754"
        stroke="white"
        strokeWidth="1.04668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2344 2.42361V4.65652"
        stroke="white"
        strokeWidth="1.04668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.76758 2.42361V4.65652"
        stroke="white"
        strokeWidth="1.04668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.53418 6.88931H12.4658"
        stroke="white"
        strokeWidth="1.04668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.47412 8.75562H8.87412"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.2417 8.75562H6.6417"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.47412 10.6917H8.87412"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.2417 10.6917H6.6417"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9082 9.68045V11.3551"
        stroke="white"
        strokeWidth="1.04668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9082 13.0298V13.0373"
        stroke="white"
        strokeWidth="1.04668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="3.81445"
        y="3.9404"
        width="8.43652"
        height="2.82776"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_391_46573">
        <rect width="16" height="16" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
