export interface IStatusItemAppointments {
  text: string;
  color?: string;
}

export const RELEVANT_APPROVAL_ROLE: any = {
  QualityManager: 'QM',
  ProjectLead: 'PL',
};

export interface IDataTable {
  id: string;
  key: string;
  formRevisionId: string;
  status: IStatusItemAppointments;
  appointment: string;
  workTypes: string;
  fieldName: string;
  openItem: string;
  questions: boolean;
  approvalRequired: boolean;
  value?: string | string[];
  section?: string;
  allowedRolesForApproval?: string[];
}

export interface ICreateItem {
  elem: any;
  formRevisions: any;
  appointment: string;
}

export interface ICheckboxValue {
  value: string;
  label: any;
  text: any;
}
