import { FC } from 'react';
import { IStatus } from '../../constants/types';
import { getStatusColor } from '../../utils/helpers';

interface Props {
  style?: object;
  size?: string | number;
  onClick?: any;
  status?: IStatus;
}

export const VideoSvg: FC<Props> = ({
  style,
  size = '22',
  status = IStatus.open,
}) => (
  <svg width={size} height={size} viewBox="0 0 25 26" fill="none">
    <g filter="url(#filter0_d_6045_49150)">
      <rect
        x="0.5"
        width="24"
        height="24"
        rx="12"
        fill={status === IStatus.open ? '#F5F5F5' : getStatusColor(status)}
      />
      <path
        d="M12.5 13C13.0523 13 13.5 12.5523 13.5 12C13.5 11.4477 13.0523 11 12.5 11C11.9477 11 11.5 11.4477 11.5 12C11.5 12.5523 11.9477 13 12.5 13Z"
        stroke={status === IStatus.open ? '#E2E2E4' : 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 12C16.1665 14.3335 14.5 15.5 12.5 15.5C10.5 15.5 8.8335 14.3335 7.5 12C8.8335 9.6665 10.5 8.5 12.5 8.5C14.5 8.5 16.1665 9.6665 17.5 12Z"
        stroke={status === IStatus.open ? '#E2E2E4' : 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="#D9D9D9" />
    </g>
    <defs>
      <filter
        id="filter0_d_6045_49150"
        x="0.5"
        y="0"
        width="24"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6045_49150"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6045_49150"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
