import React, { useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import { config } from '../../../../config';
import { IAppointment } from '../../../../constants/types';
import { Button, Space, Typography } from 'antd';
import { MailboxSvg } from '../../../../components/icons/mailboxSvg';
import {
  CalendarOutlined,
  PhoneOutlined,
  PushpinOutlined,
  SwapRightOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { WorkOrderSvg } from '../../../../components/icons/workOrderSvg';
import translation from '../../../../i18n/translation';
import styles from './maps.module.scss';
import { useIntl } from 'react-intl';

const { Text } = Typography;
const mapStyles = {
  height: 'calc(100vh - 290px)',
  width: '100%',
};

const defaultCenter = {
  lat: 52.5127,
  lng: 13.43422,
};

interface Props {
  appointments: any;
  isClustered?: boolean;
  setDrawerAppointment: React.Dispatch<
    React.SetStateAction<IAppointment | null>
  >;
}

const onOpenAddressLink: any = (url: string) => {
  window.open(url, '_blank', 'noreferrer noopener');
};

const getLatLng = (location?: string): any => {
  let latLng = location ? location.split(',') : ['52.512700', '13.434220'];
  return {
    lat: parseFloat(latLng[0] ?? '52.512700'),
    lng: parseFloat(latLng[1] ?? '13.434220'),
  };
};

const MapView: React.FC<Props> = ({
  appointments,
  isClustered = false,
  setDrawerAppointment,
}) => {
  const [
    selectedAppointment,
    setSelectedAppointment,
  ] = useState<IAppointment | null>(null);
  const intl = useIntl();

  return (
    <LoadScript googleMapsApiKey={config.googleMapsAPIKey}>
      <GoogleMap mapContainerStyle={mapStyles} zoom={7} center={defaultCenter}>
        {isClustered ? (
          <MarkerClusterer averageCenter>
            {(clusterer) =>
              appointments.map((appointment: IAppointment, index: number) =>
                !appointment.isGroupHeader ? (
                  <Marker
                    key={`marker-${appointment.externalId}-${index}`}
                    position={getLatLng(appointment?.customerAddress?.latLong)}
                    clusterer={clusterer}
                    clickable
                    onClick={() => {
                      setSelectedAppointment(appointment);
                    }}
                  />
                ) : null
              )
            }
          </MarkerClusterer>
        ) : (
          appointments.map((appointment: IAppointment, index: number) =>
            !appointment.isGroupHeader ? (
              <Marker
                key={`marker-${appointment.externalId}-${index}`}
                position={getLatLng(appointment.customerAddress?.latLong)}
                clickable
                onClick={() => {
                  setSelectedAppointment(appointment);
                }}
              />
            ) : null
          )
        )}

        {selectedAppointment && (
          <InfoWindow
            position={getLatLng(selectedAppointment?.customerAddress?.latLong)}
            onCloseClick={() => setSelectedAppointment(null)}
          >
            <div className={styles.container}>
              <Space direction="vertical">
                {selectedAppointment?.customer && (
                  <Space style={{ padding: '0 0 8px 8px' }}>
                    <MailboxSvg />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Text strong className={styles.name}>
                        {selectedAppointment?.customer}
                      </Text>
                    </div>
                  </Space>
                )}

                {selectedAppointment?.customerAddress?.address && (
                  <Space
                    className={styles.spaceContent}
                    style={{ maxWidth: '90%' }}
                  >
                    <PushpinOutlined className={styles.pushpin} />
                    <Text
                      type="secondary"
                      className={styles.addressLink}
                      onClick={() =>
                        onOpenAddressLink(
                          `http://maps.google.com/maps/dir/${selectedAppointment?.customerAddress?.address}/`
                        )
                      }
                    >
                      {selectedAppointment?.customerAddress?.address}
                    </Text>
                  </Space>
                )}

                {selectedAppointment?.customerAddress?.telephone && (
                  <Space className={styles.spaceContent}>
                    <PhoneOutlined className={styles.phone} />
                    <Text type="secondary">
                      {selectedAppointment?.customerAddress?.telephone}
                    </Text>
                  </Space>
                )}
                <Space
                  direction="vertical"
                  style={{ width: '100%', paddingTop: '16px' }}
                >
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {selectedAppointment?.externalCaseId && (
                      <Space className={styles.spaceContent}>
                        <Text type="secondary" strong>
                          {translation('case_id')}:
                        </Text>
                        <Text ellipsis type="secondary">
                          {selectedAppointment?.externalCaseId}
                        </Text>
                      </Space>
                    )}

                    {selectedAppointment?.workOrderType?.name && (
                      <Space className={styles.spaceContent}>
                        <WorkOrderSvg style={{ margin: '0 0 0 -1px' }} />
                        <Text type="secondary">
                          {selectedAppointment?.workOrderType?.name}
                        </Text>
                      </Space>
                    )}

                    {selectedAppointment?.team?.name && (
                      <Space className={styles.spaceContent}>
                        <TeamOutlined style={{ marginRight: '3px' }} />
                        <Text type="secondary">
                          {selectedAppointment?.team?.name}
                        </Text>
                      </Space>
                    )}

                    {selectedAppointment?.appointmentDate && (
                      <Space className={styles.spaceContent}>
                        <CalendarOutlined />
                        <Text type="secondary">
                          {intl.formatDate(
                            selectedAppointment?.appointmentDate
                          )}
                        </Text>
                      </Space>
                    )}
                    {selectedAppointment?.appointmentDate &&
                      selectedAppointment?.appointmentEndDate && (
                        <Space className={styles.spaceContent}>
                          <Text type="secondary" style={{ fontWeight: 'bold' }}>
                            Start:{' '}
                          </Text>
                          <Text type="secondary">
                            {intl.formatTime(
                              selectedAppointment?.appointmentDate,
                              {
                                timeStyle: 'short',
                                hour12: false,
                              }
                            )}
                          </Text>
                          <Text type="secondary" style={{ fontWeight: 'bold' }}>
                            {translation('end')}:{' '}
                          </Text>
                          <Text type="secondary">
                            {intl.formatTime(
                              selectedAppointment?.appointmentEndDate,
                              {
                                timeStyle: 'short',
                                hour12: false,
                              }
                            )}
                          </Text>
                        </Space>
                      )}
                    <Space
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        type="link"
                        onClick={() =>
                          setDrawerAppointment(selectedAppointment)
                        }
                        style={{ marginLeft: 'auto' }}
                      >
                        {translation('open_in_drawer')} <SwapRightOutlined />
                      </Button>
                    </Space>
                  </Space>
                </Space>
              </Space>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(MapView);
