import { AnyAction } from 'redux';
import { store } from '../../store';
import {
  appointmentsDetailsTypes,
  AppointmentType,
  IAppointmentStatusActionType,
  RequestAnswersData,
} from '../../constants/types';
import {
  getAnswersRevisionId,
  getArrayRevisionUniqueId,
  getSortOrder,
} from '../../utils/helpers';
import ReportService from '../../services/report-service/reportService';
import ApiRepository from '../../services/api/apiRepository';

const actions = {
  getAppointmentsDetailsData(appointId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA,
          payload: await ApiRepository.getAppointmentsDetailsData(appointId),
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getAppointmentDetailWithRelatedAppointments(appointId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: appointmentsDetailsTypes.GET_RELATED_APPOINTMENTS_DATA,
          payload: await fetchAppointmentWithRelatedAppointments(appointId),
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_RELATED_APPOINTMENTS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getAnswersData({
    appointmentId,
    approvalRequired,
    pageNumber,
    pageSize,
    status,
    questions,
    worktypeFilter,
    formRevisionId,
  }: RequestAnswersData) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const answersData = await ApiRepository.getAnswersListData({
          appointmentId,
          approvalRequired,
          status,
          pageNumber,
          pageSize,
          questions,
          worktypeFilter,
          formRevisionId,
        });
        const storeRevisionIds = Object.keys(
          store.getState().appointmentsDetails.formRevisions
        );
        const revisionId = getAnswersRevisionId(answersData!.response);

        const uniqueRevisionsId = getArrayRevisionUniqueId(
          revisionId,
          storeRevisionIds
        );

        if (Boolean(uniqueRevisionsId.length)) {
          try {
            const revisionsData = await ApiRepository.getFormRevisions(
              uniqueRevisionsId
            );
            dispatch({
              type: appointmentsDetailsTypes.GET_FORM_REVISIONS_DATA,
              payload: revisionsData,
            });
          } catch (err: any) {
            let error = new Error();
            error = err;
            throw error;
          }
        }

        try {
          const workTypesOrder = store
            .getState()
            .appointmentsDetails.userData?.appointmentType?.workTypes.map(
              (a: any) => a.id
            );
          const revisions: any = Object.values(
            store.getState().appointmentsDetails.formRevisions
          );
          const existFieldsOrder = getSortOrder(
            workTypesOrder,
            revisions,
            answersData!.response
          );
          answersData!.response.sort(
            (a: any, b: any) =>
              existFieldsOrder.indexOf(a.formFieldId) -
              existFieldsOrder.indexOf(b.formFieldId)
          );
        } catch (e) {
          throw e;
        }

        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_ANSWERS_DATA,
          payload: answersData,
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getMetadataInfos(caseId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: appointmentsDetailsTypes.GET_ADDITIONAL_INFOS,
          payload: await ApiRepository.getMetadataInfos(caseId),
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getDescriptionInfo(externalId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const resp = await ApiRepository.getAppointmentExternalDetail(
          externalId
        );
        dispatch({
          type: appointmentsDetailsTypes.GET_DESCRIPTION_INFOS,
          payload: { description: resp?.response?.workOrderDescription },
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  setDefaultStateUserData() {
    return {
      type: appointmentsDetailsTypes.SET_DEFAULT_APPOINTMENTS_DETAILS_DATA,
    };
  },

  setDefaultStateAnswersData() {
    return {
      type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_ANSWERS_DATA,
      payload: {
        pagination: {
          currentPage: 1,
          pageSize: 10,
          totalCount: 0,
        },
        response: [],
      },
    };
  },

  setDefaultPagination() {
    return {
      type: appointmentsDetailsTypes.SET_DEFAULT_PAGINATION,
    };
  },

  setLoadingDetails(loading: boolean) {
    return {
      type: appointmentsDetailsTypes.SET_LOADING_DETAILS,
      payload: loading,
    };
  },

  setReportGenerating(generating: boolean) {
    return {
      type: appointmentsDetailsTypes.SET_GENERATE_REPORT,
      payload: generating,
    };
  },

  generateReport(externalId: string, id: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: appointmentsDetailsTypes.SET_GENERATE_REPORT,
          payload: true,
        });
        await ReportService.generateReport(
          store.getState().authentication.token!,
          id,
          externalId
        );
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL,
          payload: err,
        });
      } finally {
        dispatch({
          type: appointmentsDetailsTypes.SET_GENERATE_REPORT,
          payload: false,
        });
      }
    };
  },

  resetAppointmentsDetailError() {
    return {
      type: appointmentsDetailsTypes.RESET_APPOINTMENTS_DETAILS_ERRORS,
      payload: null,
    };
  },

  resetState() {
    return {
      type: appointmentsDetailsTypes.RESET_APPOINTMENTS_DETAILS_STATE,
      payload: null,
    };
  },

  updateAppointmentStatus(
    appointmentId: string,
    actionType: IAppointmentStatusActionType
  ) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        if (actionType === IAppointmentStatusActionType.close) {
          await ApiRepository.closeAppointment(appointmentId);
        } else {
          await ApiRepository.updateAppointmentStatus(
            appointmentId,
            actionType
          );
        }
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA,
          payload: await ApiRepository.getAppointmentsDetailsData(
            appointmentId
          ),
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },
};

const fetchAppointmentWithRelatedAppointments = async (appointId: string) => {
  const appointmentDetailsPromise = ApiRepository.getAppointmentsDetailsData(
    appointId
  );

  const worktypesPromise = ApiRepository.getWorktypeInstanceData(appointId);

  const appointmentDetails = (await appointmentDetailsPromise)?.response;
  const worktypes = (await worktypesPromise)?.response;

  const groupsPromise = ApiRepository.getWorktypeGroupsData(
    appointmentDetails.appointmentType.id
  );

  const relatedAppointmentsPromise = ApiRepository.getAppointmentsByCaseId(
    appointmentDetails.externalCaseId,
    appointmentDetails.appointmentType.id
  );

  const groups = (await groupsPromise)?.response;
  const appointments = (await relatedAppointmentsPromise)?.response;

  const relatedAppointments = appointments
    ? appointments.filter(
        (appointment: AppointmentType) =>
          appointment.externalId !== appointmentDetails.externalId
      )
    : [];
  return {
    appointmentDetails,
    relatedAppointments,
    groups,
    worktypes,
  };
};

export default actions;
