import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Tag,
  Table,
  Layout,
  Checkbox,
  TreeSelect,
  Radio,
  Tabs,
  Badge,
  Space,
  Row,
  Col,
  Avatar,
  Typography,
  TableColumnsType,
  TableProps,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { RootState } from '../../store';
import * as routes from '../../router/routes';
import AppointmentDetailsAction from './actions';
import styles from './formFieldAnswer.module.scss';
import { getFormFieldLabel, getStatusColor } from '../../utils/helpers';
import translation from '../../i18n/translation';
import {
  ICreateItem,
  IDataTable,
  ICheckboxValue,
  IStatusItemAppointments,
} from './typeInterface';
import classNames from 'classnames';
import {
  IAppointmentStatusActionType,
  IWorkTypeAnswers,
  IWorkTypeGroup,
  IWorkType,
} from '../../constants/types';
import { TreeNode } from 'antd/lib/tree-select';
import { showNotificationMessage } from '../../utils/notification';
import AppLayout from '../../components/appLayout';
import AppointmentDetailsSidebar from './appointmentDetailsSidebar';
import CarouselSlider from '../../components/carouselSlider/carouselSlider';
import useAutoFetchData from '../../utils/useAutoFetchData';
import BreadcrumNavigation from '../../components/breadcrumNavigation';
import { useSaveLocationSearchParams } from 'craftos-ui';
import DOMPurify from 'dompurify';

const MAX_PAGE_SIZE = 1000;
const { Content } = Layout;
const { Text, Link } = Typography;

interface IChangeUrl {
  pageNumber?: number | undefined;
  status?: string;
  questions?: boolean;
  worktype?: string;
  activeGroup?: string;
  approvalRequired?: boolean;
  approver?: string;
}

const FormFieldAnswer: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params: { page?: string; appointId?: string } = useParams();
  const appointmentsDetails = useSelector(
    (state: RootState) => state.appointmentsDetails
  );

  const getCheckboxValue = (group?: IWorkTypeGroup): ICheckboxValue[] => [
    {
      value: 'Open',
      text: translation('open'),
      label: (
        <Text className={styles.checkboxText}>
          <Badge
            color={getStatusColor('') || '#E5E5E5'}
            className={styles.badgeStatusColor}
          />
          {translation('open')}&nbsp;
          <Text code className={styles.checkboxTextNumber}>
            {appointmentsDetails?.userData?.appointmentType?.name === 'MVT' ||
            'Sep. Zählertausch'
              ? group?.answerCounts?.open
              : group?.answerCounts?.openApprovable}
          </Text>
        </Text>
      ),
    },
    {
      value: 'Submitted',
      text: translation('submitted'),
      label: (
        <Text className={styles.checkboxText}>
          <Badge
            color={getStatusColor('Submitted')}
            className={styles.badgeStatusColor}
          />
          {translation('submitted')}&nbsp;
          <Text code className={styles.checkboxTextNumber}>
            {group?.answerCounts?.submitted}
          </Text>
        </Text>
      ),
    },
    {
      value: 'Resubmitted',
      text: translation('re_submitted'),
      label: (
        <Text className={styles.checkboxText}>
          <Badge
            color={getStatusColor('Resubmitted')}
            className={styles.badgeStatusColor}
          />
          {translation('re_submitted')}&nbsp;
          <Text code className={styles.checkboxTextNumber}>
            {group?.answerCounts?.resubmitted}
          </Text>
        </Text>
      ),
    },
    {
      value: 'InReview',
      text: translation('in_review'),
      label: (
        <Text className={styles.checkboxText}>
          <Badge
            color={getStatusColor('InReview')}
            className={styles.badgeStatusColor}
          />
          {translation('in_review')}&nbsp;
          <Text code className={styles.checkboxTextNumber}>
            {group?.answerCounts?.inReview}
          </Text>
        </Text>
      ),
    },
    {
      value: 'Rejected',
      text: translation('rejected'),
      label: (
        <Text className={styles.checkboxText}>
          <Badge
            color={getStatusColor('Rejected')}
            className={styles.badgeStatusColor}
          />
          {translation('rejected')}&nbsp;
          <Text code className={styles.checkboxTextNumber}>
            {group?.answerCounts?.rejected}
          </Text>
        </Text>
      ),
    },
    {
      value: 'Approved',
      text: translation('approved'),
      label: (
        <Text className={styles.checkboxText}>
          <Badge
            color={getStatusColor('Approved')}
            className={styles.badgeStatusColor}
          />
          {translation('approved')}&nbsp;
          <Text code className={styles.checkboxTextNumber}>
            {appointmentsDetails?.userData?.appointmentType?.name === 'MVT' ||
            'Sep. Zählertausch'
              ? group?.answerCounts?.approved || 0
              : (group?.answerCounts?.approved || 0) -
                (group?.answerCounts?.autoApproved || 0)}
          </Text>
        </Text>
      ),
    },
  ];

  const findStatusLabel = (elem: any): string => {
    let status = elem.status;
    if (!elem.approvalRequired && status === 'Approved') {
      return 'AUTO-APPROVED';
    }
    return getCheckboxValue().find((x) => x.value === status)?.text;
  };

  const getQueryString = useCallback((): any => {
    return location.search
      .slice(1)
      .split('&')
      .reduce((total, current) => {
        const res = current.split('=');
        return {
          ...total,
          [res[0]]: res[1],
        };
      }, {});
  }, [location.search]);

  const [questions, setQuestions] = useState<boolean>(
    getQueryString().questions ? getQueryString().questions === 'true' : false
  );

  const [approvalRequired, setApprovalRequired] = useState<boolean>(
    localStorage.getItem('approval_required') === 'true'
  );

  const [selectedGroup, setSelectedGroup] = useState<string>(
    getQueryString().group ? getQueryString().group : 'all'
  );

  const [worktypeFilter, setWorktypeFilter] = useState<string[]>(
    getQueryString().worktype ? getQueryString().worktype.split(',') : []
  );

  const [worktypeList, setWorktypeList] = useState<IWorkType[]>([]);

  const [fieldImageVisibleId, setFieldImageVisibleId] = useState<string>('');

  let statusParam = getQueryString().status ?? '';
  let approverParam = getQueryString().approver ?? '';

  const [status, setStatus] = useState<string[]>(
    statusParam ? statusParam.split(',') : []
  );
  const [selectedApprovalFilter, setSelectedApprovalFilter] = useState<
    string[]
  >(approverParam ? approverParam.split(',') : []);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState(0);

  useEffect(() => {
    dispatch(AppointmentDetailsAction.setLoadingDetails(true));
    dispatch(
      AppointmentDetailsAction.getAppointmentDetailWithRelatedAppointments(
        params.appointId!
      )
    );
  }, [location.search, params.appointId, dispatch]);

  useEffect(() => {
    if (appointmentsDetails.error) {
      showNotificationMessage({
        message: intl.formatMessage({
          id:
            appointmentsDetails.error.errorKey ??
            'error_fetch_apointment_details',
        }),
        error: appointmentsDetails.error,
        onClose: () =>
          dispatch(AppointmentDetailsAction.resetAppointmentsDetailError()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentsDetails.error]);

  useSaveLocationSearchParams(routes.appointmentsDetails.path);

  const changeUrl = ({
    pageNumber,
    status,
    questions,
    worktype,
    activeGroup,
    approver,
  }: IChangeUrl) => {
    clearScrollPosition();
    const statusUrl = status ? `&status=${status}` : '';
    const pageNumberUrl = pageNumber ? `&pageNumber=${pageNumber}` : '';
    const questionsUrl = questions ? `&questions=${questions}` : '';
    const activeGroupUrl = activeGroup ? `&group=${activeGroup}` : '';
    const worktypeUrl = worktype ? `&worktype=${worktype}` : '';
    const approverUrl = approver ? `&approver=${approver}` : '';
    const url = `${statusUrl}${pageNumberUrl}${questionsUrl}${activeGroupUrl}${worktypeUrl}${approverUrl}`.slice(
      1
    );

    navigate({
      pathname: location.pathname,
      search: `?${url}`,
    });
  };

  const onUpdateAppointmentStatus = (
    actionType: IAppointmentStatusActionType
  ) => {
    dispatch(
      AppointmentDetailsAction.updateAppointmentStatus(
        params.appointId!,
        actionType
      )
    );
  };

  const onApprovalRoleFilterChange = (checkedValues: any) => {
    setSelectedApprovalFilter(checkedValues ?? []);
    changeUrl({
      status: status.join(','),
      questions,
      worktype: worktypeFilter.join(','),
      activeGroup: selectedGroup,
      approver: checkedValues?.length ? checkedValues.join(',') : '',
    });
  };

  const onStatusChange = (checkedValues: any) => {
    dispatch(AppointmentDetailsAction.setDefaultPagination());
    setStatus(checkedValues);
    changeUrl({
      status: checkedValues?.length ? checkedValues.join(',') : '0',
      questions,
      worktype: worktypeFilter.join(','),
      activeGroup: selectedGroup,
      approver: selectedApprovalFilter.join(','),
    });
  };

  const onSetQuestions = () => {
    setQuestions((state) => {
      changeUrl({
        status: status.join(','),
        questions: !state,
        worktype: worktypeFilter.join(','),
        activeGroup: selectedGroup,
        approver: selectedApprovalFilter.join(','),
      });
      return !state;
    });
  };

  const onChangeGroupTab = (activeGroup: any) => {
    setSelectedGroup(activeGroup);
    setWorktypeFilter([]);
    changeUrl({
      status: status.join(','),
      questions,
      worktype: '',
      activeGroup,
      approver: selectedApprovalFilter.join(','),
    });
  };

  const onSetWorktypeFilter = (checkedValues: any) => {
    setWorktypeFilter(checkedValues);
    changeUrl({
      status: status.join(','),
      questions,
      worktype: checkedValues.length ? checkedValues.join(',') : '',
      activeGroup: selectedGroup,
      approver: selectedApprovalFilter.join(','),
    });
  };

  const onSetApprovalRequired = () => {
    clearScrollPosition();
    setApprovalRequired((state) => {
      let value = !state;
      localStorage.setItem('approval_required', value.toString());
      return value;
    });
  };
  const onCancelBubble = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const renderCopyableText = (value: string, field: any) => {
    return (
      <div onClick={(event: any) => onCancelBubble(event)}>
        <Text
          className={styles.fieldValueCopyableText}
          copyable={value?.length > 1}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(value),
            }}
          />
        </Text>
      </div>
    );
  };
  const renderFieldImages = (urls: string[], field: any) => {
    const onClickImageAvatar: any = (event: MouseEvent, index: number) => {
      event.preventDefault();
      event.stopPropagation();
      setFieldImageVisibleId(field.id);
      setSelectedPreviewImage(index);
    };
    return (
      <span onClick={(event: any) => onCancelBubble(event)}>
        <Avatar.Group maxCount={4} size={48} style={{ cursor: 'pointer' }}>
          {urls.map((url: string, index: number) => (
            <Avatar
              shape="square"
              key={`avatar-img-${field.id}-${index}`}
              onClick={(event: any) => onClickImageAvatar(event, index)}
              src={url}
            />
          ))}
        </Avatar.Group>
        <div style={{ display: 'none' }}>
          {fieldImageVisibleId ? (
            <CarouselSlider
              imageUrls={urls}
              currentIndex={selectedPreviewImage}
              visible={fieldImageVisibleId === field.id}
              onVisibleChange={() => setFieldImageVisibleId('')}
            />
          ) : null}
        </div>
      </span>
    );
  };

  const onTableChange: TableProps<IDataTable>['onChange'] = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    onApprovalRoleFilterChange(filters?.allowedRolesForApproval);
  };

  const columns: TableColumnsType<IDataTable> = [
    {
      title: translation('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: IStatusItemAppointments) => {
        return (
          <Tag
            className={styles.statusText}
            color={status.color ? status.color : 'default'}
          >
            {status.text}
          </Tag>
        );
      },
      width: '170px',
    },
    {
      title: translation('work_types'),
      dataIndex: 'workTypes',
      key: 'workTypes',
      render: (value: any, field: any) => {
        return (
          <div id={field.id}>
            {(value === 'MVT' || value === 'Separater Zählertausch') &&
            field?.section
              ? field.section
              : value}
          </div>
        );
      },
      width: '250px',
    },
    {
      title: translation('field_name'),
      dataIndex: 'fieldName',
      key: 'fieldName',
    },
    {
      title: translation('field_value'),
      dataIndex: 'value',
      key: 'value',
      render: (value: any, field: any) =>
        Array.isArray(value)
          ? renderFieldImages(value, field)
          : renderCopyableText(value, field),
    },
    {
      title: translation('approval'),
      dataIndex: 'allowedRolesForApproval',
      key: 'allowedRolesForApproval',
      filters: [
        {
          text: 'QM',
          value: 'QM',
        },
        {
          text: 'PL',
          value: 'PL',
        },
      ],
      onFilter: (val: any, field: any) =>
        field?.allowedRolesForApproval?.includes(val),
      filterSearch: false,
      defaultFilteredValue: selectedApprovalFilter,
      width: '130px',
      render: (val: any, field: any) =>
        val.map((item: string, i: number) => (i === 0 ? item : ` / ${item}`)),
    },
  ];

  const dataSourceTable = useMemo(() => {
    const getFieldAnswerValue = (
      elem: any,
      formField: any
    ): string | string[] => {
      const latestAnswer: IWorkTypeAnswers = elem?.answers[0];
      if (!latestAnswer) {
        return '';
      }

      if (latestAnswer.uploads) {
        return latestAnswer.uploads;
      } else if (latestAnswer.content) {
        return latestAnswer.content;
      } else if (latestAnswer.dateContent) {
        return `${intl.formatDate(
          latestAnswer.dateContent || new Date()
        )} ${intl.formatTime(latestAnswer.dateContent || new Date())}`;
      } else if (
        latestAnswer.selections &&
        latestAnswer.selections.length > 0 &&
        formField &&
        formField.answers
      ) {
        return latestAnswer.selections
          .map(
            (id: string) =>
              formField.answers.find((field: any) => field.id === id)?.label
          )
          .join(', ');
      }
      return '';
    };

    const createItem = ({
      appointment,
      elem,
      formRevisions,
    }: ICreateItem): IDataTable => {
      const subsectionFieldName = getFormFieldLabel(
        formRevisions,
        elem.formFieldId,
        elem.formInstance.formRevisionId
      );
      return {
        id: elem.id,
        key: elem.id,
        section: subsectionFieldName?.section,
        formRevisionId: elem.formInstance.formRevisionId,
        status: {
          text: findStatusLabel(elem),
          color: getStatusColor(elem.status),
        },
        appointment,
        workTypes: subsectionFieldName?.workType,
        fieldName: subsectionFieldName?.formField?.label,
        openItem: elem.id,
        questions: elem.questions,
        approvalRequired: elem.approvalRequired,
        value: getFieldAnswerValue(elem, subsectionFieldName?.formField),
        allowedRolesForApproval: subsectionFieldName?.allowedRolesForApproval,
      };
    };

    return Boolean(appointmentsDetails.answersData.length)
      ? appointmentsDetails.answersData.map((item: any) =>
          createItem({
            elem: item,
            formRevisions: appointmentsDetails.formRevisions,
            appointment: appointmentsDetails.userData?.appointmentType?.name,
          })
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, appointmentsDetails]);

  useEffect(() => {
    if (
      appointmentsDetails.worktypes &&
      appointmentsDetails.worktypeGroups &&
      appointmentsDetails.userData &&
      appointmentsDetails.userData?.appointmentType &&
      appointmentsDetails.userData?.appointmentType.workTypes.length > 0
    ) {
      let worktypes = appointmentsDetails.worktypes;
      let worktpesSortOrder = appointmentsDetails.userData?.appointmentType.workTypes.map(
        (a: any) => a.id
      );

      let visibleWorktype =
        selectedGroup === 'all'
          ? []
          : appointmentsDetails.worktypeGroups.find(
              (group: IWorkTypeGroup) => group.id === selectedGroup
            )?.worktypes;
      let sortedWorktypes = (visibleWorktype && visibleWorktype.length > 0
        ? visibleWorktype
        : selectedGroup === 'all'
        ? worktypes
        : []
      ).sort(
        (a: any, b: any) =>
          worktpesSortOrder.indexOf(a.key) - worktpesSortOrder.indexOf(b.key)
      );
      setWorktypeList(sortedWorktypes);

      if (sortedWorktypes && sortedWorktypes.length) {
        dispatch(
          AppointmentDetailsAction.getAnswersData({
            appointmentId: params.appointId,
            pageNumber:
              selectedGroup !== 'all'
                ? 1
                : getQueryString().pageNumber
                ? Number(getQueryString().pageNumber)
                : 1,
            pageSize: MAX_PAGE_SIZE,
            status: status[0] !== '0' ? status : [],
            questions,
            approvalRequired,
            worktypeFilter:
              worktypeFilter && worktypeFilter.length > 0
                ? worktypeFilter
                : visibleWorktype?.map((wt: IWorkType) => wt.key ?? '') ?? [],
          })
        );
      } else {
        dispatch(AppointmentDetailsAction.setDefaultStateAnswersData());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointmentsDetails.worktypes,
    appointmentsDetails.worktypeGroups,
    appointmentsDetails.userData,
    approvalRequired,
  ]);

  const scroll = (id: string) => {
    const section = document.getElementById(id);
    section?.scrollIntoView({ behavior: 'auto', block: 'center' });
  };

  const saveScrollPosition = (rowKey: string) => {
    if (params?.appointId) {
      let scrollObject = { [params.appointId]: rowKey };
      localStorage.setItem(
        'appointentDetailScrollY',
        JSON.stringify(scrollObject)
      );
    }
  };
  const clearScrollPosition = () => {
    localStorage.setItem('appointentDetailScrollY', '');
  };

  useEffect(() => {
    let savedScroll = localStorage.getItem('appointentDetailScrollY');
    if (savedScroll && params?.appointId && dataSourceTable) {
      let scrollPosition = JSON.parse(savedScroll)[params.appointId];
      if (scrollPosition) {
        scroll(scrollPosition);
      } else {
        localStorage.setItem('appointentDetailScrollY', '');
      }
    }
  }, [params?.appointId, dataSourceTable]);

  useAutoFetchData(() => {
    clearScrollPosition();
    if (params?.appointId) {
      dispatch(
        AppointmentDetailsAction.getAppointmentDetailWithRelatedAppointments(
          params.appointId
        )
      );
    }
    return () => {
      dispatch(AppointmentDetailsAction.resetState());
    };
  });

  const getStatusBadge = (status: String) => {
    switch (status) {
      case 'Stopped':
        return (
          <Tag className={styles.appointmentStatusText}>
            {translation('stopped')}
          </Tag>
        );
      case 'Closed':
        return (
          <Tag className={styles.appointmentStatusText}>
            {translation('closed')}
          </Tag>
        );
      case 'Active':
        return (
          <Tag
            className={styles.appointmentStatusText}
            color={getStatusColor('Approved')}
          >
            {translation('active')}
          </Tag>
        );
      default:
        return null;
    }
  };

  const selectedGroupObj =
    appointmentsDetails.worktypeGroups &&
    appointmentsDetails.worktypeGroups.find(
      (group: IWorkTypeGroup) => group.id === selectedGroup
    );

  return (
    <AppLayout>
      <Layout className={styles.contentLayout}>
        <AppointmentDetailsSidebar
          loading={false}
          userData={appointmentsDetails.userData}
          relatedAppointments={appointmentsDetails?.relatedAppointments}
          isAllFormsApproved={
            appointmentsDetails.userData?.answerCounts?.openRequired === 0 &&
            appointmentsDetails.userData?.answerCounts?.approvalPending === 0
          }
          onUpdateAppointmentStatus={onUpdateAppointmentStatus}
        />
        <Content className={styles.dataLayout}>
          <Content>
            <Row align="middle" className={styles.groupContent}>
              <Col flex="auto">
                <div className={styles.breadcrumbContainer}>
                  <Space>
                    <BreadcrumNavigation
                      page={params.page!}
                      appointmentId={params.appointId!}
                      customerName={
                        appointmentsDetails.userData?.customer?.name
                      }
                    />
                    {getStatusBadge(appointmentsDetails.userData?.status)}
                  </Space>
                  <Tabs
                    activeKey={selectedGroup}
                    tabBarStyle={{ marginBottom: 0 }}
                    onChange={onChangeGroupTab}
                    items={
                      appointmentsDetails.worktypeGroups &&
                      appointmentsDetails.worktypeGroups.map(
                        (group: IWorkTypeGroup) => ({
                          key: group.id,
                          label: (
                            <span>
                              <Badge
                                color={
                                  getStatusColor(
                                    group.status?.toString() ?? ''
                                  ) || '#E5E5E5'
                                }
                                className={styles.badgeStatusColor}
                              />
                              {group.name}
                            </span>
                          ),
                        })
                      )
                    }
                  />
                </div>
              </Col>
              <Col
                style={{
                  marginRight: '16px',
                }}
              >
                <Space>
                  <Link
                    href={`${routes.protocol.pathWithParams(
                      params?.page!,
                      appointmentsDetails?.userData?.externalCaseId ?? '',
                      appointmentsDetails?.userData?.appointmentType?.name ?? ''
                    )}`}
                    target="_blank"
                  >
                    <Button type="primary" className={styles.customLoader}>
                      {translation('display_protocol')}
                    </Button>
                  </Link>
                  <Link
                    href={`${routes.workorder.pathWithParams(
                      params?.page!,
                      appointmentsDetails?.userData?.externalCaseId ?? '',
                      appointmentsDetails?.userData?.appointmentType?.name ?? ''
                    )}`}
                    target="_blank"
                  >
                    <Button>{translation('workorder')}</Button>
                  </Link>
                </Space>
              </Col>
            </Row>
          </Content>
          <Content className={styles.dataContent}>
            <div className={styles.titleContainer}>
              <div className={styles.filtersContainer}>
                <Radio
                  className="radioGroup"
                  onChange={() => onStatusChange([])}
                  checked={status.length === 0 || status[0] === '0'}
                >
                  <Text className={styles.checkboxText}>
                    {translation('all')}&nbsp;
                    <Text code className={styles.checkboxTextNumber}>
                      {appointmentsDetails.userData?.appointmentType?.name ===
                        'MVT' || 'Sep. Zählertausch'
                        ? (selectedGroupObj?.answerCounts?.approvalPending ??
                            0) +
                          (selectedGroupObj?.answerCounts?.open ?? 0) +
                          (selectedGroupObj?.answerCounts?.rejected ?? 0) +
                          (selectedGroupObj?.answerCounts?.approved ?? 0)
                        : (selectedGroupObj?.answerCounts?.approvalPending ??
                            0) +
                          (selectedGroupObj?.answerCounts?.openApprovable ??
                            0) +
                          (selectedGroupObj?.answerCounts?.rejected ?? 0) +
                          (selectedGroupObj?.answerCounts?.approved ?? 0) -
                          (selectedGroupObj?.answerCounts?.autoApproved ?? 0)}
                    </Text>
                  </Text>
                </Radio>
                <Checkbox.Group
                  className="checkbox"
                  options={getCheckboxValue(selectedGroupObj)}
                  onChange={onStatusChange}
                  value={status}
                />
                <Checkbox
                  className="checkbox"
                  checked={questions}
                  onChange={onSetQuestions}
                  value={questions}
                >
                  <Text className={styles.checkboxText}>
                    {translation('questions')}
                    <Badge
                      count={selectedGroupObj?.answerCounts?.questions ?? 0}
                      showZero
                      style={{ marginLeft: '5px' }}
                    />
                  </Text>
                </Checkbox>
              </div>
              <TreeSelect
                disabled={appointmentsDetails.loading}
                loading={appointmentsDetails.loading}
                className={styles.worktypeFilterTree}
                value={worktypeFilter}
                onChange={onSetWorktypeFilter}
                allowClear
                treeCheckable
                popupMatchSelectWidth={false}
                treeNodeLabelProp="title"
                showCheckedStrategy="SHOW_PARENT"
                placeholder={intl.formatMessage({ id: 'work_types' })}
                filterTreeNode={(input: String, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {worktypeList &&
                  worktypeList.map((worktype: IWorkType) => (
                    <TreeNode
                      value={worktype.value ?? worktype.id}
                      key={worktype.value ?? worktype.id}
                      label={worktype.label}
                      title={
                        <Badge
                          color={
                            getStatusColor(worktype.status?.toString() ?? '') ||
                            '#E5E5E5'
                          }
                          text={worktype.label}
                        />
                      }
                    />
                  ))}
              </TreeSelect>
              <div className={styles.autoApprovalFilterContainer}>
                <Checkbox
                  className="checkbox"
                  checked={approvalRequired}
                  onChange={onSetApprovalRequired}
                  value={approvalRequired}
                >
                  {translation('include_auto_approval')}
                </Checkbox>
              </div>
            </div>
            <div className={styles.tableContent}>
              <Table
                className={styles.table}
                columns={columns}
                dataSource={dataSourceTable}
                sticky={{ offsetHeader: -16 }}
                onRow={(record: IDataTable) => {
                  return {
                    onClick: () => {
                      saveScrollPosition(record.id);
                      navigate({
                        pathname: routes.workType.pathWithParams(
                          params.page!,
                          params.appointId!,
                          record.id
                        ),
                      });
                    },
                  };
                }}
                pagination={{
                  hideOnSinglePage: true,
                  position: ['bottomCenter'],
                  pageSize: MAX_PAGE_SIZE,
                }}
                onChange={onTableChange}
                rowClassName={(record) =>
                  classNames('rowItem', {
                    [styles.tableRowItemHighlighted]: record.questions,
                  })
                }
              />
            </div>
          </Content>
        </Content>
      </Layout>
    </AppLayout>
  );
};

export default FormFieldAnswer;
