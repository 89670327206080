import { Typography } from 'antd';
import styles from './ProtocolDocument.module.scss';
import ProtocolField from './ProtocolField';

interface ProtocolSectionProps {
  section: any;
  showAllSubmissions: boolean;
}

const { Title } = Typography;

const ProtocolSection = ({
  section,
  showAllSubmissions,
}: ProtocolSectionProps) => (
  <div id={section?.formFieldId} className={styles.sectionContainer}>
    <Title level={4}>{section?.protocolText || section?.label}</Title>
    {section.questions.map((item: any, i: number) =>
      item.type === 'Section' ? (
        <ProtocolSection
          section={item}
          key={`pro-section-${i}`}
          showAllSubmissions={showAllSubmissions}
        />
      ) : (
        <ProtocolField
          field={item}
          key={`pro-section-field-${i}`}
          showAllSubmissions={showAllSubmissions}
        />
      )
    )}
  </div>
);

export default ProtocolSection;
