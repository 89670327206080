import { LOCALES } from '../locales';

const english = {
  [LOCALES.ENGLISH]: {
    en: 'English',
    de: 'Deutsch',
    pl: 'Polish',
    ro: 'Romanian',
    sk: 'Solvakian',
    uk: 'Ukrainian',
    languages: 'Languages',
    all: 'All',
    add: 'Add',
    address: 'Address',
    team: 'Team',
    customer: 'Customer',
    appointment: 'Appointment',
    appointmentDate: 'Date',
    approved: 'Approved',
    appointmentType: 'Type',
    end: 'End',
    no_info: 'No information',
    progress: 'Progress',
    appointment_status: 'Appointment Status',
    work_order_status: 'WO Status',
    work_order_type: 'Work Order Type',
    zip: 'ZIP',
    module: 'Modules',
    meter_exchange: 'ME',
    meter_exchange_cabinet: 'MEC',
    attention_error: 'Attention Error',
    acceptance_criteria: 'Acceptance criteria',
    quality_manager: 'Quality Manager',
    craftsman: 'Craftsman',
    submission: 'Submission',
    btn_approve: 'Approve',
    btn_reject: 'Reject',
    btn_escalate: 'Escalate',
    btn_startNow: 'Start Now',
    btn_goBack: 'Go Back',
    btn_confirm: 'Confirm',
    btn_deny: 'Overwrite',
    btn_comment: 'Comment',
    btn_reopen: 'Reopen',
    cancel: 'Cancel',
    cancel_editing: 'Cancel',
    attach_to_comment: 'Attach to Comment',
    max_five_images_for_comment: 'You can attach up to 5 images.',
    image_editor_error:
      'Something went wrong. Please close the editor and try again.',
    create_first_form: 'Create your first form',
    comment_placeholder: 'Max 600 Characters',
    collapse_all_sections: 'Collapse all sections',
    change_order: 'Change Order',
    copy_form: 'Copy Form',
    create_form: 'Create Form',
    save_answer_first:
      'To see the answer in the list, you have to save it first',
    save_error_message_after_answers:
      'To enter the error message, you have to save the valid answer',
    comment_modal_visible_text:
      'Please write to the craftsman why you reject his work and what he should do to correct the mistake',
    comment_modal_confirmation_visible_title:
      'This step has already been approved!',
    comment_modal_confirmation_visible_text:
      'Are you sure you want to reject the step? If so, please write a detailed explanation for the handyman so he can understand why you are changing the status.',
    delete_form: 'Delete Form',
    date_created: 'Date Created',
    date_modified: 'Date Modified',
    date_updated: 'Date Updated',
    description: 'Description',
    issue_description: 'Issue description',
    add_issue_description: 'Add an issue description',
    no_issue_description: 'Issue description is not yet provided',
    description_optional: 'Description(optional)',
    edit_from: 'Edit From',
    back_to_homepage: 'Back to Home',
    page_not_found: 'Page not found',
    an_error_occured: 'An Error occured',
    error_notification_description:
      'Most probably this form does not exists yet.',
    error_boundary_title: 'Our experts correct the error.',
    error_boundary_text: 'Sorry for temporary inconvenience',
    error_fetch_apointments:
      'An error occured while fetching the appointments.',
    error_fetch_teams: 'An error occured while fetching the teams.',
    error_post_appointment_note:
      'An error occured while submitting appointment notes.',
    error_post_appointment_delay_reason:
      'An error occured while submitting appointment delay reason.',
    error_post_workorder_description:
      'An error occured while submitting a work order description.',
    error_fetch_apointment_details:
      'An error occured while fetching the appointment details.',
    error_fetch_workorder_details:
      'An error occured while fetching the workorder details.',
    error_fetch_documentation:
      'An error occured while fetching the documentation.',
    error_fetch_appointment_time_log:
      'An error occured while fetching the appointment time log details.',
    error_fetch_appointment_status_change_log:
      'An error occured while fetching the appointment status change log details.',
    error_fetch_appointment_login_users:
      'An error occurred while fetching the appointment logged-in users list.',
    error_fetch_appointment_types:
      'An error occured while fetching the appointment types list.',
    error_fetch_worktype_groups: 'An error occured while fetching the groups.',
    error_fetch_worktype_instance:
      'An error occured while fetching the worktypes.',
    error_fetch_answers: 'An error occured while fetching the answers',
    error_fetch_form_revisions:
      'An error occured while fetching the form revision data.',
    error_fetch_answer_details:
      'An error occured while fetching answer details for the field.',
    error_fetch_approval_comments:
      'An error occured while fetching review comments.',
    error_fetch_reason_code_list:
      'An error occured while fetching the reason codes.',
    error_post_comment: 'An error occured while submitting comments.',
    error_post_reason_code:
      'An error occured while submitting the reason codes.',
    error_post_confirm_approval: 'An error occured while confirming approvals.',
    error_post_reopen: 'An error occured while reopening the field.',
    error_upload_images: 'An error occured while uploading images.',
    error_report_generation: 'An error occured while generating the reports.',
    error_post_appointment_status:
      'An error occurred while updating appointment status.',
    error_close_appointment: 'An error occurred while closing appointment.',
    error_post_approval_conflict: 'This field has already been reviewed.',
    error_post_metadata: 'An error occured while creating new Metadata.',

    error_fetch_assignments_details:
      'An error occured while fetching assignment details.',
    error_empty_queue_item:
      'There are no packages available to review for you at the moment. Please try again later.',
    error_fetch_approval_package:
      'An error occurred while fetching approval packages.',
    error_fetch_next_approval_package:
      'An error occurred while fetching next approval packages.',
    error_fetch_approval_package_count:
      'An error occurred while fetching approval packages counts.',
    error_unassign_approval_package:
      'An error occurred while unassigning approval packages.',
    error_fetch_additional_infos:
      'An error occurred while fetching additional informations.',
    error_fetch_assigned_approval_package:
      'An error occurred while fetching assigned approval packages.',
    error_fetch_user_approval_package_history:
      'An error occurred while fetching reviewed approval package history.',
    error_get_appointment_caseid:
      'An error occurred while fetching appointments by case id.',
    error_fetch_entity_status:
      'An error occur while fetching entity status lists.',
    error_update_entity_status: 'An error occur while update entity status.',
    error_invalid_snapshot_version:
      'A new submission has been made for this field. Please refresh the page, so you can review the latest submission.',

    expand_all_sections: 'Expand all sections',
    field_name: 'Field Name',
    field_value: 'Field Value',
    form_builder: 'Form Builder',
    generate_report: 'Generate Report',
    report_title: 'Report',
    generate_report_success:
      'The report is being generated and will be available as download soon',
    got_it: 'Got it',
    include_archived: 'Include archived',
    index_title: 'Index title*',
    name: 'Name',
    open: 'Open',
    wo_dashboard: 'Work Order Dashboard',
    workorders: 'Work Orders',
    workorder: 'Work Order',
    'installation-progress': 'Installation Progress',
    'approval-packages': 'Approval Packages',
    question_appointment_take_place: 'Did the appointment take place?*',
    re_submitted: 'Re Submitted',
    in_review: 'In Review',
    two_review: '2. Review',
    rejected: 'Rejected',
    roof_inside: 'Roof Inside',
    roof_shape: 'Roof shape?*',
    roof_shape_description:
      'depending on “Did the appointment take place?” value is “Yes”',
    save: 'Save',
    save_changes: 'Save Changes',
    search: 'Search',
    clear_filters: 'Remove Filters',
    subsection: 'Subsection',
    submitted: 'Submitted',
    status: 'Status',
    total: 'Total',
    today: 'Today',
    tomorrow: 'Tomorrow',
    work_types: 'Chapter',
    work_type: 'Work Type',
    warning: 'Warning',
    stopped: 'Stopped',
    active: 'Active',
    closed: 'Closed',
    case_id: 'Order number',
    customer_name: 'Customer Name',
    enter_customer_name_or_case_id: 'Enter customer Name or Order number',
    escalate_notify: 'Information and link copied to clipboard',
    close_appointment: 'Close Appointment',
    close_appointment_message:
      'Are you sure you want to close the appointment?',
    close_appointment_confirm: 'Yes, close',
    close_appointment_pending_forms_message:
      'Warning! There are still open or not approved fields. Are you sure you want to close the appointment?',
    reopen_appointment: 'Reopen Appointment',
    reopen_appointment_message:
      'Are you sure you want to reopen the appointment?',
    appointment_details: 'Appointment Details',
    no_submissions: 'No team submissions yet',
    montage_documents: 'Montage Documents',
    reset_selection: 'Reset selection',
    select_teams: 'Select Teams',
    preview_comments: 'Preview',
    additional_comments: 'Additional Comments',
    leave_a_comment: 'Please add a comment.',
    two_eyes_info: 'Field will be approved without second review',
    reason_code_label: 'Predefined Reason codes',
    reason_code_placeholder: 'Pick up a reason code',
    approver_label: 'Second review',
    approver_desc:
      'Click on "Confirm" if you agree with the first decision. Click on "Overwrite" if the first decision is wrong. Your decision will NOT be reviewed again and has to be final. Click on "Escalate" if you want to escalate to your supervisor.',
    approver_desc_2:
      'This field will be reviewed by a second person. You cannot make any changes until another QM reviewed it',
    deny_modal_title: 'Confirm overwrite',
    deny_modal_text: 'Are you sure?',
    deny_modal_desc:
      'The approval or rejection that you will send in the following will be the final decision and will be sent to the craftsperson without further QM review.',
    reopen_modal_title: 'Confirm Reopening',
    reopen_modal_desc:
      'Reopening means the last submission will need to be reviewed by the 4 eye principle again.  Please note that you cannot reverse this decision.',
    reopen_modal_2_desc:
      'Reopening means the last submission needs to be reviewed again. Please note that you cannot reverse this decision.',
    edit_images: 'Edit Images',
    close: 'Close',
    edit_images_confirm_close_message:
      'Are you sure you want to exit the editing mode? All changes will be lost',
    images_selected: 'Images selected',
    submit_selection: 'Submit selection',
    image_preview: 'Image preview',
    select_image: 'Select image',
    remove_image_from_selection: 'Remove image from selection',
    error: 'Error',
    error_message_required_with_choice:
      'If you chose a valid answer or a range number, the error message is required. Without the chosen correct answer it should be empty.',
    update_images: 'Update Images',
    loading_selected_images: 'Adding selected images',
    error_generic_post: 'An error occurred while submitting your request',
    zoom: 'Zoom',
    rotation: 'Rotation',
    apply_changes_and_select_image: 'Apply changes and select image',
    apply_changes: 'Apply changes',
    questions: 'Questions',
    include_auto_approval: 'Include auto approved fields',
    cancel_reviewing: 'Cancel reviewing',
    assign_next_package: 'Next package',
    review: 'Review',
    review_completed: 'Review Completed',
    select_all: 'Select all',
    select_images: 'Select images',
    approval_package_welcome:
      'Hi! Pending field approvals are waiting for you, join a queue to see them!',
    reviewed_packages: 'Reviewed packages',
    open_review_history: 'Open history',
    history: 'History',
    latest: 'Latest',
    assign_first_queue: 'Packages - First reviews',
    first_queue: 'First review',
    start_first_queue: 'Start first review',
    assign_second_queue: 'Packages - Second reviews',
    second_queue: 'Second review',
    start_second_queue: 'Start second review',
    skipped: 'skipped',
    inReview: 'in progress',
    'show-skipped-packages': 'Show skipped packages',
    packages_assigned_to_me: 'Packages assigned to me',
    all_todays_packages: 'That’s all your past items from today.',
    no_reviewed_packages_in_history: 'You have not reviewed any packages yet.',
    additional_infos: 'Additional information',
    incomplete_pakage_note:
      'You are already assigned to a package. Please continue.',
    your_assigned_package: 'Your assigned package',
    continue_package: 'Continue Package',
    package_completed_title: 'Reviews completed!',
    package_completed_message:
      'Good job! You can continue with the next package.',
    on_cancel_package_review:
      'You did not complete reviewing the package. Are you sure you want to unassign from reviewing the package?',
    skip_review: 'Yes, skip review',
    keep_reviewing: 'Keep reviewing',
    note: 'Note',
    notes: 'Notes',
    show_all_notes: 'Show all comments',
    add_a_note: 'Add a comment',
    add_new_note: 'Add new note',
    filter_by_sat: 'Filter by Work Item',
    work_items: 'Work Items',
    edited: 'edited',
    started: 'Started',
    paused: 'Paused',
    follow_up: 'Follow-Up appointment',
    at_risk: 'Appointment at risk',
    at_risk_tooltip:
      'Appointment at risk. Overlap with previous appointment or scheduled end date after Friday 12 pm.',
    parallel_appointment_tooltip:
      'This appointment has a parallel appointment.',
    last_appointment_tooltip: 'Installation will be finished within this week',
    last_appointments: 'Finishing appointments',
    priority_customer: 'Priority Customers',
    banner_text:
      'Planned Downtime for the migration to Salesforce: CraftOS will not be available from Tue 9th of May 8pm to Wed 10th 6am!',
    enter_key: 'Please enter a key',
    enter_description: 'Please enter a description',
    multiple_rejection:
      'This field has been rejected {timesRejected, number} times',

    //Detail drawer
    appointments: 'Appointments',
    all_appointments: 'All appointments',
    appointment_info: 'Appointment info',
    current_appointment: 'Current appointment',
    time_remaining: 'Time remaining',
    change_start_end: 'Change Start / End',
    change_team: 'Adjust Team',
    open_in_drawer: 'Open in Drawer',
    time_change_log: 'Time History',
    status_change_log: 'Status History',
    work_orders_details: 'Workorder Details',
    change_appointment_time: 'Change appointment time',
    appointment_time: 'Appointment Time',
    appointment_time_log: 'Appointment Time',
    time_log_error:
      'The data for the appointment time log could not be loaded.',
    check_time_log_input: 'Please enter at least one date value.',
    check_time_log_end_start_input:
      'The end date has to be later than the start date.',
    save_new_time_log_error:
      'The date changes could not be saved. Please try again!',
    save_note: 'Save note',
    customer_information: 'Customer information',
    customer_search: 'Customer Search',
    customer_name_order_number: 'Customer Name, Order number',
    phone_number: 'Phone number',
    confirm_status_change: 'Are you sure you want to change status?',
    confirm_delete_field: 'Are you sure you want to delete',
    yes: 'Yes',
    no: 'No',
    parallel_appointment: 'Parallel appointment',
    checkin_history: 'Check-in History',

    //Service Appointment status
    none: 'None',
    scheduled: 'Scheduled',
    dispatched: 'Dispatched',
    in_progress: 'In Progress',
    cannotComplete: 'Cannot Complete',
    completed: 'Completed',
    cancelled: 'Cancelled',

    //Appointment Type Editor
    provide_appointment_type_name: 'Please enter the Appointment Type name',
    provide_external_id: 'Please enter the External Id (SalesForce Worktype)',
    provide_file_label: 'Please enter a file label',
    provide_group_name: 'Please provide a group name',
    //SF Appoinbtment Type
    acdc: 'AC/DC',
    vorbau: 'Vorbau',
    fertigstellung: 'Fertigstellung',
    dc_acm_ac: 'DC/ACM/AC',
    dc_acm_ac_backlog: 'DC/ACM/AC Backlog',
    acm: 'ACM',
    acm_backlog: 'ACM Backlog',
    dc_acm: 'DC/ACM',
    dc_acm_backlog: 'DC/ACM Backlog',
    dc: 'DC',
    dc_backlog: 'DC Backlog',
    ac: 'AC',
    ac_backlog: 'AC Backlog',
    clarification_IA: 'Klärfall IA',
    clarification: 'Klärfall',
    ach: 'ACH',
    dch: 'DCH',
    hak: 'HAK',
    ach_dch: 'ACH/DCH',
    ach_hak: 'ACH/HAK',
    dch_hak: 'DCH/HAK',
    ach_dch_hak: 'ACH/DCH/HAK',

    ac_healing: 'ACH',
    //Work Order status
    wo_status: 'Work Order Status',
    new: 'New',
    in_progress_WO: 'In Progress',
    technical_cancellation: 'Tech Cancel',
    technical_cancellation_tooltip: 'Technical Cancellation',
    customer_cancellation: 'Customer Cancel',
    customer_cancellation_tooltip: 'Customer Cancellation',
    backlog: 'Backlog',
    wo_backlog: 'Backlog',
    installation_preparation: 'Preparation',
    installation_preparation_tooltip: 'Installation Preparation',
    completed_WO: 'Completed',
    completed_app: 'Completed',
    cancelled_WO: 'Cancelled',
    on_hold: 'On Hold',
    //WorkOrder Info Page
    job_details: 'Job Details',
    work_order: 'Work order',
    ac_healing_necessary: 'AC Healing necessary?',
    documentation: 'Documentation',
    more: 'More',
    less: 'Less',
    edit_appointments: 'Edit Appointments',
    MVT: 'IPA',
    'Sep. Zählertausch': 'Sep. ME',
    //Saving Filters
    manage_filters: 'Manage Filters',
    create_new_preset: 'Create new filter preset',
    saved_filters: 'Saved filters',
    no_saved_filters: 'No Saved Filters',
    no_selected_filters: 'No filters are selected',
    no_filter_name_error: 'Please give your filter combination a name.',
    filter_name: 'Filter name',
    title: 'Title',
    your_filters: 'Your Filters',
    filter_group: 'Filter group',
    branch: 'Branch',
    team_selection: 'Team Selection',
    project_leads: 'Project Leads',
    filter_presets: 'Filter Presets',
    saved_presets: 'Saved Presets',
    error_invalid_filter: 'The selected filter parameters are no longer valid.',
    remarks: 'Remarks',
    //QM statuses
    uk_status: 'UK Status',
    dc_status: 'DC Status',
    ac_status: 'AC Status',
    acm_status: 'AC-M Status',
    uk_open: 'UK Open',
    uk_submitted: 'UK Submitted',
    uk_approved: 'UK Approved',
    uk_rejected: 'UK Rejected',
    dc_open: 'DC Open',
    dc_submitted: 'DC Submitted',
    dc_approved: 'DC Approved',
    dc_rejected: 'DC Rejected',
    ac_open: 'Open',
    ac_submitted: 'Submitted',
    ac_approved: 'Approved',
    ac_rejected: 'Rejected',
    acm_open: 'Open',
    acm_submitted: 'Submitted',
    acm_approved: 'Approved',
    acm_rejected: 'Rejected',
    acm_video_status: 'ACM Abnahme Status',
    acm_video_open: 'ACM Abnahme Open',
    acm_video_submitted: 'ACM Abnahme Submitted',
    acm_video_approved: 'ACM Abnahme Approved',
    acm_video_rejected: 'ACM Abnahme Rejected',

    week: 'Week',
    done: 'Done',
    scheduled_appointments: 'Scheduled Appointments',
    active_appointments: 'Active Appointments',
    delayed_start_appointments: 'Delayed Start Appointments',
    delayed_end_appointments: 'Delayed End Appointments',
    delayed_appointments: 'Delayed Appointments',
    appointments_count: 'Appointments Count',
    completion_goal: 'Completion goal',
    completion_goal_on: 'Completion goal on',
    start_date_modified: 'Start date changed',
    end_date_modified: 'End date changed',
    appointment_type: 'Installation',
    appointment_type_filter: 'Appointment Type',
    appointmentTypeFilter: 'Type',
    workorder_type_filter: 'Work Order Type',
    montage: 'Installation',
    'Customer Service': 'Customer Service',
    wo: 'WO',
    DMG: 'DMG',
    HI: 'HI',
    customer_service: 'Customer Service',
    home_improvement: 'Home Improvement',
    damage_case: 'Damage Case',
    with_meter_exchange: 'Meter exchange',
    with_wallbox: 'Wallbox',
    with_meter_cabinet_exchange: 'With meter cabinet exchange',
    pl_approval_pending: 'PL approval pending',
    approval: 'Approval',
    qm_rejection: 'QM rejection',
    question: 'Question',
    long_wait_time: 'Long QM wait time',
    punctuality: 'Punctuality',
    started_late: 'Started late',
    overdue: 'Overdue',
    documents: 'Documents',
    view_route: 'View route',
    checked_in_users: 'Checked In Users',
    //delayed appointments
    delayed_reason_update: 'Update Delay Reason',
    choose_delayed_reason: 'Choose a delay reason',
    change_delay_reason: 'Change delay reason',
    select_delay_reason: 'Select a delay reason',
    cannot_change_status_to_scheduled: 'Cannot change status to "Scheduled".',
    cannot_change_status_to_completed: 'Cannot change status to "Completed".',
    cannot_change_status_to_in_progress:
      'Cannot change status to "in Progress".',
    delay_reason_history: 'Delay Reason History',
    status_change_in_progress_message:
      'The status will change to “In Progress” automatically, once the first check-in to this appointment has happened.',
    status_change_completed_message:
      'The status will change to “Completed” automatically, as soon as the following conditions are met:',
    status_change_completed_first_condition:
      'All packages have been approved by QM',
    status_change_completed_second_condition:
      'If the appointment has a delay, the delay reason has been given',
    status_change_completed_third_condition:
      'If the finishing work item is fully documented and approved by QM',
    //form builder
    field_type: 'Field Type',
    display_as: 'Display as',
    formbuilder_field_name: 'Field name',
    instruction_text: 'Instruction text',
    instruction_image: 'Instruction image',
    instruction_images: 'Instruction images',
    protocol_text: 'Protocol text',
    display_protocol: 'Display Protocol',
    required: 'Required',
    read_only: 'Read-only',
    approval_required: 'Approval required',
    approval_type: 'Approval type',
    depends_on: 'Depends on',
    answer: 'Answer',
    answers: 'Answers',
    default: 'Default',
    required_answers: 'Required answers',
    required_images: 'Required images',
    form_name: 'Form name',
    new_form: 'New Form',
    edit_form: 'Edit Form',
    prefilled_value: 'Pre-filled value',
    anyone: 'Anyone',
    write_to: 'Write to',
    add_dependency: 'Add dependency',
    valid_answers: 'Valid answers',
    error_message: 'Error Message',
    checkbox: 'Checkbox',
    delete: 'Delete',
    publish: 'Publish',
    publish_confirm: 'Do you want to publish this form?',
    formbuilder_work_type: 'Work Type',
    type: 'Type',
    legal_text: 'Legal text',
    add_answer: 'Add Answer',
    range: 'Range',
    published_version: 'Published version',
    comment_required: 'Comment required',
    delete_field: 'Delete field',

    choice: 'Choice',
    radio: 'Radio',
    section: 'Section',
    input: 'Input',
    other: 'Other',
    numeric: 'Numeric',
    instruction: 'Instruction',
    date: 'Date',
    signature: 'Signature',

    form_fields: 'Form fields',
    fields: 'Fields',
    new_field: 'New Field',
    selected_field: 'Selected Field',
    edit: 'Edit',
    cancel_edit_mode: 'Cancel Editing',
    published: 'Published',
    draft: 'Draft',
    revision: 'Revision',
    created: 'Created',
    updated: 'Updated',
    update: 'Update',
    add_new_form: 'Add new form',
    page: 'Page',
    updated_by: 'Updated by',

    delete_field_check: 'Are you sure you want to delete this field?',
    delete_field_warning:
      'This field is a section, by deleting it you will lose also all his children!',
    answer_field_warning:
      'Save the answer first, to be able to set it as default.',
    delete_attachment_warning:
      'Are you sure you want to delete this attachment? The uploaded file will be lost.',
    appointment_is_delayed: 'This appointment has been delayed.',
    cannot_complete_reason: '”Cannot Complete” reason',
    choose_cannot_complete_reason:
      'Please provide the status change reason from the list:',
    select_reason: 'Select an option',
    appointment_status_update: 'Update Appointment Status',
    update_appointment_status: 'Would you like to update appointment status?',
    workorder_status_update: 'Update Work Order Status',
    update_workorder_status: 'Would you like to update work order status?',

    //Form Builder Tooltips:
    field_type_tooltip:
      'This field determines the query and input possibility for the user.',
    field_name_tooltip:
      'Name of the Field Type. (Visible in the form and in the app)',
    display_as_tooltip: 'Defines or restricts the input possibilities.',
    instruction_text_tooltip:
      'Text in which the users see their task/acceptance criteria conditions for the respective test point.',
    instruction_image_tooltip:
      'Image which is displayed below the text for further explanation.',
    protocol_text_tooltip:
      'Checkpoint display name in the log. (Not visible in the app)',
    required_tooltip: 'Makes the checkpoint mandatory.',
    read_only_tooltip:
      'Removes the ability to edit the checkpoint. (Read only)',
    approval_required_tooltip:
      'The checkpoint must be checked and is not released automatically.',
    valid_answers_tooltip:
      "Automatically rejects the checkpoint on the wrong answer without QM's consent.",
    approval_type_tooltip:
      'Determines the number of people required for the release.',
    depends_on_tooltip:
      'Causes a checkpoint to appear only if a corresponding answer has been selected beforehand.',
    answers_tooltip: 'Answer options',
    default_tooltip: 'The answer is selected as Default.',
    required_answers_tooltip: 'Number of answers needed.',
    required_images_tooltip: 'Number of pictures needed.',
    error_message_tooltip:
      'Error message that the user gets if the wrong answer has been selected. (Automatic rejection)',
    range_tooltip:
      'Automatic check at Numeric. Specifies the interval in which the number entries can be in, in order to be valid.',
    metadata_tooltip:
      'The metadata is used to create dependencies between checkpoints in different forms.',
    depend_on_tooltip:
      'The checkpoint is only displayed if the metadata variable has the value entered.',
    default_value_key_tooltip:
      'This can be used to pre-fill the checkpoint. The answer is then displayed as "System Integration". This option requires the checkpoint to always be set to read-only.',
    write_to_tooltip:
      'The meta data field to which the value will be written to.',

    //recycled images warning messages:

    recycled_picture: 'Recycled Picture',
    ExternalImage_popup:
      'Attention, at least one of the pictures in this answer is either a screenshot or was sent to the user from a different device.',
    ExternalImage_baner:
      'Attention, at least one of the pictures in this answer is either a screenshot or was sent to the user from a different device.',
    OutdatedImage_popup:
      'Attention, at least one of the pictures in this answer was not taken during the appointment.',
    OutdatedImage_baner:
      'Attention, at least one of the pictures in this answer was not taken during the appointment.',

    //weekdays
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',

    //Protocol
    Protocol: 'Protocol',
    'Show All Submissions': 'Show All Submissions',
    Print: 'Print',
    'Submission {answerNumber}': 'Submission {answerNumber}',
    New: 'New',
    Old: 'Old',
  },
};

export default english;
