import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './pages/signin';
import SignUp from './pages/signup';
import InstallationProgress from './pages/progress/installation';
import FormFieldAnswer from './pages/formFieldAnswer';
import FormInstance from './pages/formInstance';
import * as routes from './router/routes';
import ApprovalPackages from './pages/approvalPackages';
import Appointment from './pages/workorders';
import WorkOrder from './pages/workorder';
import FormBuilderTable from './pages/formBuilderTable';
import FormBuilderDetail from './pages/formBuilderDetail';
import AppointmentTypesTable from './pages/appointmentTypes/appointmentTypesTable';
import AppointmentTypeDetail from './pages/appointmentTypes/appointmentTypeDetail';
import GenericProgress from './pages/progress';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { IFeatures } from './store/rolesAndPrivileges';
import FallbackPage from './pages/noAccess';
import { ProtocolPage } from './pages/protocol';

const Routing: React.FC = () => {
  const privilegesState = useSelector(
    (state: RootState) => state.authentication?.privileges ?? {}
  );

  return (
    <Routes>
      {privilegesState[IFeatures.formBuilderAccess] && [
        <Route
          key="Fulfilment:FormBuilder.Access.List"
          path={routes.formBuilderTable.path}
          element={<FormBuilderTable />}
        />,
        <Route
          key="Fulfilment:FormBuilder.Access.Detail"
          path={routes.formBuilderDetail.path}
          element={<FormBuilderDetail />}
        />,
      ]}

      {privilegesState[IFeatures.SuperAdminAccess] && [
        <Route
          key="Fulfilment:AppointmentTypes.Access.List"
          path={routes.appointmentTypesTable.path}
          element={<AppointmentTypesTable />}
        />,
        <Route
          key="Fulfilment:AppointmentTypes.Access.Detail"
          path={routes.appointmentTypeDetail.path}
          element={<AppointmentTypeDetail />}
        />,
      ]}

      {privilegesState[IFeatures.progressAccess] && [
        <Route
          key="Fulfilment:Progress:Access:Installation"
          path={routes.installtionProgressTable.path}
          element={<InstallationProgress />}
        />,
        <Route
          key="Fulfilment:Progress:Access:Generic"
          path={routes.genericProgressTable.path}
          element={<GenericProgress />}
        />,
      ]}

      {privilegesState[IFeatures.appointmentRead] && [
        <Route
          key="Fulfilment:Workorder:Access"
          path={routes.workorder.path}
          element={<WorkOrder />}
        />,
        <Route
          key="Fulfilment:Workorder:Access"
          path={routes.workOrders.path}
          element={<Appointment />}
        />,
        <Route
          key="Fulfilment:AppointmentDetails:Access"
          path={routes.appointmentsDetails.path}
          element={<FormFieldAnswer />}
        />,
        <Route
          key="Fulfilment:FieldDetails:Access"
          path={routes.workType.path}
          element={<FormInstance />}
        />,
        <Route
          key="Fulfilment:Workorder:Access"
          path={routes.protocol.path}
          element={<ProtocolPage />}
        />,
      ]}

      {privilegesState[IFeatures.approvalPackagesAccess] &&
        privilegesState[IFeatures.appointmentRead] && (
          <Route
            path={routes.workTypeAssignment.path}
            element={<FormInstance />}
          >
            <Route
              path={routes.workTypeAssignment.pathField}
              element={<FormInstance />}
            />
          </Route>
        )}

      {privilegesState[IFeatures.approvalPackagesAccess] && (
        <Route
          path={routes.approvalPackages.path}
          element={<ApprovalPackages />}
        />
      )}

      <Route path={routes.noAccess.path} element={<FallbackPage />} />

      <Route path={routes.login.signin} element={<SignIn />} />
      <Route path={routes.login.signup} element={<SignUp />} />

      <Route
        path="/*"
        element={
          <Navigate
            replace
            to={
              privilegesState[IFeatures.appointmentRead]
                ? routes.workOrders.path
                : routes.noAccess.path
            }
          />
        }
      />
    </Routes>
  );
};

export default Routing;
