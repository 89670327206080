import { AnyAction } from 'redux';
import {
  appointmentsTableTypes,
  AppointmentsTableRequestType,
  appointmentsTeamsTypes,
  appointmentsDetailsTypes,
} from '../../constants/types';
import ApiRepository from '../../services/api/apiRepository';
import ReportService from '../../services/report-service/reportService';
import { store } from '../../store';

const actions = {
  getTeamsData() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: appointmentsTeamsTypes.GET_APPOINTMENTS_TEAMS_DATA,
          payload: await ApiRepository.getTeamsData(),
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsTableTypes.GET_APPOINTMENTS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  setTeamsLoading(loading: boolean) {
    return {
      type: appointmentsTeamsTypes.SET_LOADING,
      payload: loading,
    };
  },

  getAppointmentsData({
    endDate,
    pageNumber,
    pageSize,
    startDate,
    status,
    appointmentStatus,
    caseId,
    externalCaseId,
    customer,
    team,
    questions,
  }: AppointmentsTableRequestType) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const asyncResp = await ApiRepository.getAppointmentsData({
          endDate,
          pageNumber,
          pageSize,
          startDate,
          status,
          appointmentStatus,
          caseId,
          externalCaseId,
          customer,
          team,
          questions,
        });
        dispatch({
          type: appointmentsTableTypes.GET_APPOINTMENTS_DATA,
          payload: asyncResp,
        });
      } catch (err: any) {
        dispatch({
          type: appointmentsTableTypes.GET_APPOINTMENTS_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  generateReport(externalId: string, id: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: appointmentsTableTypes.SET_GENERATE_REPORT_ID,
          payload: id,
        });
        await ReportService.generateReport(
          store.getState().authentication.token!,
          id,
          externalId
        );
      } catch (err: any) {
        dispatch({
          type: appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL,
          payload: err,
        });
      } finally {
        dispatch({
          type: appointmentsTableTypes.SET_GENERATE_REPORT_ID,
          payload: '',
        });
      }
    };
  },

  setDefaultPagination() {
    return {
      type: appointmentsTableTypes.SET_DEFAULT_PAGINATION,
    };
  },

  setLoading(loading: boolean) {
    return {
      type: appointmentsTableTypes.SET_LOADING,
      payload: loading,
    };
  },

  resetAppointmentsTableError() {
    return {
      type: appointmentsTableTypes.GET_APPOINTMENTS_DATA_FAIL,
      payload: null,
    };
  },

  setReportGenerating(reportId: string) {
    return {
      type: appointmentsTableTypes.SET_GENERATE_REPORT_ID,
      payload: reportId,
    };
  },
};

export default actions;
