import { Typography } from 'antd';
import cName from 'classnames';
import styles from './ProtocolDocument.module.scss';
import { FieldValue } from './FieldValue';

interface ProtcolFieldProps {
  field: any;
  showAllSubmissions: boolean;
  isRoot?: boolean;
}

const { Title } = Typography;

const ProtocolField = ({
  field,
  showAllSubmissions,
  isRoot = false,
}: ProtcolFieldProps) => (
  <div
    className={cName({
      [styles.fieldContainer]: true,
      [styles.rootField]: isRoot,
    })}
  >
    <Title id={field?.formFieldId} level={5}>
      {field?.protocolText || field?.label}
    </Title>
    <FieldValue question={field} showAllSubmissions={showAllSubmissions} />
  </div>
);

export default ProtocolField;
