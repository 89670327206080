import { FormInstance, FormItemProps } from 'antd';
import { IRoles } from '../../store/rolesAndPrivileges';
import { SUPPORTED_LANGUAGES } from '../../constants/types';

export enum FormBuilderDetailActions {
  FETCH_FORM_START = 'FETCH_FORM_START',
  FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS',
  FETCH_FORM_FAILED = 'FETCH_FORM_FAILED',
  FETCH_PUBLISHED_FORM_START = 'FETCH_PUBLISHED_FORM_START',
  FETCH_PUBLISHED_FORM_SUCCESS = 'FETCH_PUBLISHED_FORM_SUCCESS',
  FETCH_PUBLISHED_FORM_FAILED = 'FETCH_PUBLISHED_FORM_FAILED',
  FETCH_METADATA_SUCCESS = 'FETCH_METADATA_SUCCESS',
  FETCH_METADATA_FAILED = 'FETCH_METADATA_FAILED',
  RESET_STATE = 'RESET_STATE',
}

export interface FormDetailParams {
  formId?: string;
  id?: string;
  fieldId?: string;
}

export type Priority = 'Default' | 'Priority';

export type MultiLanguageFieldItem = {
  [key in SUPPORTED_LANGUAGES]?: string;
} & { default?: SUPPORTED_LANGUAGES };

export interface FormType {
  formId: string;
  status: string;
  revision: number;
  name: any;
  description: any;
  publishedAt: string;
  formStatus: string;
  formHandle: string;
  workType: WorkType;
  children: FormChild[];
  languages: SUPPORTED_LANGUAGES[];
  defaultLanguage: SUPPORTED_LANGUAGES;
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: AtedBy;
  updatedBy: AtedBy;
  priority: Priority;
  doNotSendToQM?: boolean;
  allowedRolesForApproval?: IRoles[];
  hasValidChildren: boolean;
}

export type FormChildItem =
  | string
  | FormFieldType
  | DisplayAs
  | Answer[]
  | Count
  | boolean
  | Metadata
  | ApprovalType
  | DependsOn[]
  | Attachment[]
  | FormChild[]
  | Metadata
  | undefined
  | Validation
  | MultiLanguageFieldItem;

export type FormChild = {
  [key: string]: FormChildItem;
  id: string;
  type: FormFieldType;
  displayAs: DisplayAs;
  label: any;
  content?: any;
  answers?: Answer[];
  requiredAnswerCount?: Count;
  approvalRequired: boolean;
  required: boolean;
  additionalFieldData?: string;
  defaultValue?: string;
  metadata?: Metadata;
  approvalType: ApprovalType;
  dependsOn?: DependsOn[];
  photoCount?: Count;
  attachments?: Attachment[];
  legalText?: any;
  children?: FormChild[];
  commentRequired: boolean;
  isPrefill: boolean;
  validation?: Validation;
  isValid?: boolean;
  level?: any;
};

export type Validation = {
  message: MultiLanguageFieldItem;
  conditions: Condition[];
};
export type Condition = {
  value: string | number;
  operator: string;
};

export interface Answer {
  id: string;
  label: any;
  description?: any;
}

export enum ApprovalType {
  Default = 'Default',
  SingleApprover = 'SingleApprover',
}

export enum AttachmentType {
  Link = 'Link',
  Image = 'Image',
  File = 'File',
}

export interface Attachment {
  type: AttachmentType;
  label: MultiLanguageFieldItem;
  uri: string;
}

export interface DependsOn {
  fieldId: string;
  answerId: string;
  inverted: boolean;
}

export enum DisplayAs {
  Buttons = 'Buttons',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
}

export enum DisplayAsInput {
  Other = 'Other',
  QR = 'QR',
  Numeric = 'Numeric',
}

export interface Metadata {
  dependOn: { [key: string]: string };
  defaultValueKey: string;
  writeTo: string[];
}

export interface Count {
  min: number;
  max: number;
}

export enum FormFieldType {
  Choice = 'Choice',
  ImageUpload = 'ImageUpload',
  Input = 'Input',
  Section = 'Section',
  Instruction = 'Instruction',
  Date = 'Date',
  Signature = 'Signature',
  VideoUpload = 'VideoUpload',
}

export interface AtedBy {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}

export interface WorkType {
  id: string;
  name: string;
  description: string;
}

export interface TreeDataNode {
  key: string;
  title: string;
  isValid: boolean;
  level: number;
  children: TreeDataNode[];
}

export interface Option {
  label?: string;
  value: string | number;
}

export interface FieldItemProps extends FormItemProps {
  editMode?: boolean;
  selectedNodeId?: string;
  fieldChanges?: FieldChanges | null;
  language?: SUPPORTED_LANGUAGES;
  defaultLanguage?: SUPPORTED_LANGUAGES;
  isPublishedItem?: boolean;
  isNewField?: boolean;
  formInstance?: FormInstance;
  isFieldLocked?: boolean;
}

export type FlatList = Map<string, FormChild>;

export interface EditMode {
  treeMenu: boolean;
  formDetail: boolean;
}

export enum FormDetailModals {
  newField = 'newField',
  deleteField = 'deleteField',
  editForm = 'editForm',
}
export interface FormDetailModalsState {
  newField: boolean;
  deleteField: boolean;
  editForm: boolean;
}

export interface FieldChangesItem {
  hasChanges: boolean;
  publishedItem: FormChildItem;
}

export type FieldChanges = {
  [key: string]: FieldChangesItem;
};
