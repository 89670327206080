import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authenticationReducer } from './authenticationReducer';
import appointmentsDetailsReducerfrom from '../pages/formFieldAnswer/reducer';
import workTypeReducer from '../pages/formInstance/reducer';
import changeLanguageReducer from '../components/changeLanguage/reducer';

import { jwt } from './jwt';
import approvalPackageReducer from '../pages/approvalPackages/reducer';
import {
  appointmentsTableReducer,
  appointmentsTeamsReducer,
} from '../pages/workorders/reducer';
import { formBuilderDetailReducer } from '../pages/formBuilderDetail/reducer';
import { appInfoBannerReducer } from '../components/appInfoBanner/reducer';
import {
  progressTableReducer,
  progressTeamsReducer,
} from '../pages/progress/reducer';
import { detailDrawerReducer } from '../pages/progress/detail-drawer/reducer';
import {
  formBuilderTableReducer,
  metaDataReducer,
} from '../pages/formBuilderTable/reducer';
import { appointmentTypesReducer } from '../pages/appointmentTypes/reducer';
import { timeLogReducer } from '../pages/progress/time-log-popover/reducer';
import { checkedInUsersReducer } from '../pages/progress/checked-in-users-popover/reducer';
import { statusChangeLogReducer } from '../pages/progress/status-log-history/reducer';
import { delayReasonLogReducer } from '../pages/progress/delay-log-history/reducer';
import { workOrderReducer } from '../pages/workorder/reducer';
import { protocolReducer } from '../pages/protocol/reducer';

const rootReducer = combineReducers({
  appInfoBannerData: appInfoBannerReducer,
  appointmentsTable: appointmentsTableReducer,
  appointmentsDetails: appointmentsDetailsReducerfrom,
  appointmentsTeams: appointmentsTeamsReducer,
  metaData: metaDataReducer,
  authentication: authenticationReducer,
  workType: workTypeReducer,
  changeLanguage: changeLanguageReducer,
  approvalPackage: approvalPackageReducer,
  formBuilderTable: formBuilderTableReducer,
  formBuilderDetail: formBuilderDetailReducer,
  appointmentTypes: appointmentTypesReducer,
  timeLogData: timeLogReducer,
  statusChangeLogData: statusChangeLogReducer,
  delayReasonLogData: delayReasonLogReducer,
  checkedInUsersData: checkedInUsersReducer,
  progressTable: progressTableReducer,
  progressTeams: progressTeamsReducer,
  detailDrawer: detailDrawerReducer,
  workOrder: workOrderReducer,
  protocol: protocolReducer,
});

const composeEnhancers = composeWithDevTools({});

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(jwt, thunk))
);

export default rootReducer;
