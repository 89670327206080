import { FormInstance } from 'antd';
import { SUPPORTED_LANGUAGES } from '../../constants/types';
import {
  TreeDataNode,
  FormChild,
  Option,
  FlatList,
  DependsOn,
  Answer,
  MultiLanguageFieldItem,
  FormType,
  Attachment,
} from './types';

export const MULTI_LANGUAGE_SUPPORTED_FORM_FIELDS: (keyof FormType)[] = [
  'name',
  'description',
];

export const MULTI_LANGUAGE_SUPPORTED_CHILD_FIELDS: (keyof FormChild)[] = [
  'label',
  'content',
  'additionalFieldData',
  'legalText',
];

export const generateFlatList = (
  children: FormChild[],
  map: FlatList = new Map(),
  level: number = 1
): FlatList => {
  if (!children || children.length === 0) return map;
  for (let child of children) {
    child.level = level;
    if (!map.has(child.id)) map.set(child.id, child);
    if (child.children) generateFlatList(child.children, map, level + 1);
  }
  return map;
};

export const generateTreeData = (
  children: FormChild[] | undefined,
  language: string,
  level: number = 1
): TreeDataNode[] => {
  const arr: TreeDataNode[] = [];
  if (!children || children.length === 0) return arr;
  for (let child of children) {
    arr.push({
      key: child.id,
      title: child.label[language],
      isValid: child.isValid ?? true,
      level: level,
      children: generateTreeData(child.children, language, level + 1),
    });
  }
  return arr;
};

export const reverseToFormChildren = (
  treeData: TreeDataNode[],
  flatList: FlatList
): FormChild[] => {
  return treeData.map((node) => {
    const newNode = flatList.get(node.key) as FormChild;
    if (newNode?.children)
      newNode.children = reverseToFormChildren(node.children, flatList);
    return newNode;
  });
};

export const addNewField = (
  children: FormChild[] | undefined,
  previousChildId: string | undefined,
  newField: FormChild
): FormChild[] => {
  const arr: FormChild[] = previousChildId ? [] : [newField];
  if (!children || children.length === 0) return arr;
  for (let child of children) {
    const children =
      child.type === 'Section'
        ? { children: addNewField(child.children, previousChildId, newField) }
        : {};

    arr.push({
      ...child,
      ...children,
    });
    if (child.id === previousChildId) {
      const children = newField.type === 'Section' ? { children: [] } : {};
      arr.push({ ...newField, ...children });
    }
  }
  return arr;
};

export const deleteField = (
  children: FormChild[],
  fieldToDeleteId: string
): FormChild[] => {
  return children.filter((child) => {
    if (child.children)
      child.children = deleteField(child.children, fieldToDeleteId);
    return child.id !== fieldToDeleteId;
  });
};

export const getAllIds = (
  children: FormChild[] | undefined,
  arr: string[] = []
): string[] => {
  if (!children || children.length === 0) return arr;
  for (let child of children) {
    arr.push(child.id);
    getAllIds(child.children, arr);
  }
  return arr;
};

export const getStatusColor = (status: string | undefined): string => {
  switch (status) {
    case 'Draft':
      return 'processing';
    case 'Published':
      return 'success';
    default:
      return '';
  }
};

export const getOptions = <T>(obj: T): Option[] => {
  // @ts-ignore
  return Object.entries(obj).map(([key, value]) => ({
    label: value,
    value: key,
  }));
};

export const getAboveChoices = (
  flatList: FlatList,
  selectedNodeId: string
): FormChild[] => {
  const choices = [];
  for (let field of flatList.values()) {
    if (field.id === selectedNodeId) break;
    if (field.type === 'Choice') choices.push(field);
  }
  return choices;
};

export const getFieldDependencies = (field: FormChild) => {
  const obj: { [key: string]: DependsOn } = {};
  if (field.dependsOn && field.dependsOn.length > 0) {
    for (let dependency of field.dependsOn) {
      obj[dependency.fieldId] = dependency;
    }
  }
  return obj;
};

export const hasDependant = (flatList: FlatList, id: string) => {
  for (let field of flatList.values()) {
    const fieldDependencies = field.dependsOn;
    if (fieldDependencies && fieldDependencies.length > 0) {
      for (let dependency of fieldDependencies) {
        if (dependency.fieldId === id) return true;
      }
    }
  }
  return false;
};

export const hasDependenciesUnderneath = (
  flatList: FlatList,
  dragNodeId: string,
  dropNodeId: string,
  dropPosition: number
) => {
  const draggedNode = flatList.get(dragNodeId);
  if (!draggedNode?.dependsOn) return false;
  let isUnder = dropPosition === -1 ? true : false;
  const fieldDependencies = getFieldDependencies(draggedNode);
  for (let field of flatList.values()) {
    if (isUnder) {
      if (fieldDependencies[field.id]) return true;
    }
    if (field.id === dropNodeId) isUnder = true;
  }
  return false;
};

export const hasDependantAbove = (
  flatList: FlatList,
  dragNodeId: string,
  dropNodeId: string
) => {
  let allAboveDependencies: { [key: string]: DependsOn } = {};
  for (let field of flatList.values()) {
    const fieldDependencies = field.dependsOn;
    if (fieldDependencies && fieldDependencies.length > 0) {
      for (let dependency of fieldDependencies) {
        allAboveDependencies[dependency.fieldId] = dependency;
      }
    }
    if (field.id === dropNodeId) break;
  }
  return allAboveDependencies[dragNodeId] ? true : false;
};

export const parseMetadataDependencies = (dependencies: {
  [key: string]: string;
}) => {
  return Object.entries(dependencies).map((dependency) => ({
    key: dependency[0],
    value: dependency[1],
  }));
};

//Transforming metadata dependecy array in object
export const normalizeMetadataDependencies = (
  dependencies: { key: string; value: string }[]
) => {
  if (dependencies.length === 0) return;
  const normalizedDeps: { [key: string]: string } = {};
  for (let dependency of dependencies) {
    if (!normalizedDeps[dependency.key])
      normalizedDeps[dependency.key] = dependency.value;
  }
  return normalizedDeps;
};

export const normalizeFormValues = (values: any): FormChild => {
  const newValues: { [key: string]: any } = {};
  for (let [key, value] of Object.entries<any>(values)) {
    switch (key) {
      case 'metadata':
        const metadata = {
          ...value,
          dependOn: normalizeMetadataDependencies(value.dependOn),
        };
        const areAllValuesUndefined = Object.values(metadata).every((v) => !v);
        newValues[key] = areAllValuesUndefined ? undefined : metadata;
        break;
      case 'answers':
      case 'dependsOn':
      case 'attachments':
        newValues[key] = value?.length ? value : undefined;
        break;
      case 'range':
        if (
          values?.approvalRequired === true &&
          (!!value?.min || value?.min === 0 || !!value.max || value?.max === 0)
        ) {
          if (
            values?.errorMessage !== undefined &&
            values?.errorMessage !== ''
          ) {
            const newConditions = [];
            if (!!value?.min || value?.min === 0) {
              newConditions.push({
                value: `${value.min}`,
                operator: 'GreaterThanOrEqual',
              });
            }
            if (!!value?.max || value?.max === 0) {
              newConditions.push({
                value: `${value.max}`,
                operator: 'LessThanOrEqual',
              });
            }
            const validation = {
              conditions: newConditions,
            };
            newValues.isValidate = true;
            newValues.validation = validation;
            newValues.validation.message = values.errorMessage;
          } else {
            throw new Error('Please reenter the Error Message');
          }
        } else {
          newValues.isValidate = false;
          newValues.validation = undefined;
        }
        break;
      case 'validAnswers':
        if (
          values?.approvalRequired === true &&
          !!value?.length &&
          value.length > 0
        ) {
          if (
            values?.errorMessage !== undefined &&
            values?.errorMessage !== ''
          ) {
            const validationChoice = {
              conditions: value.map((item: Answer, i: number) => {
                if (typeof value[i] === 'object') {
                  return value[i];
                } else {
                  return {
                    value: item,
                    operator: 'Match',
                  };
                }
              }),
            };
            newValues.isValidate = true;
            newValues.validation = validationChoice;
            newValues.validation.message = values.errorMessage;
          } else {
            throw new Error('Please reenter the Error Message');
          }
        } else {
          newValues.isValidate = false;
          newValues.validation = undefined;
        }
        break;
      case 'errorMessage':
        break;
      default:
        newValues[key] = value;
    }
  }
  return newValues as FormChild;
};

const updatePropLanguage = (
  prop: any,
  defaultLanguage: SUPPORTED_LANGUAGES,
  languages: SUPPORTED_LANGUAGES[]
): MultiLanguageFieldItem => {
  let newProp: MultiLanguageFieldItem = {};
  languages.forEach((lang: SUPPORTED_LANGUAGES) => {
    newProp[lang] = !!htmlToText(prop[lang]) ? prop[lang] : '';
  });

  newProp['default'] = defaultLanguage;
  return newProp;
};

export const updateFormLanguageProps = (
  form: FormType,
  defaultLanguage: SUPPORTED_LANGUAGES,
  languages: SUPPORTED_LANGUAGES[]
): FormType => {
  let newForm = {
    ...form,
    ...(MULTI_LANGUAGE_SUPPORTED_FORM_FIELDS.reduce(
      (accu: any, fieldName: keyof FormType) => ({
        ...accu,
        ...{
          [fieldName]: updatePropLanguage(
            form[fieldName],
            defaultLanguage,
            languages
          ),
        },
      }),
      {}
    ) as FormType),
  } as FormType;

  return newForm;
};

export const updateFlatList = (
  flatList: FlatList,
  defaultLanguage: SUPPORTED_LANGUAGES,
  languages: SUPPORTED_LANGUAGES[]
): FlatList => {
  const newFlatList: FlatList = new Map();
  flatList?.forEach((value: FormChild, key: string) =>
    newFlatList.set(
      key,
      updateFieldLanguageProps(value, defaultLanguage, languages)
    )
  );
  return newFlatList;
};

export const updateFieldLanguageProps = (
  field: FormChild,
  defaultLanguage: SUPPORTED_LANGUAGES,
  languages: SUPPORTED_LANGUAGES[]
): FormChild => {
  let newField: FormChild = {
    ...field,
    ...(MULTI_LANGUAGE_SUPPORTED_CHILD_FIELDS.reduce(
      (accu: any, fieldName: keyof FormChild) =>
        field[fieldName] && typeof field[fieldName] === 'object'
          ? {
              ...accu,
              ...{
                [fieldName]: updatePropLanguage(
                  field[fieldName],
                  defaultLanguage,
                  languages
                ),
              },
            }
          : { ...accu },
      {}
    ) as FormChild),
  };

  if (field.answers && field.answers.length) {
    field.answers.forEach((answer: Answer) => {
      if (answer && typeof answer.label === 'object') {
        answer.label = updatePropLanguage(
          answer.label,
          defaultLanguage,
          languages
        );
      }
    });
  }

  if (field.attachments && field.attachments.length) {
    field.attachments.forEach((attachment: Attachment) => {
      if (attachment && typeof attachment.label === 'object') {
        attachment.label = updatePropLanguage(
          attachment.label,
          defaultLanguage,
          languages
        );
      }
    });
  }

  if (
    field.validation &&
    field.validation.message &&
    typeof field.validation.message === 'object'
  ) {
    field.validation.message = updatePropLanguage(
      field.validation.message,
      defaultLanguage,
      languages
    );
  }

  return newField;
};

export const areItemsDifferent = (itemA: any, itemB: any) => {
  return JSON.stringify(itemA) !== JSON.stringify(itemB);
};

export const languageFieldModifiedRule = (
  fieldName: string,
  language: SUPPORTED_LANGUAGES,
  initialValue: MultiLanguageFieldItem
): any => ({ getFieldValue, isFieldTouched }: any) => ({
  validator: () => {
    let currentValue = getFieldValue(fieldName);
    let isNameModified = isFieldTouched(fieldName);

    if (isNameModified && currentValue[language!] === initialValue[language!]) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
});

export const languageFieldDefaultCheckRule = (
  fieldName: any,
  language: SUPPORTED_LANGUAGES,
  defaultLanguage: SUPPORTED_LANGUAGES
): any => ({ getFieldValue }: any) => ({
  validator: () => {
    let currentValue = getFieldValue(fieldName);
    if (
      language !== defaultLanguage &&
      currentValue &&
      !!currentValue[defaultLanguage] !== !!currentValue[language]
    ) {
      return Promise.reject(
        `Field value not configured correctly. Please refer to ${defaultLanguage?.toUpperCase()} language`
      );
    }
    return Promise.resolve();
  },
});

export const validateFormFieldProperty = (
  property: MultiLanguageFieldItem,
  defaultLanguage: SUPPORTED_LANGUAGES,
  languages: SUPPORTED_LANGUAGES[]
): boolean => {
  let result = true;

  if (result && property && property.default) {
    result = property.default === defaultLanguage;
    const defValue = property[defaultLanguage];
    languages.forEach((lang: SUPPORTED_LANGUAGES) => {
      if (lang !== defaultLanguage) {
        result =
          result && !!htmlToText(defValue) === !!htmlToText(property[lang]);
      }
    });
  }

  return result;
};

export const validateFormField = (
  formChild: FormChild,
  defaultLanguage: SUPPORTED_LANGUAGES,
  languages: SUPPORTED_LANGUAGES[]
): boolean => {
  let result = true;
  MULTI_LANGUAGE_SUPPORTED_CHILD_FIELDS.forEach(
    (fieldName: keyof FormChild) => {
      const label = formChild[fieldName] as MultiLanguageFieldItem;
      result =
        result && validateFormFieldProperty(label, defaultLanguage, languages);
    }
  );

  if (formChild.answers && formChild.answers.length) {
    formChild.answers.forEach((answer: Answer) => {
      if (answer && typeof answer.label === 'object') {
        const label = answer.label as MultiLanguageFieldItem;
        result =
          result &&
          validateFormFieldProperty(label, defaultLanguage, languages);
      }
    });
  }

  if (formChild.attachments && formChild.attachments.length) {
    formChild.attachments.forEach((attachment: Attachment) => {
      if (attachment && typeof attachment.label === 'object') {
        const label = attachment.label as MultiLanguageFieldItem;
        result =
          result &&
          validateFormFieldProperty(label, defaultLanguage, languages);
      }
    });
  }

  if (
    formChild.validation &&
    formChild.validation.message &&
    typeof formChild.validation.message === 'object'
  ) {
    const label = formChild.validation.message as MultiLanguageFieldItem;
    result =
      result && validateFormFieldProperty(label, defaultLanguage, languages);
  }

  return result;
};

export const validateFlatList = (
  flatList: FlatList,
  defaultLanguage: SUPPORTED_LANGUAGES,
  languages: SUPPORTED_LANGUAGES[]
): { hasValidChildren: boolean; flatList: FlatList } => {
  let hasValidChildren = true;
  flatList.forEach((value: FormChild, key: string) => {
    value.isValid = validateFormField(value, defaultLanguage, languages);
    hasValidChildren = hasValidChildren && value.isValid;
  });

  return {
    flatList,
    hasValidChildren,
  };
};

export const validateFormInstance = (formInstance: FormInstance) => {
  // TODO: find a way to call this after page render is completed
  setTimeout(() => {
    formInstance.validateFields();
  }, 100);
};

export const htmlToText = (html: any): string =>
  html ? html.replace(/<[^>]+>/g, '') : '';
