import { FC, useState, useEffect, useMemo } from 'react';
import {
  Select,
  Form,
  Row,
  Col,
  Button,
  Input,
  Empty,
  Modal,
  Upload,
  message,
  FormInstance,
  Image,
  Popconfirm,
  Typography,
  Tooltip,
  Space,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  FieldItemProps,
  Option,
  AttachmentType,
  Attachment,
  FormFieldType,
  MultiLanguageFieldItem,
} from '../types';
import { getOptions, languageFieldDefaultCheckRule } from '../utils';
import ApiRepository from '../../../services/api/apiRepository';
import styles from '../formBuilderDetail.module.scss';
import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';
import { SUPPORTED_LANGUAGES } from '../../../constants/types';
import DefaultTextView from './DefaultTextView';

const { Text } = Typography;
const { Dragger } = Upload;

interface Props {
  name: number;
  selectedLanguage: SUPPORTED_LANGUAGES;
  supportedLanguages: SUPPORTED_LANGUAGES[];
  defaultLanguage?: SUPPORTED_LANGUAGES;
  isFieldLocked?: boolean;
  restField: {};
  editMode?: boolean;
  attachmentOptions: Option[];
  form: FormInstance;
  remove: (name: number) => void;
}

const AttachmentRow: FC<Props> = ({
  name,
  restField,
  editMode,
  attachmentOptions,
  form,
  remove,
  defaultLanguage,
  selectedLanguage,
  supportedLanguages,
  isFieldLocked,
}) => {
  const attachments = Form.useWatch('attachments');
  const [showModal, setShowModal] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleModalCancel = () => {
    setFileList([]);
    setShowModal(false);
  };

  const uploadAttachment = async () => {
    const file = fileList[0];
    if (file) {
      setUploading(true);
      try {
        const formData = new FormData();
        formData.append(`file`, file);
        const res = await ApiRepository.uploadFile('form-builder', formData);
        setUploading(false);
        const url = res?.response[0]?.url;
        const attachments: Attachment[] = form.getFieldValue('attachments');
        const newAttachments = attachments.map((attachment, i) =>
          i === name ? { ...attachment, uri: url } : attachment
        );
        form.setFieldsValue({
          attachments: newAttachments,
        });
        message.success('File successfully uploaded!', 5);
        handleModalCancel();
      } catch (error: any) {
        setUploading(false);
        message.error(error?.message, 5);
      }
    }
  };

  const attachmentLabel: MultiLanguageFieldItem =
    attachments && attachments[name] && attachments[name]['label']
      ? attachments[name]['label']
      : null;

  return (
    <>
      {/* Attachment row component */}
      <Row justify="space-between" align="top">
        <Col span={7}>
          <Form.Item
            {...restField}
            name={[name, 'type']}
            label="Type"
            initialValue={'Image'}
            rules={[
              {
                required: true,
                message: 'Select a type',
              },
            ]}
          >
            <Select disabled options={attachmentOptions} />
          </Form.Item>
        </Col>
        <Col span={7}>
          {supportedLanguages?.map((lang: SUPPORTED_LANGUAGES) => [
            <Form.Item
              {...restField}
              key={`attachmentsLabel-${lang}`}
              name={[name, 'label', lang]}
              label={
                <Space direction="horizontal">
                  Label
                  {lang !== defaultLanguage && (
                    <DefaultTextView
                      defaultValue={
                        attachmentLabel &&
                        (attachmentLabel[defaultLanguage!] as string)
                      }
                    />
                  )}
                </Space>
              }
              hidden={lang !== selectedLanguage}
              rules={[
                languageFieldDefaultCheckRule(
                  ['attachments', name, 'label'],
                  lang!,
                  defaultLanguage!
                ),
              ]}
            >
              <Input
                disabled={!editMode}
                placeholder={
                  attachmentLabel &&
                  (attachmentLabel[defaultLanguage!] as string)
                }
              />
            </Form.Item>,
          ])}
        </Col>
        <Col span={7}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.attachments !== curValues.attachments
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                {...restField}
                name={[name, 'uri']}
                label="Url"
                tooltip={
                  <Image
                    width={200}
                    src={getFieldValue('attachments')[name]?.uri}
                  />
                }
                rules={[
                  {
                    required: true,
                    message: 'Upload a file',
                  },
                ]}
              >
                {/* If no url renders the upload button otherwise shows a disabled input with the url*/}
                {editMode && !getFieldValue('attachments')[name]?.uri ? (
                  <div style={{ width: '100%' }}>
                    <Button
                      type="primary"
                      icon={<UploadOutlined />}
                      disabled={isFieldLocked}
                      onClick={() => setShowModal(true)}
                      style={{ width: '100%' }}
                    >
                      Upload
                    </Button>
                  </div>
                ) : (
                  <Input
                    disabled={
                      !editMode ||
                      isFieldLocked ||
                      !!getFieldValue('attachments')[name]
                    }
                    placeholder="Url"
                  />
                )}
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        {editMode &&
          !isFieldLocked &&
          (attachments && attachments[name]?.uri ? (
            <Popconfirm
              title={
                <p style={{ minWidth: '300px' }}>
                  {translation('delete_attachment_warning')}
                </p>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={() => remove(name)}
            >
              <MinusCircleOutlined style={{ marginTop: '16px' }} />
            </Popconfirm>
          ) : (
            <MinusCircleOutlined
              onClick={() => remove(name)}
              style={{ marginTop: '16px' }}
            />
          ))}
      </Row>

      {/* Upload attachment modal component*/}
      <Modal
        title="Upload attachment file"
        open={showModal}
        onOk={uploadAttachment}
        onCancel={handleModalCancel}
        okText="Upload"
        okButtonProps={{
          disabled: fileList.length === 0,
          loading: uploading,
        }}
      >
        <Dragger
          fileList={fileList}
          onRemove={() => setFileList([])}
          beforeUpload={(file: any) => {
            setFileList([file]);
            return false;
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Modal>
    </>
  );
};

interface PropsFormItemAttachments extends FieldItemProps {
  selectedLanguage: SUPPORTED_LANGUAGES;
  supportedLanguages: SUPPORTED_LANGUAGES[];
}

const FormItemAttachments: FC<PropsFormItemAttachments> = ({
  editMode,
  fieldChanges,
  defaultLanguage,
  selectedLanguage,
  supportedLanguages,
  isFieldLocked,
}) => {
  const form = Form.useFormInstance();
  const selectedFieldType = Form.useWatch('type');
  const [attachmentOptions, setAttachmentOptions] = useState<Option[]>([]);

  const publishedItemValues = useMemo(
    () => ({
      attachments: fieldChanges?.attachments.publishedItem,
    }),
    [fieldChanges]
  );

  useEffect(() => {
    setAttachmentOptions(getOptions(AttachmentType));
  }, []);

  return (
    <Form.Item
      required={selectedFieldType === FormFieldType.Instruction}
      label={
        fieldChanges?.attachments.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('instruction_image_tooltip')}>
              <span> {translation('instruction_images')} </span>
            </Tooltip>

            <PublishedItem initialValues={publishedItemValues}>
              <FormItemAttachments
                isFieldLocked={isFieldLocked}
                selectedLanguage={selectedLanguage}
                supportedLanguages={supportedLanguages}
              />
            </PublishedItem>
          </Text>
        ) : (
          <Tooltip title={translation('instruction_image_tooltip')}>
            <span> {translation('instruction_images')} </span>
          </Tooltip>
        )
      }
    >
      <Form.List
        name="attachments"
        rules={[
          {
            validator: (_, items) => {
              if (
                selectedFieldType === FormFieldType.Instruction &&
                (!items || items.length === 0)
              ) {
                return Promise.reject(
                  'At least one Instruction image is required.'
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <div className={styles.nestContent}>
            {fields?.map(({ key, name, ...restField }) => (
              <AttachmentRow
                key={`attachmentRow-${key}`}
                name={name}
                restField={restField}
                editMode={editMode}
                form={form}
                attachmentOptions={attachmentOptions}
                remove={remove}
                isFieldLocked={isFieldLocked}
                selectedLanguage={selectedLanguage}
                defaultLanguage={defaultLanguage}
                supportedLanguages={supportedLanguages}
              />
            ))}
            {!editMode && fields.length === 0 && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No images"
              />
            )}

            {editMode && (
              <Button
                type="dashed"
                disabled={isFieldLocked}
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add image
              </Button>
            )}
          </div>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default FormItemAttachments;
