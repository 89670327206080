import { Typography } from 'antd';
import styles from './ProtocolDocument.module.scss';
import ProtocolSection from './ProtocolSection';
import ProtocolField from './ProtocolField';

interface ProtocolChapterProps {
  chapter: any;
  showAllSubmissions: boolean;
}

const { Title } = Typography;

const ProtocolChapter = ({
  chapter,
  showAllSubmissions,
}: ProtocolChapterProps) => (
  <div className={styles.chapterContainer}>
    <Title id={chapter?.formInstanceId ?? chapter?.formFieldId} level={3}>
      {chapter?.protocolText || chapter?.label}
    </Title>
    {chapter.questions.map((item: any, i: number) =>
      item.type === 'Section' ? (
        <ProtocolSection
          section={item}
          key={`section--${i}`}
          showAllSubmissions={showAllSubmissions}
        />
      ) : (
        <ProtocolField
          field={item}
          key={`section-field--${i}`}
          showAllSubmissions={showAllSubmissions}
        />
      )
    )}
  </div>
);

export default ProtocolChapter;
