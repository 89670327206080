import { FC, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tree, Spin, Button, Space, Typography, Badge } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  EditOutlined,
  SaveOutlined,
  ApartmentOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import * as routes from '../../../router/routes';
import { RootState } from '../../../store';
import {
  FormChild,
  TreeDataNode,
  EditMode,
  FormType,
  FlatList,
  FormDetailParams,
} from '../types';
import { getAllIds, getFieldDependencies, areItemsDifferent } from '../utils';
import styles from '../formBuilderDetail.module.scss';
import translation from '../../../i18n/translation';

const { Text } = Typography;

interface Props {
  selectedNode: FormChild | undefined;
  editMode: EditMode;
  treeData: TreeDataNode[];
  saveLoading: boolean;
  openNewFieldModal: () => void;
  handleEditMode: (key: keyof EditMode, mode: boolean) => void;
  handleDrop: (info: any) => void;
  handleSave: (form: FormType, updatedFlatList: FlatList) => void;
}

const TreeMenu: FC<Props> = ({
  selectedNode,
  editMode,
  treeData,
  saveLoading,
  openNewFieldModal,
  handleEditMode,
  handleDrop,
  handleSave,
}) => {
  const navigate = useNavigate();
  const { formId, id }: FormDetailParams = useParams();
  const loading = useSelector(
    (state: RootState) =>
      state.formBuilderDetail.loadingForm ||
      state.formBuilderDetail.loadingPublishedForm
  );
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);
  const flatList = useSelector(
    (state: RootState) => state.formBuilderDetail.flatList
  );
  const publishedFlatList = useSelector(
    (state: RootState) => state.formBuilderDetail.publishedFlatList
  );
  const [expandedKeys, setExpandedKeys] = useState<string[]>();
  const [showDependencies, setShowDependencies] = useState(false);
  const [showFieldLabels, setShowFieldLabels] = useState(false);

  const handleSelectRow = (nodeId: string) => {
    handleEditMode('formDetail', false);
    navigate(routes.formBuilderDetail.pathWithParams(formId!, id!, nodeId));
  };

  const isDependency = (id: string) =>
    selectedNode?.dependsOn?.find((dependency) => dependency.fieldId === id);

  const isDependant = (id: string) => {
    if (selectedNode) {
      const field = flatList?.get(id);
      let fieldDependencies;
      if (field?.dependsOn && field.dependsOn.length) {
        fieldDependencies = getFieldDependencies(field);
        return fieldDependencies[selectedNode?.id] ? true : false;
      }
    }
  };

  const isNewField = (nodeId: string) => {
    const field = publishedFlatList?.get(nodeId);
    return !field && form?.status !== 'Published' ? true : false;
  };

  useEffect(() => {
    if (form) {
      setExpandedKeys(getAllIds(form.children));
    }
  }, [form]);

  return (
    <div style={{ height: '100%' }}>
      {loading ? (
        <div className={styles.centeredContent}>
          <Spin size="large" className={styles.spin} />
        </div>
      ) : (
        expandedKeys && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <PageHeader
              title={translation('form_fields')}
              className={styles.siderContentHeader}
              extra={
                !editMode.treeMenu ? (
                  [
                    <Button
                      key="4"
                      type="primary"
                      disabled={editMode.formDetail}
                      onClick={openNewFieldModal}
                      icon={<PlusOutlined />}
                    ></Button>,
                    <Button
                      key="3"
                      type="default"
                      disabled={editMode.formDetail}
                      onClick={() => handleEditMode('treeMenu', true)}
                      icon={<EditOutlined />}
                    ></Button>,
                  ]
                ) : (
                  <Space>
                    <Button
                      loading={saveLoading}
                      type="primary"
                      onClick={() =>
                        form && flatList && handleSave(form, flatList)
                      }
                      icon={<SaveOutlined />}
                    >
                      {' '}
                      {translation('save')}
                    </Button>
                    <Button
                      disabled={saveLoading}
                      onClick={() => handleEditMode('treeMenu', false)}
                    >
                      {' '}
                      {translation('cancel_editing')}
                    </Button>
                  </Space>
                )
              }
            />
            <div className={`${styles.siderContentContainer}`}>
              <Tree
                draggable={editMode.treeMenu}
                treeData={treeData}
                defaultExpandedKeys={expandedKeys}
                selectedKeys={selectedNode?.id && ([selectedNode?.id] as any)}
                onSelect={(selectedKeys, info) =>
                  handleSelectRow(info.node.key as string)
                }
                onDrop={handleDrop}
                titleRender={(node) => (
                  <Text ellipsis>
                    <Text
                      ellipsis
                      type={!node.isValid ? 'danger' : undefined}
                      style={
                        showDependencies
                          ? {
                              border: isDependency(node.key)
                                ? '1px solid blue'
                                : isDependant(node.key)
                                ? '1px solid red'
                                : 'none',
                              padding: '0 2px',
                              borderRadius: '2px',
                            }
                          : undefined
                      }
                    >
                      {node.title}
                    </Text>
                    {showFieldLabels && (
                      <Text
                        italic
                        code
                        type="secondary"
                        style={{
                          fontSize: '0.8em',
                          marginLeft: '8px',
                        }}
                      >
                        {flatList?.get(node.key)?.type}
                      </Text>
                    )}
                    {isNewField(node.key) ? (
                      <Badge status="success" style={{ marginLeft: '8px' }} />
                    ) : (
                      publishedFlatList &&
                      areItemsDifferent(
                        flatList?.get(node.key),
                        publishedFlatList.get(node.key)
                      ) && (
                        <Badge status="warning" style={{ marginLeft: '8px' }} />
                      )
                    )}
                  </Text>
                )}
              />
            </div>
            <div className={styles.siderFooter}>
              <Button
                key="2"
                type={showFieldLabels ? 'primary' : 'default'}
                disabled={editMode.formDetail}
                onClick={() => setShowFieldLabels(!showFieldLabels)}
                icon={<ExclamationCircleOutlined />}
              />

              <Button
                key="1"
                type={showDependencies ? 'primary' : 'default'}
                disabled={editMode.formDetail}
                onClick={() => setShowDependencies(!showDependencies)}
                icon={<ApartmentOutlined />}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TreeMenu;
