import { Empty, Space, Typography } from 'antd';
import styles from './documents.module.scss';
import { FileMTWIcon } from '../../../../components/icons/fileMTW';
import { config } from '../../../../config';
import { MTWorkOrderParams } from '../..';
import { useParams } from 'react-router-dom';
const { Text, Link } = Typography;

interface DocumentsProps {
  filesInfo?: any[];
  customerName?: string;
}

const WODocuments: React.FC<DocumentsProps> = ({
  filesInfo = [],
  customerName,
}) => {
  const params: MTWorkOrderParams = useParams();

  return (
    <>
      {params?.appointmentTypeName === 'MVT' && filesInfo?.length === 0 && (
        <Empty className={styles.iconCentered} description={false} />
      )}
      <Space
        style={{
          maxWidth: '100%',
          overflowX: 'auto',
          padding: '8px 8px 24px 0',
        }}
      >
        {params?.appointmentTypeName !== 'MVT' && (
          <Link
            href={`${config.buildabilityUrl}/${params?.externalCaseId}`}
            target="_blank"
            key="buildability-link"
          >
            <div key="buildability-link-div" className={styles.fileWrapper}>
              <FileMTWIcon />
              <Text style={{ maxWidth: '120px' }} ellipsis>
                MVT Protokoll {customerName}
              </Text>
            </div>
          </Link>
        )}
        {filesInfo?.length > 0 &&
          filesInfo?.map((file: any, i: number) => (
            <Link href={file.url} target="_blank" key={`file-link-${i}`}>
              <div
                key={`${file?.fileName}-${i}`}
                className={styles.fileWrapper}
              >
                <FileMTWIcon />
                <Text style={{ maxWidth: '120px' }} ellipsis>
                  {file?.fileName}
                </Text>
              </div>
            </Link>
          ))}
      </Space>
    </>
  );
};

export default WODocuments;
