import { useCallback, useEffect, useRef, useState } from 'react';
import PageLayout from '../../components/appLayout';
import { Content } from 'antd/lib/layout/layout';
import {
  Space,
  Layout,
  Tabs,
  Typography,
  Button,
  Select,
  Spin,
  Progress,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import styles from './workorder.module.scss';
import {
  FileTextOutlined,
  MessageOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import CustomerInformation from './components/customerInformation';
import MTWorkOrderActions from './actions';
import JobDetails from './components/jobDetails';
import { DiplomatMTWIcon } from '../../components/icons';
import AppointmentCard from './components/appointmentCard';
import NotesLog from './components/notes';
import AppointmentStatusTag from '../../components/appointmentStatusTag';
import {
  CS_appointmentType,
  IAppointment,
  IAppointmentStatus,
  IAppointmentStatusType,
  IAppointmentTypeSalesForce,
} from '../../constants/types';
import WODocuments from './components/documents';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GroupProgress from './components/groupProgress';
import { SAT_FILTER_CONSTANT, STATUS_FILTER_CONSTANT } from './types';
import translation from '../../i18n/translation';
import * as routes from '../../router/routes';
import { config } from '../../config';
import BreadcrumNavigation from '../../components/breadcrumNavigation';
import { LinkMTWIcon } from '../../components/icons/linkMTW';
const { Text, Link } = Typography;

const WO_STATUSES = [
  IAppointmentStatus.new,
  IAppointmentStatus.inProgress,
  IAppointmentStatus.technicalCancellation,
  IAppointmentStatus.customerCancellation,
  IAppointmentStatus.backlog,
  IAppointmentStatus.completed,
];

export interface MTWorkOrderParams {
  page?: string;
  externalCaseId?: string;
  appointmentTypeName?: string;
}

interface IChangeUrl {
  status?: string;
  workItem?: string;
  sort?: string;
}

const WorkOrder: React.FC = () => {
  const params: MTWorkOrderParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const containerRef = useRef(null);

  const workOrderState = useSelector((state: RootState) => state.workOrder);

  const getQueryString = useCallback((string: string | null): IChangeUrl => {
    if (!string) return {};
    return string
      .slice(1)
      .split('&')
      .reduce((total: object, current: string) => {
        const res = current.split('=');
        return {
          ...total,
          [res[0]]: res[1],
        };
      }, {});
  }, []);

  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>(
    getQueryString(location.search)?.status?.split(',') ?? []
  );
  const [selectedWorkItemFilters, setSelectedWorkItemFilters] = useState<
    string[]
  >(getQueryString(location.search)?.workItem?.split(',') ?? []);
  const [appointmentStatusCount, setAppointmentStatusCount] = useState<any>({});
  const [filteredAppointments, setFilteredAppointments] = useState<
    IAppointment[]
  >([]);
  const [generateLinkAppointment, setGenerateLinkAppointment] = useState<
    IAppointment | undefined
  >();

  const fetchWOPageData = () => {
    dispatch(MTWorkOrderActions.setLoading(true));
    dispatch(
      MTWorkOrderActions.getWorkOrderData(
        params?.externalCaseId ?? '',
        params?.appointmentTypeName ?? ''
      )
    );
  };

  useEffect(() => {
    if (
      !workOrderState?.loading &&
      params?.externalCaseId &&
      params?.appointmentTypeName
    ) {
      fetchWOPageData();
    }
    return () => {
      dispatch(MTWorkOrderActions.resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params?.externalCaseId, params?.appointmentTypeName]);

  const applyAppointmentFilters = (appointment: IAppointment) => {
    let bool = true;
    if (selectedStatusFilters?.length > 0) {
      bool = !!selectedStatusFilters.filter(
        (item) => STATUS_FILTER_CONSTANT[item] === appointment?.externalStatus!
      ).length;
    }
    if (bool && selectedWorkItemFilters?.length > 0) {
      bool = !!selectedWorkItemFilters.filter(
        (item) =>
          SAT_FILTER_CONSTANT[item] === appointment?.serviceAppointmentType!
      ).length;
    }
    if (bool) return appointment;
  };

  useEffect(() => {
    if (workOrderState?.workOrder?.appointments?.length) {
      changeUrl({});
      setFilteredAppointments(
        workOrderState?.workOrder?.appointments.filter((app: IAppointment) =>
          applyAppointmentFilters(app)
        )
      );
      if (!generateLinkAppointment)
        setGenerateLinkAppointment(
          workOrderState?.workOrder?.appointments.find(
            (app: IAppointment) =>
              app?.status !== 'NotStarted' &&
              app?.externalStatus !== IAppointmentStatus.cancelled
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workOrderState?.workOrder?.appointments,
    selectedStatusFilters,
    selectedWorkItemFilters,
  ]);

  const changeUrl = ({
    status = selectedStatusFilters.join(','),
    workItem = selectedWorkItemFilters.join(','),
  }) => {
    const statusUrl = status ? `&status=${status}` : '';
    const workItemUrl = workItem ? `&workItem=${workItem}` : '';
    const url = `${statusUrl}${workItemUrl}`.slice(1);
    navigate({
      pathname: location.pathname,
      search: `?${url}`,
    });
  };

  useEffect(() => {
    if (workOrderState?.workOrder?.appointments?.length) {
      let appCount: any = {
        Scheduled: 0,
        Completed: 0,
        'In Progress': 0,
        'Cannot Complete': 0,
        Canceled: 0,
      };
      workOrderState?.workOrder?.appointments.forEach((app: any) => {
        if (appCount.hasOwnProperty(app?.externalStatus)) {
          appCount = {
            ...appCount,
            [app?.externalStatus]: appCount[app?.externalStatus] + 1,
          };
        }
      });
      setAppointmentStatusCount(appCount);
    }
  }, [workOrderState?.workOrder?.appointments]);

  const toggleStatusFilter = (status: string) => {
    const statusIndex = selectedStatusFilters.indexOf(status);
    statusIndex > -1
      ? setSelectedStatusFilters(
          selectedStatusFilters.filter((item) => item !== status)
        )
      : setSelectedStatusFilters([
          ...selectedStatusFilters.filter((item) => item !== ''),
          status,
        ]);
  };

  const renderGroupProgress = () => {
    let totalDCPackages = 0;
    let totalApprovedDCPackages = 0;
    let dcGroup = {};
    let acmGroup = {};
    let acGroup = {};
    let abschlussGroup = {};
    workOrderState?.workOrder?.groupStatuses.forEach((group: any) => {
      if (group?.name === 'DC') {
        totalDCPackages = totalDCPackages + group?.totalPackages ?? 0;
        totalApprovedDCPackages =
          totalApprovedDCPackages + group?.totalApprovedPackages ?? 0;
        dcGroup = {
          ...group,
          totalPackages: totalDCPackages,
          totalApprovedPackages: totalApprovedDCPackages,
        };
      } else if (group?.name === 'UK') {
        totalDCPackages = totalDCPackages + group?.totalPackages ?? 0;
        totalApprovedDCPackages =
          totalApprovedDCPackages + group?.totalApprovedPackages ?? 0;
        dcGroup = {
          ...dcGroup,
          totalPackages: totalDCPackages,
          totalApprovedPackages: totalApprovedDCPackages,
        };
      } else if (group?.name === 'AC') {
        acGroup = { ...group };
      } else if (group?.name === 'AC-M') {
        acmGroup = { ...group, name: 'ACM' };
      } else if (group?.name === 'Abschluss') {
        abschlussGroup = { ...group, name: 'Abschluss' };
      }
    });
    return [dcGroup, acmGroup, acGroup, abschlussGroup].map((group, i) => (
      <GroupProgress
        key={`group-progress-${i}`}
        appointmentId={
          filteredAppointments?.length > 0 ? generateLinkAppointment?.id : ''
        }
        group={group}
      />
    ));
  };

  const renderProgressDummy = () => {
    return [
      { name: 'DC', totalPackages: 0, totalApprovedPackages: 0 },
      { name: 'ACM', totalPackages: 0, totalApprovedPackages: 0 },
      { name: 'AC', totalPackages: 0, totalApprovedPackages: 0 },
      { name: 'Abschluss', totalPackages: 0, totalApprovedPackages: 0 },
    ].map((group, i) => (
      <GroupProgress key={`group-progress-${i}`} group={group} />
    ));
  };

  const renderAnswersProgress = () => {
    const totalFieldsWithoutInvisible =
      typeof workOrderState?.workOrder?.answerCounts?.total === 'number'
        ? workOrderState?.workOrder?.answerCounts?.approvalPending +
          workOrderState?.workOrder?.answerCounts?.openRequired +
          workOrderState?.workOrder?.answerCounts?.rejected +
          workOrderState?.workOrder?.answerCounts?.approved
        : 0;

    return (
      <div className={styles.answersProgressContainer}>
        <div className={styles.workItemsProgressContainer}>
          <Text>{translation(params?.appointmentTypeName ?? 'progress')}</Text>
          {workOrderState?.workOrder?.answerCounts?.total && (
            <Space>
              <Text type="secondary">
                {workOrderState?.workOrder?.answerCounts?.approved ?? 0}/
                {totalFieldsWithoutInvisible} {translation('fields')}
              </Text>

              <Link
                href={`${routes.appointmentsDetails.pathWithParams(
                  params?.page!,
                  generateLinkAppointment?.id ?? ''
                )}`}
                className={styles.flexCenter}
                target="_blank"
              >
                <LinkMTWIcon />
              </Link>
            </Space>
          )}
        </div>
        <Link
          href={`${routes.appointmentsDetails.pathWithParams(
            params?.page!,
            generateLinkAppointment?.id ?? ''
          )}`}
          disabled={
            typeof workOrderState?.workOrder?.answerCounts?.total !== 'number'
          }
          target="_blank"
        >
          <Progress
            strokeColor={
              workOrderState?.workOrder?.answerCounts?.approved ===
              totalFieldsWithoutInvisible
                ? '#64D59F'
                : '#FFC700'
            }
            percent={parseInt(
              String(
                (workOrderState?.workOrder?.answerCounts?.approved /
                  totalFieldsWithoutInvisible) *
                  100
              )
            )}
            showInfo={false}
          />
        </Link>
      </div>
    );
  };

  const items = [
    {
      label: translation('job_details'),
      key: 'item-1',
      children: (
        <JobDetails
          workOrder={workOrderState?.workOrder}
          fetchData={fetchWOPageData}
        />
      ),
      Icon: ScheduleOutlined,
    },
    {
      label: translation('documents'),
      key: 'item-2',
      children: (
        <WODocuments
          filesInfo={workOrderState?.workOrder?.files}
          customerName={workOrderState?.workOrder?.customer?.name}
        />
      ),
      Icon: FileTextOutlined,
    },
    {
      label: translation('notes'),
      key: 'item-3',
      children: <NotesLog notes={workOrderState?.workOrder?.notes} />,
      Icon: MessageOutlined,
    },
  ];

  return (
    <PageLayout>
      {workOrderState?.loading ? (
        <Spin className={styles.pageSpin} />
      ) : (
        <Layout className={styles.contentLayout}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.breadcrumbContainer}>
              <BreadcrumNavigation
                page={params.page!}
                workorderId={params.externalCaseId}
              />
            </div>
            <PageHeader
              className={styles.header}
              title={workOrderState?.workOrder?.customer?.name}
              subTitle={
                <div className={styles.headerSubTitle}>
                  <Text>{params?.externalCaseId}</Text>
                  <AppointmentStatusTag
                    type={IAppointmentStatusType.workOrder}
                    status={workOrderState?.workOrder?.status}
                    appointment={null}
                    statusList={WO_STATUSES}
                    externalWorkOrderId={
                      workOrderState?.workOrder?.externalWorkOrderId ?? ''
                    }
                    showTextForOpenStatuses={false}
                    onSuccess={fetchWOPageData}
                    parentNode={containerRef}
                  />
                </div>
              }
            />
            <Space className={styles.headerExtras}></Space>
          </div>
          <Content className={styles.wrapper} ref={containerRef}>
            <div className={styles.woDetails}>
              <div className={styles.customerInformation}>
                <Space className={styles.customerInformationHeader}>
                  <div className={styles.flexCenter}>
                    <DiplomatMTWIcon />{' '}
                  </div>
                  <Text strong style={{ fontSize: '16px' }}>
                    {translation('customer_information')}
                  </Text>
                </Space>{' '}
                <CustomerInformation
                  customerInfo={workOrderState?.workOrder?.customer}
                />
              </div>
              <div className={styles.jobDetails}>
                <Tabs
                  items={items.map((item, i) => {
                    return {
                      label: (
                        <span>
                          <item.Icon />
                          {item?.label}
                        </span>
                      ),
                      key: item?.key,
                      children: item?.children,
                    };
                  })}
                />
              </div>
            </div>

            <div className={styles.sectionContent2}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Space style={{ width: '100%', paddingTop: '16px' }}>
                  <div className={styles.flexCenter}>
                    <DiplomatMTWIcon />
                  </div>
                  <Text strong style={{ fontSize: '16px' }}>
                    {params?.appointmentTypeName !== 'Montage'
                      ? translation('documentation')
                      : translation('work_items')}
                  </Text>
                </Space>
                <div
                  className={styles.workItemsProgressContainer}
                  style={{ width: '100%' }}
                >
                  {params?.appointmentTypeName !== 'Montage'
                    ? renderAnswersProgress()
                    : workOrderState?.workOrder?.groupStatuses?.length > 0
                    ? renderGroupProgress()
                    : renderProgressDummy()}
                </div>
              </Space>
            </div>

            <div className={styles.sectionContent}>
              <Space
                className={styles.appointmentsHeader}
                style={{ paddingBottom: '16px' }}
              >
                <Text strong style={{ fontSize: '18px' }}>
                  {translation('appointments')}
                </Text>
                <Link
                  id="ga4-detail-drawer-planner-link"
                  href={`${config.plannerUrl}/request/${
                    params?.appointmentTypeName === CS_appointmentType.name
                      ? workOrderState?.workOrder?.externalCustomerOrderNumber
                      : params?.externalCaseId
                  }`}
                  target="_blank"
                >
                  <Button>{translation('edit_appointments')}</Button>
                </Link>
              </Space>
              <div className={styles.filtersContainer}>
                <Space style={{ flexWrap: 'wrap' }}>
                  <div
                    key={`status-filter-button-all`}
                    className={styles.removeButtonActive}
                  >
                    <Button
                      onClick={() => setSelectedStatusFilters([])}
                      type={
                        selectedStatusFilters?.length === 0 ? 'default' : 'text'
                      }
                    >
                      {translation('all')}
                      <span className={styles.badge}>
                        {workOrderState?.workOrder?.appointments?.length}
                      </span>
                    </Button>
                  </div>
                  {Object.keys(STATUS_FILTER_CONSTANT).map(
                    (statusFilter: string, i: number) => (
                      <div
                        key={`status-filter-button-${i}`}
                        className={styles.removeButtonActive}
                      >
                        <Button
                          type={
                            selectedStatusFilters.includes(statusFilter)
                              ? 'default'
                              : 'text'
                          }
                          onClick={() => {
                            toggleStatusFilter(statusFilter);
                          }}
                        >
                          {STATUS_FILTER_CONSTANT[statusFilter]}
                          <span className={styles.badge}>
                            {
                              appointmentStatusCount[
                                STATUS_FILTER_CONSTANT[statusFilter]
                              ]
                            }
                          </span>
                        </Button>
                      </div>
                    )
                  )}
                </Space>
                {params?.appointmentTypeName === 'Montage' && (
                  <Select
                    placeholder={translation('appointment_type_filter')}
                    optionFilterProp="children"
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    onChange={(workItemArray) => {
                      setSelectedWorkItemFilters(workItemArray);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    defaultValue={selectedWorkItemFilters}
                    style={{ width: '340px' }}
                    options={(Object.keys(IAppointmentTypeSalesForce) as Array<
                      keyof typeof IAppointmentTypeSalesForce
                    >).map((item: keyof typeof IAppointmentTypeSalesForce) => {
                      return {
                        value: item,
                        label: IAppointmentTypeSalesForce[item],
                      };
                    })}
                  />
                )}
              </div>
              <div className={styles.appointmentCardsContainer}>
                {filteredAppointments?.length > 0 &&
                  filteredAppointments.map((app: IAppointment, i: number) => (
                    <AppointmentCard
                      appointment={app}
                      key={`appointment-${i}`}
                    />
                  ))}
              </div>
            </div>
          </Content>
        </Layout>
      )}
    </PageLayout>
  );
};

export default WorkOrder;
