import { BrowserRouter as Router } from 'react-router-dom';
import 'antd/dist/reset.css';

import Authentication from './components/authentication';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { store } from './store';
import { I18nProvider } from './i18n';
import ErrorBoundary from './components/errorBoundary';
import Routing from './routing';
import AppInfoBanner from './components/appInfoBanner';
import AccessControl from './components/accessControl';
import { IFeatures } from './store/rolesAndPrivileges';
import { antdThemeNone } from './constants/antDesignTheme';
import NewDomainRedirect from './components/NewDomainRedirect';

function App() {
  return (
    <Provider store={store}>
      <NewDomainRedirect forceRedirect />
      <ConfigProvider theme={antdThemeNone}>
        <I18nProvider>
          <Authentication>
            <AccessControl featureKey={IFeatures.appointmentRead}>
              <ErrorBoundary>
                <AppInfoBanner />
                <Router>
                  <Routing />
                </Router>
              </ErrorBoundary>
            </AccessControl>
          </Authentication>
        </I18nProvider>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
