import { store } from '../../store';
import {
  AppointmentsTableRequestType,
  FetchOptions,
  IReasonCodeOccurance,
  RequestAnswersData,
  IAppointmentStatusActionType,
  IQueue,
  NotificationType,
  WeeklyAppointmentsTableRequestType,
} from '../../constants/types';
import { config } from '../../config';
import { LOCALES } from '../../i18n';
import { FormType } from '../../pages/formBuilderDetail/types';

interface IRequestType {
  apiPath: string;
  errorKey?: string;
  useRCService?: boolean;
  method?: string;
  contentType?: {};
  language?: string;
}

interface IGetRequestType extends IRequestType {
  queryStr?: string;
  getPagination?: boolean;
}

interface IUpdateRequestType extends IRequestType {
  payload?: string;
}

const getBearerToken = () => ({
  Authorization: `Bearer ${store.getState().authentication.token}`,
});

const throwError = async (resp: any, errorKey: string | undefined) => {
  const responseError = {
    type: NotificationType.error,
    message: resp.statusText || 'Something went wrong',
    code: resp.status || '',
  };
  let error = new Error();
  if (resp.status === 400) {
    const errBody = await resp.json();
    error = { ...error, ...{ body: errBody } };
    if (errBody.errors) {
      let errorsList = Object.values(errBody.errors);
      responseError.message = errBody.title;
      if (errBody.errors[901]) {
        errorKey = 'error_post_approval_conflict';
      } else if (errBody.errors[915]) {
        errorKey = 'error_invalid_snapshot_version';
      } else if (errBody.errors[904]) {
        errorKey = 'error_empty_queue_item';
        responseError.type = NotificationType.warn;
      } else if (errorsList.length && Array.isArray(errorsList[0])) {
        responseError.message = `${
          responseError.message
        } - ${errorsList[0].join(', ')}`;
      }
    }
  }
  error = { ...error, ...responseError, ...{ errorKey } };
  throw error;
};

const Http = {
  apiBaseURL: config.apiUrl,
  apiRCBaseURL: config.apiRCUrl,

  async get({
    apiPath,
    queryStr,
    getPagination,
    errorKey,
    useRCService,
    language = (localStorage.getItem('user-language') ?? LOCALES.GERMAN).split(
      '-'
    )[0],
  }: IGetRequestType) {
    const opt: FetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Language: language,
        ...getBearerToken(),
      },
    };

    const resp = await fetch(
      `${useRCService ? this.apiRCBaseURL : this.apiBaseURL}/${apiPath}${
        queryStr ? `?${queryStr}` : ''
      }`,
      opt
    );
    if (resp.ok) {
      if (resp.status === 204) {
        return {};
      }

      const response = await resp.json();
      if (getPagination) {
        const pagination = JSON.parse(resp.headers.get('X-Pagination') || '');
        return { response, pagination };
      }
      return { response };
    }

    await throwError(resp, errorKey);
  },

  async update({
    apiPath,
    payload,
    errorKey,
    useRCService,
    method,
    contentType = { 'Content-Type': 'application/json' },
  }: IUpdateRequestType) {
    const opt: FetchOptions = {
      method: method,
      headers: {
        ...contentType,
        ...getBearerToken(),
      },
      body: payload,
    };

    const resp = await fetch(
      `${useRCService ? this.apiRCBaseURL : this.apiBaseURL}/${apiPath}`,
      opt
    );
    if (resp.ok) {
      const response = await resp.json();
      return { response };
    }

    await throwError(resp, errorKey);
  },

  async post(params: IUpdateRequestType) {
    return this.update({ ...params, method: 'POST' });
  },

  async put(params: IUpdateRequestType) {
    return this.update({ ...params, method: 'PUT' });
  },

  async patch(params: IUpdateRequestType) {
    return this.update({ ...params, method: 'PATCH' });
  },

  async upload(params: IUpdateRequestType) {
    return this.update({ ...params, method: 'POST', contentType: {} });
  },

  async delete(params: IUpdateRequestType) {
    return this.update({ ...params, method: 'DELETE' });
  },
};

const ApiRepository = {
  async getAppInfoBannerText() {
    return await Http.get({
      apiPath: 'Notification',
      errorKey: 'error_fetch_teams',
    });
  },

  /// Get All Teams list
  async getTeamsData() {
    return await Http.get({
      apiPath: 'Teams',
      errorKey: 'error_fetch_teams',
    });
  },

  /// Get All Teams list
  async getAppointmentTypesData(name?: string) {
    let nameUrl = name ? `&Name=${name}` : '';
    const queryStr = `${nameUrl}`.slice(1);
    return await Http.get({
      apiPath: 'AppointmentTypes',
      queryStr,
      errorKey: 'error_fetch_appointment_types',
    });
  },

  /// Get All Teams list
  async getEntityStatusList(entity?: string) {
    return await Http.get({
      apiPath: `SalesForce/${entity}/status`,
      errorKey: 'error_fetch_entity_status',
    });
  },

  async updateEntityStatus(
    status: string,
    entity: string,
    externalId: string,
    oldStatus = '',
    comment = ''
  ) {
    return await Http.put({
      apiPath: `SalesForce/${entity}/status/${externalId}`,
      payload: JSON.stringify({ status, oldStatus, comment }),
      errorKey: 'error_update_entity_status',
    });
  },

  /// Update work order or appointment status - craftos apis
  async updateEntitiesStatus(
    status: string,
    entity: string,
    externalId: string,
    oldStatus = '',
    comment = ''
  ) {
    return await Http.post({
      apiPath: `dashboard/status-update/${entity}?id=${externalId}`,
      payload: JSON.stringify({ status, oldStatus, comment }),
      errorKey: 'error_update_entity_status',
    });
  },

  /// Get all appointments data with filters and pagination
  async getAppointmentsData({
    endDate,
    pageNumber,
    pageSize,
    status,
    appointmentStatus,
    startDate,
    caseId,
    externalCaseId,
    customer,
    team,
    questions,
  }: AppointmentsTableRequestType) {
    const startDateUrl = startDate ? `&StartDate=${startDate}` : '';
    const endDateUrl = endDate ? `&EndDate=${endDate}` : '';
    const pageNumberURL = pageNumber ? `&PageNumber=${pageNumber}` : '';
    const pageSizeURL = pageSize ? `&PageSize=${pageSize}` : '';
    const caseIdUrl = caseId ? `&CaseId=${caseId}` : '';
    const externalCaseIdUrl = externalCaseId
      ? `&ExternalCaseId=${externalCaseId}`
      : '';
    const customerUrl = customer ? `&Customer=${customer}` : '';
    const appointmentStatusUrl = appointmentStatus
      ? `&AppointmentStatus=${appointmentStatus}`
      : externalCaseId || customer
      ? ''
      : '&AppointmentStatus=Active&AppointmentStatus=Stopped';

    const questionsUrl = questions === true ? `&Questions=true` : '';

    let statusUrl = status?.length
      ? `&Status=${status.join('&Status=').replace(/\s/g, '%20')}`
      : '';
    let teamUrl = team?.length
      ? `&Team=${team.map((t) => encodeURIComponent(t)).join('&Team=')}`
      : '';

    const queryStr = `${startDateUrl}${endDateUrl}${statusUrl}${appointmentStatusUrl}${pageNumberURL}${pageSizeURL}${caseIdUrl}${externalCaseIdUrl}${customerUrl}${questionsUrl}${teamUrl}&SortBy=status&SortBy=-appointmentDate`.slice(
      1
    );

    return await Http.get({
      apiPath: 'Appointments',
      queryStr,
      getPagination: true,
      errorKey: 'error_fetch_apointments',
    });
  },

  /// Get all appointments data with filters and pagination
  async getWeeklyAppointmentsData({
    week,
    team,
    appointmentTypes,
    dashboardType = 'installation',
  }: WeeklyAppointmentsTableRequestType) {
    return await Http.post({
      apiPath: `dashboard/${dashboardType}-progress`,
      payload: JSON.stringify({
        week,
        team,
        appointmentTypes: appointmentTypes
          ? appointmentTypes.split(',')
          : undefined,
      }),
      errorKey: 'error_fetch_apointments',
    });
  },

  async sendWorkOrderNote(
    selectedExternalCaseId?: string,
    selectedAppointmentId?: string,
    note?: string,
    updateNoteId?: string
  ) {
    return updateNoteId
      ? await Http.put({
          apiPath: `WorkOrder/${selectedExternalCaseId}/${selectedAppointmentId}/notes/${updateNoteId}`,
          payload: JSON.stringify({
            text: note,
          }),
          errorKey: 'error_post_appointment_note',
        })
      : await Http.post({
          apiPath: `WorkOrder/${selectedExternalCaseId}/${selectedAppointmentId}/notes`,
          payload: JSON.stringify({
            text: note,
          }),
          errorKey: 'error_post_appointment_note',
        });
  },

  async getAppointmentsDetailsData(appointmentId: string) {
    return await Http.get({
      apiPath: `Appointments/${appointmentId}`,
      errorKey: 'error_fetch_apointment_details',
    });
  },

  async getWorkOrdersDetailsData(
    externalCaseId: string,
    appointmentTypeName: string
  ) {
    return await Http.get({
      apiPath: `WorkOrder/${externalCaseId}/${appointmentTypeName}`,
      errorKey: 'error_fetch_workorder_details',
    });
  },

  async getDocumentsData(externalCaseId: string, appointmentTypeName: string) {
    return await Http.get({
      apiPath: `documentation/${appointmentTypeName}/${externalCaseId}`,
      errorKey: 'error_fetch_documentation',
    });
  },

  async getCheckedInUsersByExternalId(id: string, onlyActive: boolean = true) {
    return await Http.get({
      apiPath: `Appointments/${id}/checkins?onlyActive=${onlyActive}`,
      errorKey: 'error_fetch_appointment_login_users',
    });
  },

  async getTimeLogByExternalId(externalId: string) {
    return await Http.get({
      apiPath: `Appointments/${externalId}/logs/dates`,
      errorKey: 'error_fetch_appointment_time_log',
    });
  },

  async getStatusChangeLogByExternalId(externalId: string) {
    return await Http.get({
      apiPath: `Appointments/${externalId}/logs`,
      errorKey: 'error_fetch_appointment_status_change_log',
    });
  },

  async getDelayReasonLogByAppointmentId(id: string) {
    return await Http.get({
      apiPath: `ChangeLogs/Appointment/${id}/`,
      queryStr: '?fieldName=DelayedEndReason',
      errorKey: 'error_fetch_appointment_status_change_log',
    });
  },

  async createNewTimeLog(
    externalId: string,
    values: {
      comment: string;
      startDate?: string;
      endDate?: string;
    }
  ) {
    return await Http.put({
      apiPath: `Appointments/${externalId}/dates`,
      payload: JSON.stringify(values),
      errorKey: 'save_new_time_log_error',
    });
  },

  async getWorktypeGroupsData(appointmentTypeId?: string) {
    let appointmentTypeUrl = appointmentTypeId
      ? `&AppointmentTypes=${appointmentTypeId}`
      : '';
    const queryStr = `${appointmentTypeUrl}`.slice(1);

    return await Http.get({
      apiPath: 'Group',
      queryStr,
      errorKey: 'error_fetch_worktype_groups',
    });
  },

  async getWorktypeInstanceData(appointmentId: string) {
    return await Http.get({
      apiPath: 'forms/instances',
      queryStr: `AppointmentId=${appointmentId}`,
      errorKey: 'error_fetch_worktype_instance',
    });
  },

  async getMetadataInfos(caseId: string) {
    return await Http.get({
      apiPath: `Metadata/instance/${caseId}`,
      queryStr: 'sync=true',
      errorKey: 'error_fetch_additional_infos',
    });
  },

  async getAnswersListData({
    appointmentId,
    approvalRequired,
    status,
    pageNumber,
    pageSize,
    questions,
    worktypeFilter,
    formRevisionId,
  }: RequestAnswersData) {
    const pageNumberURL = pageNumber ? `&PageNumber=${pageNumber}` : '';
    const pageSizeURL = pageSize ? `&PageSize=${pageSize}` : '';
    const approvalRequiredUrl = approvalRequired
      ? ''
      : '&ApprovalRequired=true';
    const questionsUrl = questions === true ? `&Questions=true` : '';
    const worktypeFilterUrl = worktypeFilter?.length
      ? `&WorkTypeId=${worktypeFilter.join('&WorkTypeId=')}`
      : '';
    const statusUrl = status?.length
      ? `&Status=${status.join('&Status=').replace(/\s/g, '%20')}`
      : '';
    const formRevisionIdUrl = formRevisionId
      ? `&FormRevisionId=${formRevisionId}`
      : '';
    const queryStr = `&AppointmentId=${appointmentId}${statusUrl}${approvalRequiredUrl}${pageNumberURL}${pageSizeURL}${questionsUrl}${worktypeFilterUrl}${formRevisionIdUrl}`.slice(
      1
    );

    return await Http.get({
      apiPath: 'forms/answers',
      queryStr,
      getPagination: true,
      errorKey: 'error_fetch_answers',
    });
  },

  async getFormRevisions(arrayRevisionsId: Array<string>) {
    let revision;
    await Promise.all(
      arrayRevisionsId.map(async (formRevisionId: string) => {
        const data = await Http.get({
          apiPath: `Forms/revisions/${formRevisionId}`,
          errorKey: 'error_fetch_form_revisions',
        });

        return { [formRevisionId]: data!.response };
      })
    ).then((values) => {
      revision = values.reduce(
        (total, current) => ({ ...total, ...current }),
        {}
      );
    });

    return revision;
  },

  /// Get Answer details
  async getAnswerData(workTypeId: string) {
    return await Http.get({
      apiPath: `forms/answers/${workTypeId}`,
      errorKey: 'error_fetch_answer_details',
    });
  },

  async getApprovalComments(answerId: string, answerSnapshotId: string) {
    return await Http.get({
      apiPath: `Forms/answers/${answerId}/approvals`,
      queryStr: `answerSnapshotId=${answerSnapshotId}`,
      errorKey: 'error_fetch_approval_comments',
    });
  },

  async getReasonCodes(formFieldId: string, formId: string) {
    return await Http.get({
      apiPath: `FieldReasonCodes/${formId}/${formFieldId}`,
      useRCService: true,
      errorKey: 'error_fetch_reason_code_list',
    });
  },

  async postComment(
    id: string,
    comment: string,
    approved: boolean,
    appointmentId: string,
    answerSnapshotId: string,
    finalApproval: boolean = false,
    uploads?: string[]
  ) {
    return await Http.post({
      apiPath: `forms/answers/${id}/approve`,
      payload: JSON.stringify({
        comment,
        approved,
        appointmentId,
        answerSnapshotId,
        finalApproval,
        uploads,
      }),
      errorKey: 'error_post_comment',
    });
  },

  async postReasonCodes(reasonCodeData: IReasonCodeOccurance) {
    return await Http.post({
      apiPath: 'ReasonCodesOccurences',
      payload: JSON.stringify(reasonCodeData),
      errorKey: 'error_post_reason_code',
      useRCService: true,
    });
  },

  async postConfirmReviewComment(
    id: string,
    appointmentId: string,
    answerSnapshotId: string
  ) {
    return await Http.post({
      apiPath: `Forms/answers/${id}/confirmapproval`,
      payload: JSON.stringify({
        appointmentId,
        answerSnapshotId,
      }),
      errorKey: 'error_post_confirm_approval',
    });
  },

  async postReply(
    id: string,
    comment: string,
    appointmentId: string,
    answerSnapshotId: string,
    uploads?: string[]
  ) {
    return await Http.post({
      apiPath: `Forms/answers/${id}/comments`,
      payload: JSON.stringify({
        comment,
        uploads,
        appointmentId,
        answerSnapshotId,
        qmSubmission: true,
      }),
      errorKey: 'error_post_comment',
    });
  },

  async postReopen(id: string, appointmentId: string) {
    return await Http.post({
      apiPath: `Forms/answers/${id}/reopen`,
      payload: JSON.stringify({ appointmentId }),
      errorKey: 'error_post_reopen',
    });
  },

  async updateAppointmentStatus(
    appointmentId: string,
    actionType: IAppointmentStatusActionType
  ) {
    return await Http.put({
      apiPath: `Appointments/${appointmentId}/status`,
      payload: JSON.stringify({
        status:
          actionType === IAppointmentStatusActionType.close
            ? 'Closed'
            : 'Stopped',
      }),
      errorKey: 'error_post_appointment_status',
    });
  },

  async updateAppointmentDelayReason(
    appointmentId: string,
    delayedEndReason: string
  ) {
    return await Http.put({
      apiPath: `Appointments/${appointmentId}/delayed-end-reason`,
      payload: JSON.stringify({
        delayedEndReason,
      }),
      errorKey: 'error_post_appointment_delay_reason',
    });
  },

  async updateAppointmentDescription(workOrderId: string, description: string) {
    return await Http.patch({
      apiPath: `Workorder/${workOrderId}`,
      payload: JSON.stringify({
        description,
      }),
      errorKey: 'error_post_workorder_description',
    });
  },

  async closeAppointment(appointmentId: string) {
    return await Http.put({
      apiPath: `Appointments/${appointmentId}/close`,
      errorKey: 'error_close_appointment',
    });
  },

  async getAssignmentData(assignmentId: string) {
    return await Http.get({
      apiPath: `Forms/assignments/${assignmentId}`,
      errorKey: 'error_fetch_assignments_details',
    });
  },

  async getAssignedApprovalPackageInfo() {
    return await Http.get({
      apiPath: 'ApprovalPackage/info',
      errorKey: 'error_fetch_assigned_approval_package',
    });
  },

  async assignApprovalPackage(source: IQueue) {
    return await Http.get({
      apiPath: 'ApprovalPackage',
      queryStr: `&source=${source}`,
      errorKey: 'error_fetch_approval_package',
    });
  },

  async assignNextApprovalPackage(assignmentId: string, formFieldId: string) {
    return await Http.post({
      apiPath: 'ApprovalPackage/next',
      payload: JSON.stringify({ assignmentId, formFieldId }),
      errorKey: 'error_fetch_next_approval_package',
    });
  },

  async getApprovalPackageCount() {
    return await Http.get({
      apiPath: 'ApprovalPackage/count',
      errorKey: 'error_fetch_approval_package_count',
    });
  },

  async unassignApprovalPackage(assignmentId: string, formFieldId: string) {
    return await Http.post({
      apiPath: 'ApprovalPackage/unassign',
      payload: JSON.stringify({ assignmentId, formFieldId }),
      errorKey: 'error_unassign_approval_package',
    });
  },

  async getUserApprovalPackageHistory() {
    return await Http.get({
      apiPath: 'ApprovalPackage/user-history',
      errorKey: 'error_fetch_user_approval_package_history',
    });
  },

  //Form builder ============================================================
  async getForms({
    pageNumber,
    pageSize,
    sortedBy,
    status,
    search,
    formStatus = 'Active',
  }: any) {
    const pageNumberURL = pageNumber ? `&PageNumber=${pageNumber}` : '';
    const pageSizeURL = pageSize ? `&PageSize=${pageSize}` : '';
    const sortedByURL = sortedBy ? `&SortBy=${sortedBy}` : '';
    const statusURL = status ? `&Status=${status}` : '';
    const searchURL = search ? `&Search=${search}` : '';
    const formStatusURL = formStatus ? `&FormStatus=${formStatus}` : '';
    const queryStr =
      pageNumberURL +
      pageSizeURL +
      sortedByURL +
      statusURL +
      searchURL +
      formStatusURL;

    return await Http.get({
      apiPath: 'Forms',
      getPagination: true,
      queryStr,
    });
  },

  async getFormDetail(id: string) {
    return await Http.get({
      apiPath: `Forms/revisions/${id}`,
      language: 'all',
    });
  },

  async getPublishedFormDetail(formId: string) {
    return await Http.get({
      apiPath: `Forms/${formId}`,
      language: 'all',
    });
  },

  async createNewWorkType(form: FormType) {
    let payload = JSON.stringify({
      name: form.name[form.name.default],
      description: form.name[form.name.description],
    });
    return await Http.post({
      apiPath: `WorkTypes`,
      payload,
    });
  },

  async createNewForm<T extends { formId: string }>(form: T) {
    return await Http.post({
      apiPath: `Forms`,
      payload: JSON.stringify(form),
    });
  },

  async uploadFile(container: string, files: any) {
    return await Http.upload({
      apiPath: `Files?container=${container}`,
      payload: files,
      errorKey: 'error_upload_images',
    });
  },

  async updateForm<T extends { formId: string }>(
    form: T,
    skipValidation: boolean = false
  ) {
    return await Http.put({
      apiPath: `Forms/${form.formId}/draft`,
      payload: JSON.stringify({ ...form, skipValidation }),
    });
  },

  async publishForm(id: string) {
    return await Http.post({
      apiPath: `Forms/revisions/${id}/publish`,
    });
  },

  async getMetadataList() {
    return await Http.get({
      apiPath: `Metadata`,
    });
  },

  async createMetaData(key: string, description: string) {
    return await Http.post({
      apiPath: `Metadata`,
      payload: JSON.stringify({
        key,
        description,
      }),
      errorKey: 'error_post_metadata',
    });
  },

  async getAppointmentExternalDetail(id: string) {
    return await Http.get({
      apiPath: `Appointments/externalid/${id}`,
    });
  },

  async getAppointmentsByCaseId(caseId: string, appointmentTypeId: string) {
    return await Http.get({
      apiPath: `Appointments/case/${caseId}`,
      queryStr: appointmentTypeId
        ? `appointmentTypes=${appointmentTypeId}`
        : '',
      errorKey: 'error_get_appointment_caseid',
    });
  },

  //Appointment Types builder ============================================================

  async getAppointmentTypeDetail(id: string) {
    return await Http.get({
      apiPath: `AppointmentTypes/${id}`,
    });
  },

  async getAllWorkTypes() {
    return await Http.get({
      apiPath: 'WorkTypes',
      //errorKey: 'error_fetch_work_types', // need to create entry
    });
  },

  async updateAppointmentTypeDetail<T extends { id: string }>(
    appointmentType: T
  ) {
    return await Http.put({
      apiPath: `AppointmentTypes/${appointmentType.id}`,
      payload: JSON.stringify(appointmentType),
    });
  },

  async createAppointmentType<T>(appointmentType: T) {
    return await Http.post({
      apiPath: `AppointmentTypes`,
      payload: JSON.stringify(appointmentType),
    });
  },

  async updateGroup<T extends { id: string }>(group: T) {
    return await Http.put({
      apiPath: `Group/${group.id}`,
      payload: JSON.stringify(group),
    });
  },

  async createGroup<T>(group: T) {
    return await Http.post({
      apiPath: `Group`,
      payload: JSON.stringify(group),
    });
  },

  async deleteGroup(id: string) {
    return await Http.delete({
      apiPath: `Group/${id}`,
    });
  },
};

export default ApiRepository;
