import { FetchOptions } from '../../constants/types';
import { config } from '../../config';
import { Promise } from 'bluebird';
import { getHeaderOfDocument, generateWorkTypeField } from './word';
import { generateDocumentFields } from '../../utils/generateDocumentFields';
const docx = require('docx');
const {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  AlignmentType,
  WidthType,
  VerticalAlign,
  ShadingType,
  TableLayoutType,
} = docx;

const ReportService = {
  async generateReport(
    token: string,
    appointmentId: string,
    externalId: string
  ): Promise<void> {
    const opt: FetchOptions = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const appointment = await (
      await fetch(`${config.apiUrl}/appointments/externalId/${externalId}`, opt)
    ).json();
    const answers: any = await (
      await fetch(
        `${config.apiUrl}/forms/answers?AppointmentId=${appointmentId}&PageNumber=1&PageSize=100000`,
        opt
      )
    ).json();
    const revisionIds = Array.from(
      new Set(answers.map((d: any) => d.formInstance.formRevisionId))
    );
    const revisions = await Promise.map(revisionIds, async (revisionId) => {
      return await (
        await fetch(`${config.apiUrl}/Forms/revisions/${revisionId}`, opt)
      ).json();
    });
    const workTypeIds = appointment.appointmentType.workTypes
      .map((wt: any) => wt.id)
      .join(`&workTypeId=`);
    const formData = await (
      await fetch(
        `${config.apiUrl}/Forms?FullObject=true&WorkTypeId=${workTypeIds}`,
        opt
      )
    ).json();
    const metadata = await (
      await fetch(
        `${config.apiUrl}/Metadata/instance/${appointment.caseId}`,
        opt
      )
    ).json();

    formData.forEach((d: any) => {
      if (!revisions.find((r) => r.id === d.id)) {
        revisions.push(d);
      }
    });
    await this.generateWord(
      appointment,
      answers,
      revisions,
      token,
      null,
      metadata
    );
  },
  async updateFieldAnswer(fields: any, fa: any) {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field?.children && field.children?.length > 0)
        await this.updateFieldAnswer(field.children, fa);

      if (field.id === fa.formFieldId) {
        field.answer = fa.answers[0];
        field.formFieldId = fa.formFieldId;
      }
    }
  },
  async generateWord(
    appointment: any,
    answers: any,
    revisions: any,
    accessToken: any,
    res: any,
    metadata: any
  ) {
    const data = appointment.appointmentType.workTypes.reduce(
      (acc: any, current: any) => {
        if (!acc[current.id]) {
          const revision = revisions.find(
            (r: any) => r.workType.id === current.id
          );
          acc[current.id] = {
            id: current.id,
            name: current.name,
            revision,
          };
        }
        return acc;
      },
      {}
    );

    const filteredAnswer = answers.filter((a: any) => a?.answers?.length > 0);
    if (filteredAnswer?.length > 0) {
      filteredAnswer.forEach(async (fa: any) => {
        data[fa.formInstance.workTypeId].revision = revisions.find(
          (r: any) => r.id === fa.formInstance.formRevisionId
        );
        const revision = data[fa.formInstance.workTypeId].revision;
        await this.updateFieldAnswer(revision.children, fa);
      });
    }

    const workTypesValues: any = Object.values(data);

    const newRows = [];
    for (let j = 0; j < workTypesValues?.length; j++) {
      if (workTypesValues[j].revision) {
        const newRow = await this.generateWorkType(
          workTypesValues[j].revision,
          filteredAnswer,
          metadata
        );
        if (newRow) {
          newRows.push(newRow);
          newRows.push(new Paragraph(''));
        }
      }
    }

    const header = await getHeaderOfDocument(appointment, metadata);

    const doc = new Document({
      sections: [
        {
          children: [...header, new Paragraph(''), ...newRows],
        },
      ],
    });

    const byte = await Packer.toBuffer(doc);
    var blob = new Blob([byte], { type: 'application/docx' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var { fileNameStart, fileNameEnd } = generateDocumentFields(
      appointment.appointmentType.name
    );
    var fileName = `${fileNameStart}${appointment.customer?.name}${
      appointment.appointmentType.name === 'Montage' ? '_' : '-'
    }${appointment.externalCaseId}${fileNameEnd}.docx`;
    link.download = fileName;
    link.click();
  },
  async generateWorkType(workType: any, answers: any, metadata: any) {
    let rows = [];

    if (workType?.children?.length) {
      for (let i = 0; i < workType?.children?.length; i++) {
        const row = await generateWorkTypeField(
          workType.children[i],
          answers,
          metadata
        );
        rows.push(...row);
      }
    }

    if (!rows || rows.length <= 0) return;

    return new Table({
      layout: TableLayoutType.FIXED,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              shading: {
                fill: '909090',
                type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                color: 'auto',
              },
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: workType.name,
                      size: 30,
                      bold: true,
                    }),
                  ],
                }),
              ],
              columnSpan: 2,
            }),
          ],
        }),

        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: 200,
                bottom: 200,
                left: 100,
                right: 100,
              },
              children: [
                new Paragraph(''),
                new Table({
                  layout: TableLayoutType.FIXED,
                  width: {
                    size: 99,
                    type: WidthType.PERCENTAGE,
                  },
                  rows: [...rows],
                }),
              ],
            }),
          ],
        }),
      ],
    });
  },
};

export default ReportService;
