import { FC, useMemo } from 'react';
import { Input, Form, Typography, Tooltip, Space } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';
import DefaultTextView from './DefaultTextView';

const { Text } = Typography;

const FormItemLabel: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
  language,
  defaultLanguage,
  hidden,
}) => {
  const fieldValue = Form.useWatch(['label', defaultLanguage]);
  const publishedItemValues = useMemo(
    () => ({
      label: fieldChanges?.label.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      key={`label-${language}`}
      hidden={hidden}
      name={['label', language!]}
      label={
        <Space direction="horizontal">
          {fieldChanges?.label.hasChanges ? (
            <Text mark>
              <Tooltip title={translation('field_name_tooltip')}>
                <span>{translation('formbuilder_field_name')} </span>
              </Tooltip>
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemLabel language={language} />
              </PublishedItem>
            </Text>
          ) : (
            <Tooltip title={translation('field_name_tooltip')}>
              {translation('formbuilder_field_name')}{' '}
            </Tooltip>
          )}
          {language !== defaultLanguage && (
            <DefaultTextView defaultValue={fieldValue} />
          )}
        </Space>
      }
      rules={[
        {
          required: true,
          whitespace: true,
          message: 'Insert a field name',
        },
      ]}
    >
      <Input placeholder={fieldValue} disabled={!editMode} />
    </Form.Item>
  );
};

export default FormItemLabel;
