import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  forceRedirect?: boolean;
}

/**
 * A functional component that checks if the current domain is in the domainMapping object.
 * If a match is found, it will either redirect the user to the new domain or display a confirmation modal based on the forceRedirect prop value.
 * @param {Props} props - Props passed to the component. Contains a forceRedirect prop with a boolean value.
 */
const NewDomainRedirect: React.FC<Props> = ({ forceRedirect = true }) => {
  const [newDomain, setNewDomain] = useState<string>('');

  // useEffect hook to initialize the newDomain state with the new domain value
  // if the current domain is found in the domainMapping object.
  useEffect(() => {
    const currentDomain = window.location.hostname;
    const domainMapping: { [key: string]: string } = {
      //localhost: 'fulfilment-test.craftos.enpal.io',
      'fulfilment-test.craftos.enpal.tech': 'fulfilment-test.craftos.enpal.io',
      'fulfilment-dev.craftos.enpal.tech': 'fulfilment-dev.craftos.enpal.io',
      'fulfilment.craftos.enpal.tech': 'fulfilment.craftos.enpal.io',
      'fulfilment-academy.craftos.enpal.tech':
        'fulfilment-academy.craftos.enpal.io',
    };

    if (currentDomain in domainMapping) {
      const newDomain = `https://${domainMapping[currentDomain]}${window.location.pathname}`;
      if (forceRedirect) {
        confirmRedirect(newDomain);
      } else {
        setNewDomain(newDomain);
      }
    }
  }, []);

  /**
   * Function to redirect the user to the new domain.
   * @param {string} newDomain - The new domain to redirect the user to.
   */
  const confirmRedirect = (newDomain: string) => {
    window.location.href = newDomain;
  };

  // useEffect hook to display a confirmation modal when newDomain has a value
  useEffect(() => {
    if (newDomain) {
      Modal.confirm({
        title: 'Redirect to New Domain',
        content: 'Are you sure you want to be redirected to the new domain?',
        onOk: confirmRedirect,
        onCancel() {
          // Do nothing or handle cancellation
        },
      });
    }
  }, [newDomain]);

  // Render nothing or return custom JSX if needed
  return null;
};

export default NewDomainRedirect;
