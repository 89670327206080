import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import cName from 'classnames';
import {
  Table,
  Typography,
  Space,
  Layout,
  Tooltip,
  Popover,
  Row,
  Col,
  Progress,
  Button,
  Checkbox,
  Badge,
  Input,
  InputRef,
} from 'antd';
import { useIntl } from 'react-intl';
import { RootState } from '../../store';
import * as routes from '../../router/routes';
import {
  ProgressTableState,
  IAppointment,
  ITeam,
  IAppointmentStatus,
  IAppointmentStatusType,
  NotificationType,
  IGroupStatus,
  IStatus,
  CS_appointmentType,
  HI_appointmentType,
} from '../../constants/types';
import ProgressTableActions from './actions';
import styles from './progress.module.scss';
import translation from '../../i18n/translation';
import classNames from 'classnames';
import { showNotificationMessage } from '../../utils/notification';
import AppLayout from '../../components/appLayout';
import { Content } from 'antd/lib/layout/layout';
import AppointmentStatusTag from '../../components/appointmentStatusTag';
import useAutoFetchData from '../../utils/useAutoFetchData';
import {
  FilterRow,
  IOnChangeFilterType,
} from '../../components/filterSection/filterSection';
import TimeLogPopover from './time-log-popover';
import DetailDrawer from './detail-drawer';
import { ProgressHeader } from './components/header';
import { ProgressFilters } from './components/filters';
import moment from 'moment';
import { IFeatures } from '../../store/rolesAndPrivileges';
import { useSaveLocationSearchParams } from 'craftos-ui';
import MapContainer from './components/maps/maps';
import { MapsSvg } from '../../components/icons/mapsSvg';
import { ListSvg } from '../../components/icons/listSvg';
import CheckedInUsersPopover from './checked-in-users-popover';
import { LastAppointmentSvg } from '../../components/icons/lastAppointmentSvg';
import { AppointmentAtRiskSvg } from '../../components/icons/appointmentAtRiskSvg';
import ParallelAppointmentSvg from '../../components/icons/parallelAppointmentSvg';
import { DelayedSvg } from '../../components/icons/delayedSvg';
import WidgetDelayAndStatusModal from '../../components/widgetDelayAndStatusModal';
import { NavigationIcon } from '../../components/icons/navigationIcon';
import { WifiIconActiveSvg } from '../../components/icons/wifiIconActive';
import { WifiIconNotActiveSvg } from '../../components/icons/wifiIconNotActive';
import { VideoSvg } from '../../components/icons/videoSvg';
import { SwapRightOutlined } from '@ant-design/icons';
import { useVT } from 'virtualizedtableforantd4';
import { PriorityCustomerSvg } from '../../components/icons/priorityCustomerSvg';

const { Text, Link, Paragraph } = Typography;
const { Search } = Input;
const scrollHeight = window.innerHeight - 330;
const SHOW_TIME_DELAY_BADGE = [
  'Montage',
  CS_appointmentType.name,
  HI_appointmentType.name,
];

const DEFAULT_BRANCH = 'Berlin';
const DEFAULT_AT = '';
const DEFAULT_WEEK = 0;

interface IChangeUrl {
  branch?: string;
  AppointmentTypes?: string;
  plId?: string;
  week?: number;
  team?: string;
  filter?: string;
  customerSearch?: string;
}

const GenericProgress: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const customerSearchRef = useRef<InputRef | null>(null);

  const genericProgressTableState: ProgressTableState = useSelector(
    (state: RootState) => state.progressTable
  );
  const appointmentsTeamsState = useSelector(
    (state: RootState) => state.progressTeams
  );
  const privilegesState = useSelector(
    (state: RootState) => state.authentication?.privileges ?? {}
  );

  let hasChangeStatusAccess = privilegesState[IFeatures.progressWriteStatus];

  const [teamsList, setTeamsList] = useState<ITeam[]>([]);
  const [branchList, setBranchList] = useState<string[]>([]);
  const [leadIdList, setLeadIdList] = useState<string[]>([]);
  const [canRefetchData, setCanRefetchData] = useState<boolean>(true);
  const [
    selectedAppointment,
    setSelectedAppointment,
  ] = useState<IAppointment | null>(null);
  const [showChangeTimeLogs, setShowChangeTimeLogs] = useState<boolean>(false);
  const [showDelayedReasonModal, setShowDelayedReasonModal] = useState<any>(
    false
  );
  const getQueryString = useCallback((string: string | null): IChangeUrl => {
    if (!string) return {};
    return string
      .slice(1)
      .split('&')
      .reduce((total: object, current: string) => {
        const res = current.split('=');
        return {
          ...total,
          [res[0]]: res[1],
        };
      }, {});
  }, []);

  const [showMapView, setShowMapView] = useState<boolean>(false);
  const [isClustered, setIsClustered] = useState<boolean>(false);

  const [branch, setBranch] = useState<string>(
    decodeURIComponent(getQueryString(location.search).branch ?? DEFAULT_BRANCH)
  );

  const [appointmentType, setAppointmentType] = useState<string>(
    decodeURIComponent(
      getQueryString(location.search).AppointmentTypes ?? DEFAULT_AT
    )
  );

  let initPlIdParam = getQueryString(location.search).plId ?? '';
  const [plId, setPlId] = useState<string[]>(
    initPlIdParam ? decodeURIComponent(initPlIdParam).split(',') : []
  );

  const [week, setWeek] = useState<number>(
    getQueryString(location.search).week ?? DEFAULT_WEEK
  );

  let teamsParam = getQueryString(location.search).team ?? '';
  const [teams, setTeams] = useState<string[]>(
    teamsParam ? decodeURIComponent(teamsParam).split(',') : []
  );

  let filterParam = getQueryString(location.search).filter ?? '';
  const [filters, setFilters] = useState<string[]>(
    filterParam ? filterParam.split(',') : []
  );
  const [customerSearch, setCustomerSearch] = useState<string>(
    decodeURIComponent(getQueryString(location.search).customerSearch ?? '')
  );
  const [detailDrawerScrollTarget, setDetailDrawerScrollTarget] = useState<
    'notes' | undefined
  >();
  const validateParams = () => {
    //if AppointmentType is selected and doesnt exist, fetch for all appointment types
    if (appointmentType) {
      let selectedAppointmentType = genericProgressTableState.appointmentTypes.filter(
        ({ id }) => id === appointmentType
      );
      if (selectedAppointmentType.length === 0) {
        onAppointmentTypeChange(DEFAULT_AT);
        showNotificationMessage({
          message: intl.formatMessage({
            id: 'error_invalid_filter',
          }),
          notificationType: NotificationType.info,
        });
        navigate(
          {
            pathname: location.pathname,
          },
          { replace: true }
        );
        dispatch(ProgressTableActions.setLoading(false));
        return false;
      }
    }
    let selectedTeams = appointmentsTeamsState.teamGroups[branch];
    if (!selectedTeams) {
      // if the Branch doesnt exist
      // reset to Default branch and clean all search params
      onBranchChange(DEFAULT_BRANCH);
      showNotificationMessage({
        message: intl.formatMessage({
          id: 'error_invalid_filter',
        }),
        notificationType: NotificationType.info,
      });
      navigate(
        {
          pathname: location.pathname,
        },
        { replace: true }
      );
      dispatch(ProgressTableActions.setLoading(false));
      return false;
    }

    // validate all plIDs.
    if (plId.length > 0) {
      let validPlId = plId.filter(
        (id: string) =>
          appointmentsTeamsState.branchTLGroups[branch] &&
          appointmentsTeamsState.branchTLGroups[branch][id]
      );
      if (validPlId.length < plId.length) {
        onPlChange(validPlId);
        showNotificationMessage({
          message: intl.formatMessage({
            id: 'error_invalid_filter',
          }),
          notificationType: NotificationType.info,
        });
        dispatch(ProgressTableActions.setLoading(false));
        return false;
      }
    }

    return true;
  };

  const fetchWeeklyAppointmentsData = () => {
    if (validateParams()) {
      dispatch(
        ProgressTableActions.getWeeklyAppointmentsData({
          week: getWeekNumberForAPI(),
          team: getTeamsListForAPI(),
          filters,
          customerSearch,
          appointmentTypes: appointmentType ? appointmentType : '',
          dashboardType: 'appointment',
        })
      );
    }
  };
  /*
    First API call Fetches the teams data
  */
  useEffect(() => {
    dispatch(ProgressTableActions.setTeamsLoading(true));
    dispatch(ProgressTableActions.getAppointmentTypesData());
    if (hasChangeStatusAccess) {
      dispatch(ProgressTableActions.getAppointmentAndWorkOrderStatusList());
    }
    dispatch(ProgressTableActions.getTeamsData());
  }, [dispatch, hasChangeStatusAccess]);

  useEffect(() => {
    if (
      branchList.length &&
      !genericProgressTableState.loading &&
      canRefetchData &&
      genericProgressTableState.appointmentTypes
    ) {
      dispatch(ProgressTableActions.setLoading(true));
      fetchWeeklyAppointmentsData();
      setCanRefetchData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    branchList,
    branch,
    appointmentType,
    plId,
    week,
    teams,
    filters,
    customerSearch,
    genericProgressTableState.loading,
    genericProgressTableState.appointmentTypes,
  ]);

  useEffect(() => {
    let teamsUrl = getQueryString(location.search).team ?? '',
      filterUrl = getQueryString(location.search).filter ?? '',
      plIdUrl = getQueryString(location.search).plId ?? '';
    let branchParam = decodeURIComponent(
      getQueryString(location.search).branch ?? DEFAULT_BRANCH
    );
    let appointmentTypeParam = decodeURIComponent(
        getQueryString(location.search).AppointmentTypes ?? DEFAULT_AT
      ),
      plIdParam = plIdUrl ? decodeURIComponent(plIdUrl).split(',') : [],
      weekParam = getQueryString(location.search).week ?? DEFAULT_WEEK,
      teamsParam = teamsUrl ? decodeURIComponent(teamsUrl).split(',') : [],
      filterParam = filterUrl ? filterUrl.split(',') : [],
      customerSearchParam = decodeURIComponent(
        getQueryString(location.search).customerSearch ?? ''
      );

    setBranch(branchParam);
    setAppointmentType(appointmentTypeParam);
    setPlId(plIdParam);
    setWeek(weekParam);
    setTeams(teamsParam);
    setFilters(filterParam);
    setCustomerSearch(customerSearchParam);
    if (canRefetchData) {
      setCanRefetchData(true);
    }
    // eslint-disable-next-line
  }, [location.search]);

  useAutoFetchData(() => {
    fetchWeeklyAppointmentsData();
  }, 300000);

  const updateTlTeamsOnBranchChange = (newBranch: string) => {
    const teamGroups = appointmentsTeamsState.teamGroups;
    const branchTLGroups = appointmentsTeamsState.branchTLGroups;
    const tlGroup = branchTLGroups[newBranch];
    const branchTeams = teamGroups[newBranch];

    setLeadIdList(tlGroup ? Object.keys(tlGroup) : []);
    setTeamsList(branchTeams ? branchTeams : []);
  };

  useEffect(() => {
    if (genericProgressTableState.error) {
      showNotificationMessage({
        message: intl.formatMessage({
          id:
            genericProgressTableState.error.errorKey ??
            'error_fetch_apointments',
        }),
        error: genericProgressTableState.error,
        onClose: () =>
          dispatch(ProgressTableActions.resetAppointmentsTableError()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericProgressTableState.error]);

  useEffect(() => {
    if (
      !appointmentsTeamsState.loading &&
      appointmentsTeamsState.appointmentsTeamsData.length
    ) {
      let branches = Object.keys(appointmentsTeamsState.branchTLGroups);
      setBranchList(branches);
      if (branches.length > 0 && branches.indexOf(DEFAULT_BRANCH) === -1) {
        setBranch(branches[0]);
        updateTlTeamsOnBranchChange(branches[0]);
      } else {
        updateTlTeamsOnBranchChange(branch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    genericProgressTableState.error,
    appointmentsTeamsState.branchTLGroups,
    appointmentsTeamsState.loading,
    branch,
  ]);

  useEffect(() => {
    if (selectedAppointment) {
      let updatedSelected = genericProgressTableState.appointmentsTableData.find(
        (app: IAppointment) => app.externalId === selectedAppointment.externalId
      );
      if (
        updatedSelected &&
        JSON.stringify(updatedSelected) !==
          JSON.stringify({
            ...selectedAppointment,
            id: updatedSelected.id,
            key: updatedSelected.key,
          })
      ) {
        setSelectedAppointment(updatedSelected ?? null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericProgressTableState.appointmentsTableData]);

  useEffect(() => {
    dispatch(ProgressTableActions.setDashboardType('appointment'));
    if (window !== undefined && customerSearchRef?.current) {
      window.addEventListener('keydown', function (event: KeyboardEvent) {
        if (
          event.code === 'F3' ||
          (event.ctrlKey && event.code === 'KeyF') ||
          (event.metaKey && event.code === 'KeyF')
        ) {
          event.preventDefault();
          customerSearchRef?.current?.focus({ cursor: 'end' });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSaveLocationSearchParams(routes.genericProgressTable.path);

  const getTeamsListForAPI = (): string[] => {
    if (!appointmentsTeamsState.appointmentsTeamsData?.length && branch) {
      return [];
    }

    let selectedTeams = appointmentsTeamsState.teamGroups[branch];
    if (plId.length > 0) {
      selectedTeams = [].concat(
        ...plId.map(
          (id: string) => appointmentsTeamsState.branchTLGroups[branch][id]
        )
      );
    }
    if (teams && teams.length) {
      return teams;
    }

    return selectedTeams ? selectedTeams.map((team: ITeam) => team.id) : [];
  };

  const getWeekNumberForAPI = (): number => week - DEFAULT_WEEK;

  const onCancelBubble = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onOpenAddressLink: any = (url: string, event: MouseEvent) => {
    onCancelBubble(event);
    window.open(url, '_blank', 'noreferrer noopener');
  };

  const sharedOnCell = (field: any) => ({
    colSpan: field.isGroupHeader ? 0 : 1,
  });

  const getAppointmentDetailsUrl = (
    field?: IAppointment,
    group?: IGroupStatus
  ) => {
    let groupParam =
      field && group && field[group?.name]
        ? `?group=${field[group?.name].groupId}`
        : '';
    return `${
      window.location.origin
    }${routes.appointmentsDetails.pathWithParams(
      routes.ROOT_PAGES.appointments,
      field?.status === 'NotStarted' && field?.previousAppointmentId
        ? field!.previousAppointmentId
        : field!.id
    )}${groupParam}`;
  };

  const renderProgressLink = (progress: number, field?: IAppointment) =>
    !field?.progress ? (
      <Progress
        className={styles.shorten}
        percent={0}
        size="small"
        showInfo={false}
      />
    ) : (
      <Link
        href={getAppointmentDetailsUrl(field)}
        onClick={(event: any) => {
          onCancelBubble(event);
          navigate(
            {
              pathname: routes.appointmentsDetails.pathWithParams(
                routes.ROOT_PAGES.appointments,
                field?.status === 'NotStarted' && field?.previousAppointmentId
                  ? field!.previousAppointmentId
                  : field!.id
              ),
            },
            { state: { url1: location.search } }
          );
        }}
      >
        <Progress
          percent={progress}
          size="small"
          status={
            field.additionalInformation?.appointmentStatus !==
            IAppointmentStatus.cannotComplete
              ? undefined
              : 'exception'
          }
          showInfo
        />
      </Link>
    );

  const renderDelayedLogo = (field: any) => (
    <div
      style={{ display: 'flex' }}
      onClick={(event: any) => {
        onCancelBubble(event);
        setShowDelayedReasonModal(field);
      }}
    >
      <DelayedSvg />
    </div>
  );

  const renderDateField = (
    date: string,
    initialDate: string,
    field: any,
    title: string
  ) => (
    <div
      onClick={(event: any) => {
        onCancelBubble(event);
      }}
    >
      <TimeLogPopover
        selectedField={field}
        setSelectedAppointment={(app: IAppointment) => {
          setShowChangeTimeLogs(true);
          setSelectedAppointment(app);
        }}
        hasWriteAccess={privilegesState[IFeatures.progressWriteDate]}
        handleUpdateTableData={fetchWeeklyAppointmentsData}
      >
        <Space size={4} style={{ display: 'flex' }}>
          {SHOW_TIME_DELAY_BADGE.includes(field.workOrderType.name) ? (
            title === 'start' ? (
              <Badge
                status={field?.isDelayedStart ? 'error' : 'success'}
                style={{ padding: '0 5px' }}
              />
            ) : title === 'end' &&
              field?.isDelayedEnd &&
              !field?.delayedEndReason ? (
              renderDelayedLogo(field)
            ) : (
              <Badge
                status={field?.isDelayedEnd ? 'error' : 'success'}
                style={{ padding: '0 5px' }}
              />
            )
          ) : (
            <div style={{ width: '16px' }}></div>
          )}
          <Text
            {...(date &&
              initialDate &&
              !moment(date).isSame(initialDate, 'minute') && {
                type: 'danger',
                italic: true,
              })}
          >
            <div
              style={{
                width: intl.locale === 'de-DE' ? '28px' : '38px',
                display: 'inline-block',
              }}
            >
              {intl.formatDate(date, { weekday: 'short' }).toUpperCase()}{' '}
            </div>
            {intl.formatTime(date, { timeStyle: 'short', hour12: false })}{' '}
          </Text>
        </Space>
      </TimeLogPopover>
    </div>
  );

  const renderActiveLogo = (field?: any) =>
    field.status === 'Active' ? (
      <CheckedInUsersPopover selectedField={field}>
        <WifiIconActiveSvg />
      </CheckedInUsersPopover>
    ) : (
      <WifiIconNotActiveSvg />
    );

  const renderPLApprovalLogo = (field?: IAppointment) => {
    if (field?.isPlApprovalPending) {
      return (
        <Link
          style={{ display: 'flex' }}
          onClick={(event: any) => {
            onCancelBubble(event);

            navigate(
              {
                pathname: routes.appointmentsDetails.pathWithParams(
                  routes.ROOT_PAGES.appointments,
                  field?.status === 'NotStarted' && field?.previousAppointmentId
                    ? field!.previousAppointmentId
                    : field!.id
                ),
                search: `?status=Submitted,Resubmitted,InReview&group=all&approver=PL`,
              },
              {
                state: { url1: location.search },
              }
            );
          }}
        >
          <VideoSvg status={IStatus.inReview} />
        </Link>
      );
    }
  };

  const openDrawerNotes = (event: any, field: any) => {
    onCancelBubble(event);
    setDetailDrawerScrollTarget('notes');
    setSelectedAppointment(field);
  };

  const notePopoverContent = (note: any, field: any) => (
    <div style={{ padding: '12px 8px 4px 8px', width: '300px' }}>
      <Paragraph>{note.text}</Paragraph>
      <Space style={{ margin: '0 0 12px 0', width: '100%' }}>
        <Text disabled>{note?.updatedBy}</Text>
        <Text disabled>
          {intl.formatDate(note?.updatedAt, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}{' '}
          {intl.formatDate(note?.updatedAt, {
            timeStyle: 'short',
            hour12: false,
          })}
        </Text>
      </Space>
      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button
          type="link"
          className={styles.editingButton}
          onClick={(event: any) => openDrawerNotes(event, field)}
        >
          {field?.totalNotes > 1 ? (
            <span>
              {translation('show_all_notes')}{' '}
              <span style={{ paddingLeft: '6px' }}>({field?.totalNotes})</span>
            </span>
          ) : (
            translation('add_a_note')
          )}{' '}
          <SwapRightOutlined />
        </Button>
      </Space>
    </div>
  );

  const columns = [
    {
      title: translation('active'),
      dataIndex: 'status',
      key: 'status',
      width: '62px',
      fixed: true,
      onCell: (field: any) => ({
        colSpan: !field.isGroupHeader ? 1 : 7,
        className: field.isGroupHeader ? styles.groupHeaderCell : '',
        align: !field.isGroupHeader ? 'center' : 'left',
      }),
      render: (status: string, field: any) =>
        field.isGroupHeader ? (
          <div className={styles.tableTeamNameContainer}>
            <Space size="large" style={{ verticalAlign: 'sub' }}>
              <Text strong>{field.teamName}</Text>
              {field.team?.assignedPlId ? (
                <Text strong>
                  ({appointmentsTeamsState.teamLeads[field.team?.assignedPlId]})
                </Text>
              ) : null}
              <Button
                size="small"
                onClick={(event: any) =>
                  onOpenAddressLink(
                    `http://maps.google.com/maps/dir/${field.routeAddressList
                      .map((address: string) => encodeURIComponent(address))
                      .join('/')}`,
                    event
                  )
                }
              >
                <Space size={2}>
                  <NavigationIcon style={{ marginTop: '4px' }} />
                  <Text style={{ fontSize: '12px', marginLeft: '4px' }}>
                    {translation('view_route')}
                  </Text>
                </Space>
              </Button>
            </Space>
          </div>
        ) : (
          <div
            className={styles.activeColIconsContainer}
            onClick={(event: any) => {
              onCancelBubble(event);
            }}
          >
            {renderActiveLogo(field)}
            {renderPLApprovalLogo(field)}
          </div>
        ),
    },
    {
      title: translation('work_order_type'),
      dataIndex: 'genericAppointmentType',
      key: 'genericAppointmentType',
      width: '150px',
      fixed: true,
      onCell: sharedOnCell,
      render: (appointmentType: string, field: any) => (
        <Space style={{ minWidth: '150px' }}>
          <Text>{appointmentType}</Text>
        </Space>
      ),
    },
    {
      title: translation('remarks'),
      dataIndex: 'serviceAppointmentType',
      key: 'appointmentType',
      width: '80px',
      onCell: sharedOnCell,
      render: (serviceAppointmentType: string, field: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: '80px',
            gap: '6px',
          }}
        >
          {field?.isLastAppointment && (
            <Tooltip title={translation('last_appointment_tooltip')}>
              <div className={styles.center}>
                <LastAppointmentSvg />
              </div>
            </Tooltip>
          )}
          {field?.atRisk && (
            <Tooltip title={translation('at_risk_tooltip')}>
              <div className={styles.center}>
                <AppointmentAtRiskSvg />
              </div>
            </Tooltip>
          )}
          {field?.parallelAppointments?.length > 0 && (
            <Tooltip title={translation('parallel_appointment_tooltip')}>
              <div className={styles.center}>
                <ParallelAppointmentSvg />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: 'Start',
      dataIndex: 'appointmentDate',
      key: 'appointmentDate',
      width: '118px',
      onCell: sharedOnCell,
      render: (appointmentDate: string, field: any) =>
        renderDateField(
          appointmentDate,
          field.appointmentScheduledDate,
          field,
          'start'
        ),
    },
    {
      title: translation('end'),
      dataIndex: 'appointmentEndDate',
      key: 'appointmentEndDate',
      width: '120px',
      onCell: sharedOnCell,
      render: (appointmentEndDate: string, field: any) =>
        renderDateField(
          appointmentEndDate,
          field.appointmentScheduledEndDate,
          field,
          'end'
        ),
    },
    {
      title: translation('appointment_status'),
      dataIndex: 'additionalInformation',
      key: 'appointment_status',
      width: '170px',
      onCell: sharedOnCell,
      render: (info: any, appointment: any) => (
        <AppointmentStatusTag
          type={IAppointmentStatusType.serviceAppointment}
          status={info?.appointmentStatus}
          statusList={genericProgressTableState.appointmentStatusList}
          showTextForOpenStatuses
          appointment={appointment}
          parentNode={scrollContainerRef}
          onSuccess={fetchWeeklyAppointmentsData}
        />
      ),
    },
    {
      title: translation('work_order_status'),
      dataIndex: 'additionalInformation',
      key: 'work_order_status',
      width: '170px',
      onCell: sharedOnCell,
      render: (info: any, appointment: any) => (
        <AppointmentStatusTag
          type={IAppointmentStatusType.workOrder}
          status={info?.workOrderStatus}
          statusList={genericProgressTableState.workOrderStatusList}
          showTextForOpenStatuses
          appointment={appointment}
          parentNode={scrollContainerRef}
          onSuccess={fetchWeeklyAppointmentsData}
        />
      ),
    },
    {
      title: translation('customer'),
      dataIndex: 'name',
      key: 'name',
      width: '180px',
      onCell: (field: any) => ({
        colSpan: field.isGroupHeader ? 3 : 1,
        className: field.isGroupHeader ? styles.groupHeaderCell : '',
      }),
      render: (text: string, field: any) =>
        !field.isGroupHeader ? (
          <div className={styles.flexStart}>
            {field?.isPriorityCustomer && (
              <Tooltip title={translation('priority_customer')}>
                <div className={styles.center} style={{ marginRight: '8px' }}>
                  <PriorityCustomerSvg />
                </div>
              </Tooltip>
            )}
            <Tooltip title={text}>
              <Text ellipsis strong={field?.isPriorityCustomer}>
                {text}
              </Text>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      title: translation('zip'),
      dataIndex: 'address',
      key: 'address',
      width: '80px',
      onCell: sharedOnCell,
      render: (address: string, field: any) => {
        const addressUrl = `http://maps.google.com/maps?q=${encodeURIComponent(
          address
        )}`;
        return (
          <Tooltip title={address}>
            <Link
              onClick={(event: any) => onOpenAddressLink(addressUrl, event)}
              ellipsis
            >
              {field.zip}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      title: translation('progress'),
      dataIndex: 'progress',
      key: 'total',
      width: '260px',
      render: (progress: any, field: any) =>
        !field?.isGroupHeader && renderProgressLink(progress, field),
    },
    {
      title: translation('note'),
      dataIndex: 'note',
      key: 'mostRecentWorkOrderNote',
      width: '250px',
      onCell: sharedOnCell,
      render: (note: any, field: any) => (
        <>
          {note ? (
            <Popover placement="left" content={notePopoverContent(note, field)}>
              <Paragraph
                style={{ marginBottom: 0 }}
                ellipsis={{
                  rows: 1,
                  onExpand: (event: any) => onCancelBubble(event),
                }}
                onClick={(event: any) => openDrawerNotes(event, field)}
              >
                {note.text}
              </Paragraph>
            </Popover>
          ) : (
            <Button
              type="link"
              style={{ width: '230px' }}
              onClick={(event: any) => openDrawerNotes(event, field)}
            ></Button>
          )}
        </>
      ),
    },
  ];

  const changeUrl = ({
    weekNumber = week,
    branchId = branch,
    appointmentTypeId = appointmentType,
    selectedPlId = plId.join(','),
    team = teams.join(','),
    customerSearchString = customerSearch,
    filter = filters.join(','),
  }) => {
    const weekUrl =
      weekNumber || weekNumber === 0
        ? `&week=${weekNumber}`
        : `&week=${DEFAULT_WEEK}`;
    const branchUrl = branchId ? `&branch=${branchId}` : '';
    const plIdUrl = selectedPlId ? `&plId=${selectedPlId}` : '';
    const teamUrl = team ? `&team=${team}` : '';
    const filterUrl = filter ? `&filter=${filter}` : '';
    const customerSearchUrl = customerSearchString
      ? `&customerSearch=${customerSearchString}`
      : '';
    const appointmentTypeUrl = appointmentTypeId
      ? `&AppointmentTypes=${appointmentTypeId}`
      : '';
    const url = `${weekUrl}${branchUrl}${plIdUrl}${teamUrl}${appointmentTypeUrl}${filterUrl}${customerSearchUrl}`.slice(
      1
    );

    navigate({
      pathname: location.pathname,
      search: `?${url}`,
    });
  };

  const onBranchChange = (val: string) => {
    setCanRefetchData(true);
    updateTlTeamsOnBranchChange(val);
    changeUrl({
      branchId: val,
      selectedPlId: '',
      team: '',
    });
  };

  const onAppointmentTypeChange = (val: string) => {
    setCanRefetchData(true);
    changeUrl({
      appointmentTypeId: val,
      selectedPlId: '',
      team: '',
    });
  };

  const onPlChange = (val: string[]) => {
    setCanRefetchData(true);
    changeUrl({
      selectedPlId: val.join(','),
      team: '',
    });
  };

  const onCalendarWeekChanged = (val: number) => {
    setCanRefetchData(true);
    val = val - 6;
    changeUrl({
      weekNumber: val,
    });
  };

  const onTeamsFilterItemSelect = (value: string[]) => {
    setCanRefetchData(true);
    changeUrl({
      selectedPlId: '',
      team: value.join(','),
    });
  };

  const onResetTeamFilter = (): void => {
    setCanRefetchData(true);
    changeUrl({
      selectedPlId: '',
      team: '',
    });
  };
  const onResetAllFilters = (): void => {
    setCanRefetchData(true);
    changeUrl({
      weekNumber: 0,
      branchId: branch,
      selectedPlId: '',
      customerSearchString: '',
      team: '',
      filter: '',
    });
  };

  const onChangeFilter = ({
    value,
    values,
    options,
    ignoreDispatch = false,
  }: IOnChangeFilterType) => {
    setCanRefetchData(false);
    setFilters((state) => {
      if (value) {
        const index = state.indexOf(value);
        if (index > -1) {
          state.splice(index, 1);
        } else if (value) {
          state.push(value);
        }
      } else if (values && options) {
        state = [
          ...state.filter((item: string) => !options.includes(item)),
          ...values,
        ];
      }

      if (!ignoreDispatch) {
        dispatch(ProgressTableActions.applyFilters(state, customerSearch));
        changeUrl({
          filter: state.join(','),
        });
      }

      return state;
    });
  };

  const onCustomerSearchChanged = (val: string) => {
    setCanRefetchData(false);
    setCustomerSearch((state) => {
      dispatch(ProgressTableActions.applyFilters(filters, val));
      changeUrl({
        customerSearchString: val,
      });
      return val;
    });
  };

  const [vt] = useVT(
    () => ({
      scroll: { y: scrollHeight },
    }),
    []
  );

  return (
    <AppLayout>
      <Layout className={styles.contentLayout}>
        <ProgressHeader
          onCalendarWeekChanged={onCalendarWeekChanged}
          pageTitle={intl.formatMessage({
            id: 'appointments',
          })}
          week={week}
          filterTree={genericProgressTableState.filterTree}
          filters={filters}
          customerSearch={customerSearch}
          onChangeFilter={onChangeFilter}
          onResetAllFilters={onResetAllFilters}
          plId={plId}
          teams={teams}
          loading={
            genericProgressTableState.loading || appointmentsTeamsState.loading
          }
          handleChangeUrl={changeUrl}
        />
        <Content className={styles.wrapper} ref={scrollContainerRef}>
          <div className={styles.filtersContainer}>
            <ProgressFilters
              progressDashboardType="generic"
              onCancelBubble={onCancelBubble}
              loading={
                genericProgressTableState.loading ||
                appointmentsTeamsState.loading
              }
              appointmentTypes={genericProgressTableState.appointmentTypes}
              appointmentType={appointmentType}
              onAppointmentTypeChange={onAppointmentTypeChange}
              branchList={branchList}
              branch={branch}
              onBranchChange={onBranchChange}
              leadIdList={leadIdList}
              teamLeads={appointmentsTeamsState.teamLeads}
              plId={plId}
              onPlChange={onPlChange}
              teamsList={teamsList}
              teams={teams}
              onTeamsFilterItemSelect={onTeamsFilterItemSelect}
              onResetTeamFilter={onResetTeamFilter}
            />
          </div>
          <div className={styles.tableContent}>
            <Space
              direction="horizontal"
              className={styles.progressCounter}
              size="large"
            >
              <Row>
                <Row
                  className={cName({
                    [styles.flexJustified]: true,
                    [styles.compact]: showMapView,
                  })}
                  key="scheduled_appointments_row_total"
                >
                  <Col style={{ marginRight: '8px' }}>
                    <Text strong>{translation('appointments_count')}</Text>
                  </Col>
                  <Col>
                    <Text strong>
                      {genericProgressTableState.filteredTotal}
                    </Text>
                  </Col>
                </Row>
                <Row
                  key="scheduled_appointments_row_progress"
                  className={cName({
                    [styles.flexJustified]: true,
                    [styles.compact]: showMapView,
                  })}
                >
                  <Progress
                    percent={parseInt(
                      String(
                        (genericProgressTableState.completed /
                          genericProgressTableState.filteredTotal) *
                          100
                      )
                    )}
                    steps={50}
                    size="small"
                  />
                </Row>

                <Row style={{ display: 'flex', gap: '16px' }}>
                  <FilterRow
                    type="completed"
                    count={
                      genericProgressTableState.flatFilter.completed?.filterIds
                        ?.length
                    }
                    selectedFilters={filters}
                    loading={!genericProgressTableState.appointmentTypes.length}
                    onChangeFilter={onChangeFilter}
                  />
                  <FilterRow
                    type="active_appointments"
                    count={
                      genericProgressTableState.flatFilter.active_appointments
                        ?.filterIds?.length
                    }
                    selectedFilters={filters}
                    loading={!genericProgressTableState.appointmentTypes.length}
                    onChangeFilter={onChangeFilter}
                  />
                </Row>
              </Row>
              <Row
                key="switch_map_list_view"
                className={styles.flexJustified}
                style={{ justifyContent: 'flex-end', width: '100%' }}
              >
                <Search
                  ref={customerSearchRef}
                  style={{ width: '230px', marginRight: '16px' }}
                  placeholder={intl.formatMessage({
                    id: 'search',
                  })}
                  value={customerSearch}
                  onChange={(event: any) => {
                    let value = event.target.value;
                    setCustomerSearch(event.target.value);
                    if (!value) {
                      onCustomerSearchChanged(value);
                    }
                  }}
                  allowClear
                  onSearch={(val) =>
                    val ? onCustomerSearchChanged(val) : null
                  }
                />
                {showMapView && (
                  <Checkbox
                    style={{ marginRight: '8px' }}
                    onChange={(e) => setIsClustered(e.target.checked)}
                    checked={isClustered}
                  >
                    Clustered View
                  </Checkbox>
                )}
                <Button
                  onClick={() => setShowMapView(true)}
                  className={
                    showMapView ? styles.optionActive : styles.optionInactive
                  }
                >
                  <MapsSvg />
                </Button>
                <Button
                  onClick={() => setShowMapView(false)}
                  className={
                    showMapView ? styles.optionInactive : styles.optionActive
                  }
                >
                  <ListSvg />
                </Button>
              </Row>
            </Space>
            {showMapView ? (
              <MapContainer
                isClustered={isClustered}
                appointments={genericProgressTableState.appointmentsTableData}
                setDrawerAppointment={setSelectedAppointment}
              />
            ) : (
              <Table
                className={styles.table}
                columns={columns}
                dataSource={genericProgressTableState.appointmentsTableData}
                size="small"
                pagination={false}
                showHeader
                sticky
                bordered={false}
                loading={
                  genericProgressTableState.loading ||
                  appointmentsTeamsState.loading
                }
                scroll={{ x: '1vw', y: scrollHeight }}
                components={vt}
                onRow={(record: IAppointment) => {
                  return {
                    onClick: () => {
                      if (!record.isGroupHeader) {
                        setSelectedAppointment(record);
                      }
                    },
                  };
                }}
                rowClassName={(record) =>
                  classNames('rowItem', {
                    [styles.tableRowItemHighlighted]: record.questions,
                    [styles.isGroupHeader]: record.isGroupHeader,
                  })
                }
              />
            )}
          </div>
        </Content>
      </Layout>
      <WidgetDelayAndStatusModal
        appointment={showDelayedReasonModal}
        modalOpen={showDelayedReasonModal}
        setModalOpen={setShowDelayedReasonModal}
        type="delay"
        cannotCompleteReasonCodeList={null}
        onCancel={(reloadCache: boolean = false) => {
          setShowDelayedReasonModal(false);
          setCanRefetchData(false);
          if (reloadCache) fetchWeeklyAppointmentsData();
        }}
      />
      <DetailDrawer
        selectedAppointment={selectedAppointment}
        showChangeTimeLogs={showChangeTimeLogs}
        rootPageRoute={routes.ROOT_PAGES.appointments}
        onClose={(reloadCache: boolean = false) => {
          setSelectedAppointment(null);
          setShowChangeTimeLogs(false);
          setCanRefetchData(false);
          setDetailDrawerScrollTarget(undefined);
          if (reloadCache) fetchWeeklyAppointmentsData();
        }}
        scrollTo={detailDrawerScrollTarget}
        appointmentStatusList={genericProgressTableState.appointmentStatusList}
      />
    </AppLayout>
  );
};

export default GenericProgress;
