import { IGNORED_FIELDS } from './types';

export type Question = {
  type: string;
  label: string;
  formFieldId: string;
  answer?: string[];
  status?: string;
  legalText?: string;
  protocolText?: string;
  allAnswers?: { answer: string[]; status: string }[];
  questions?: Question[];
};

export const downloadImages = (images: string[] | undefined) => {
  if (images) {
    images.forEach(async (image, index) => {
      const fetchedImage = await fetch(image);
      const imageBlob = await fetchedImage.blob();
      const fileType = image.substring(image.lastIndexOf('.') + 1);
      const fileName = `image-${index}.${fileType}`;
      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileName);
      const href = URL.createObjectURL(imageBlob);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
  }
};

export async function compressImage(imageSrc: string, toWidth: number) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    // Could not compress the image, fall back to the uncompressed image. This
    // really only should happen during testing in a fake DOM since all modern
    // browsers do support `canvas`.
    return imageSrc;
  }

  const image = await loadImage(imageSrc);

  const { width, height } = image;
  const ratio = width / height;

  canvas.width = toWidth;
  canvas.height = canvas.width / ratio;
  const scale = canvas.width / width;

  ctx.scale(scale, scale);
  ctx.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL('image/jpeg');
}

function loadImage(imageSrc: string) {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  return new Promise<HTMLImageElement>((resolve) => {
    image.onload = () => {
      resolve(image);
    };
    image.src = imageSrc;
  });
}

export const filterIgnoredFields = (questions: any): any =>
  questions.map((chapter: any) => ({
    ...chapter,
    questions: chapter.questions.filter(
      (question: any) => !IGNORED_FIELDS.includes(question.type)
    ),
  }));
