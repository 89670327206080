import { FC, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Avatar,
  Typography,
  Divider,
  Space,
  Form,
  Input,
  Button,
  DatePicker,
  ConfigProvider,
} from 'antd';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import updateLocale from 'dayjs/plugin/updateLocale';
import { SwapRightOutlined, DownOutlined } from '@ant-design/icons';
import { StopWatchIcon } from '../../../../components/icons';
import translation from '../../../../i18n/translation';
import styles from '../detailDrawer.module.scss';
import { useIntl } from 'react-intl';
import deLocale from 'antd/es/locale/de_DE';
import locale from 'antd/es/locale/en_GB';
import { AppointmentType } from '../../../../constants/types';
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});
const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  isLatest: boolean;
  editMode?: boolean;
  handleEditMode?: () => void;
  handleSubmit?: (arg1: any) => any;
  timeLog: any;
  appointment: AppointmentType | null;
  isPopover?: boolean;
}

const ChangeLog: FC<Props> = ({
  isLatest,
  editMode,
  handleEditMode,
  handleSubmit,
  timeLog,
  appointment,
  isPopover = false,
}) => {
  const intl = useIntl();
  const languageSetting = intl.locale;
  languageSetting === 'de-DE' ? dayjs.locale('de') : dayjs.locale('en');
  const [form] = Form.useForm();
  const [startValue, setStartValue] = useState<any | null>(null);
  const [endValue, setEndValue] = useState<any | null>(null);
  const [loadingSaveTimeLog, setLoadingSaveTimeLog] = useState(false);
  const [errorSaveTimeLog, setErrorSaveTimeLog] = useState<boolean | string>(
    false
  );
  const [isSubmitButtonActive, setIsSubmitButtonActive] = useState<boolean>(
    false
  );
  const [isFormAttached, setIsFormAttached] = useState(
    isPopover ? false : undefined
  );

  useEffect(() => {
    if (timeLog?.newStartDate && timeLog?.newEndDate && !!isLatest) {
      setStartValue(dayjs(timeLog.newStartDate));
      setEndValue(dayjs(timeLog.newEndDate));
    }
  }, [isLatest, timeLog?.newStartDate, timeLog?.newEndDate, editMode]);

  useEffect(() => {
    checkStartAndEndValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startValue, endValue]);

  const checkStartAndEndValues = () => {
    if (!startValue && !endValue) {
      setIsSubmitButtonActive(false);
      setErrorSaveTimeLog(false);
    } else {
      checkCommentInputValueTruthy()
        ? setIsSubmitButtonActive(true)
        : setIsSubmitButtonActive(false);
    }
  };

  const onStartChange = (time: any) => {
    setStartValue(time);
    if (!!time) {
      if (isPopover) setIsFormAttached(true);
      setIsSubmitButtonActive(
        time?.$d?.toISOString() !== timeLog?.newStartDate
      );
      setErrorSaveTimeLog(false);
    } else {
      if (endValue && endValue?.$d?.toISOString() === timeLog?.newEndDate) {
        setIsSubmitButtonActive(false);
      }
    }
  };

  const onEndChange = (time: any) => {
    setEndValue(time);
    if (!!time) {
      if (isPopover) setIsFormAttached(true);
      setIsSubmitButtonActive(time?.$d?.toISOString() !== timeLog?.newEndDate);
      setErrorSaveTimeLog(false);
    } else {
      if (
        startValue &&
        startValue?.$d?.toISOString() === timeLog?.newStartDate
      ) {
        setIsSubmitButtonActive(false);
      }
    }
  };

  const checkCommentInputValueTruthy = () => {
    let values = form.getFieldsValue();
    if (!values?.comment || values?.comment?.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const onFinish = async (values: any) => {
    if (!endValue?.$d && !startValue?.$d) {
      setErrorSaveTimeLog(intl.formatMessage({ id: 'check_time_log_input' }));
    } else {
      if (
        startValue?.$d
          ? dayjs(startValue?.$d?.toISOString()).isAfter(
              endValue?.$d ? endValue?.$d?.toISOString() : timeLog?.newEndDate
            )
          : dayjs(timeLog?.newStartDate).isAfter(endValue?.$d?.toISOString())
      ) {
        setErrorSaveTimeLog(
          intl.formatMessage({ id: 'check_time_log_end_start_input' })
        );
      } else {
        setLoadingSaveTimeLog(true);
        const newTimeLogObject: any = {
          comment: values.comment,
          startDate: startValue?.$d
            ? startValue?.$d?.toISOString()
            : timeLog?.newStartDate,
          endDate: endValue?.$d
            ? endValue?.$d?.toISOString()
            : timeLog?.newEndDate,
        };
        if (handleSubmit) {
          const res = await handleSubmit(newTimeLogObject);
          setIsFormAttached(false);
          setLoadingSaveTimeLog(res.loading);
          setErrorSaveTimeLog(res.error);
          setStartValue(null);
          setEndValue(null);
          form.resetFields();
        }
      }
    }
  };
  const onSubmit = () => {
    let values = form.getFieldsValue();
    if (!values?.comment || values?.comment?.length === 0) {
      setErrorSaveTimeLog(intl.formatMessage({ id: 'leave_a_comment' }));
    } else {
      onFinish(values);
    }
  };

  const onHandleCancel = () => {
    setErrorSaveTimeLog(false);
    setLoadingSaveTimeLog(false);
    form.resetFields();
    if (isPopover) {
      setIsFormAttached(false);
      setStartValue(dayjs(timeLog.newStartDate));
      setEndValue(dayjs(timeLog.newEndDate));
    }

    if (handleEditMode) handleEditMode();
  };
  const disabledDate = (current: any) =>
    current && dayjs(timeLog?.newEndDate).week() !== dayjs(current).week();

  const renderDatePicker = (value: any, onChange: any) => (
    <ConfigProvider locale={languageSetting === 'de-DE' ? deLocale : locale}>
      <DatePicker
        suffixIcon={<DownOutlined />}
        showTime={{ minuteStep: 15 }}
        placeholder="--:--"
        showNow
        format="ddd HH:mm"
        style={{ width: '120px' }}
        value={value}
        onChange={onChange}
        disabledDate={disabledDate}
      />
    </ConfigProvider>
  );

  return (
    <Col>
      {editMode && (
        <Row>
          {!isPopover && (
            <Col flex="none">
              <Avatar
                icon={<StopWatchIcon />}
                className={styles.isLatestAvatar}
              />
            </Col>
          )}
          <Col flex="auto" style={{ maxWidth: '550px' }}>
            {!isPopover && (
              <div className={styles.changeLogContentLine}>
                <Space
                  size="middle"
                  align="center"
                  style={{ margin: '0 0 6px 0' }}
                >
                  <Text strong>{translation('change_appointment_time')}</Text>
                </Space>
              </div>
            )}
            <div className={styles.changeLogContentLine}>
              <Row align="middle">
                <Col flex="50px">
                  <Text
                    strong
                    type={!isLatest && !isPopover ? 'secondary' : undefined}
                  >
                    Start:
                  </Text>
                </Col>
                <Space size="middle" align="center">
                  {renderDatePicker(startValue, onStartChange)}
                </Space>
              </Row>
            </div>

            <div className={styles.changeLogContentLine}>
              <Row align="middle">
                <Col flex="50px">
                  <Text
                    strong
                    type={!isLatest && !isPopover ? 'secondary' : undefined}
                  >
                    {translation('end')}:
                  </Text>
                </Col>

                <Space size="middle" align="center">
                  {renderDatePicker(endValue, onEndChange)}
                </Space>
              </Row>
            </div>
            {(!isPopover || (isPopover && isFormAttached)) && (
              <div className={styles.changeLogContentLine}>
                <Form form={form} onFinish={undefined}>
                  <Form.Item
                    name="comment"
                    rules={[
                      {
                        type: 'string',
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <TextArea
                      showCount
                      maxLength={100}
                      rows={2}
                      style={{ marginTop: '8px' }}
                      onChange={(e) =>
                        !e?.target?.value
                          ? setIsSubmitButtonActive(false)
                          : errorSaveTimeLog
                          ? setErrorSaveTimeLog(false)
                          : checkStartAndEndValues()
                      }
                    />
                  </Form.Item>
                  {!!errorSaveTimeLog && (
                    <Text type="danger">{errorSaveTimeLog}</Text>
                  )}
                  <Form.Item>
                    <div className={styles.noteFormActionsContainer}>
                      <Space>
                        <Button size="small" onClick={onHandleCancel}>
                          Cancel
                        </Button>
                        <Button
                          id={`ga-submit-timechange-isPopover-${isPopover}`}
                          size="small"
                          type="primary"
                          htmlType="button"
                          loading={loadingSaveTimeLog}
                          disabled={!isSubmitButtonActive}
                          onClick={() => onSubmit()}
                        >
                          {translation('change_appointment_time')}
                        </Button>
                      </Space>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            )}
          </Col>
        </Row>
      )}
      <Row>
        {!isPopover && (
          <Col flex="none">
            <Avatar
              icon={<StopWatchIcon />}
              className={
                isLatest && !editMode ? styles.isLatestAvatar : styles.avatar
              }
            />
          </Col>
        )}
        <Col flex="auto" style={{ maxWidth: '550px' }}>
          {((isLatest && editMode) || !isLatest) && (
            <Divider className={styles.divider} />
          )}
          {!timeLog?.oldEndDate && !timeLog?.oldStartDate ? (
            <div className={styles.changeLogContentLine}>
              <Space size="middle" align="center">
                <Text strong type={!isLatest ? 'secondary' : undefined}>
                  {' '}
                  {translation('scheduled')}{' '}
                </Text>
              </Space>
            </div>
          ) : !isPopover ? (
            <div className={styles.changeLogContentLine}>
              <Space size="middle" align="center">
                <Text
                  strong
                  type={
                    (isLatest && editMode) || !isLatest
                      ? 'secondary'
                      : undefined
                  }
                >
                  {intl.formatDate(timeLog?.logDate, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </Text>
                <Text type="secondary">
                  {intl.formatTime(timeLog?.logDate, {
                    timeStyle: 'short',
                    hour12: false,
                  })}
                </Text>
                <Text type="secondary">{timeLog?.userInfo?.name}</Text>
              </Space>
            </div>
          ) : null}

          <div className={styles.changeLogContentLine}>
            <Row align="middle">
              <Col flex="50px">
                <Text
                  strong
                  type={
                    (isLatest && editMode && !isPopover) || !isLatest
                      ? 'secondary'
                      : undefined
                  }
                >
                  Start:
                </Text>
              </Col>
              <Col>
                <Space size="middle" align="center">
                  {!!timeLog?.oldStartDate &&
                    timeLog?.oldStartDate !== timeLog?.newStartDate && (
                      <Space className={styles.verticalAlignment}>
                        <Text type="secondary" delete>
                          {intl
                            .formatDate(timeLog?.oldStartDate, {
                              weekday: 'short',
                            })
                            .toUpperCase()}{' '}
                          {intl.formatTime(timeLog?.oldStartDate, {
                            timeStyle: 'short',
                            hour12: false,
                          })}
                        </Text>
                        <SwapRightOutlined />
                      </Space>
                    )}

                  <Text
                    type={
                      (isLatest && editMode && !isPopover) || !isLatest
                        ? 'secondary'
                        : undefined
                    }
                  >
                    {intl
                      .formatDate(timeLog?.newStartDate, {
                        weekday: 'short',
                      })
                      .toUpperCase()}{' '}
                    {intl.formatTime(timeLog?.newStartDate, {
                      timeStyle: 'short',
                      hour12: false,
                    })}
                  </Text>
                </Space>
              </Col>
            </Row>
          </div>
          <div className={styles.changeLogContentLine}>
            <Row align="middle">
              <Col flex="50px">
                <Text
                  strong
                  type={
                    (isLatest && editMode && !isPopover) || !isLatest
                      ? 'secondary'
                      : undefined
                  }
                >
                  {translation('end')}:
                </Text>
              </Col>
              <Col>
                <Space size="middle" align="center">
                  {!!timeLog?.oldEndDate &&
                    timeLog?.oldEndDate !== timeLog?.newEndDate && (
                      <Space className={styles.verticalAlignment}>
                        <Text type="secondary" delete>
                          {intl
                            .formatDate(timeLog?.oldEndDate, {
                              weekday: 'short',
                            })
                            .toUpperCase()}{' '}
                          {intl.formatTime(timeLog?.oldEndDate, {
                            timeStyle: 'short',
                            hour12: false,
                          })}
                        </Text>
                        <SwapRightOutlined />
                      </Space>
                    )}

                  <Text
                    type={
                      (isLatest && editMode && !isPopover) || !isLatest
                        ? 'secondary'
                        : undefined
                    }
                  >
                    {intl
                      .formatDate(timeLog?.newEndDate, {
                        weekday: 'short',
                      })
                      .toUpperCase()}{' '}
                    {intl.formatTime(timeLog?.newEndDate, {
                      timeStyle: 'short',
                      hour12: false,
                    })}
                  </Text>
                </Space>
              </Col>
            </Row>
          </div>
          <div className={styles.changeLogContentLine}>
            {!timeLog?.oldEndDate && !timeLog?.oldStartDate ? (
              <Text type="secondary" style={{ fontSize: '13px' }}>
                {intl.formatDate(timeLog.logDate, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}{' '}
                {intl.formatTime(timeLog?.logDate, {
                  timeStyle: 'short',
                  hour12: false,
                })}
              </Text>
            ) : (
              <Text
                type={isPopover && isLatest ? undefined : 'secondary'}
                style={{ fontSize: '13px' }}
              >
                {timeLog?.comment}
              </Text>
            )}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default ChangeLog;
