import { Badge, Empty, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { compressImage } from '../../helpers';
import styles from '../../protocol.module.scss';
import { Question } from '../../helpers';
import DOMPurify from 'dompurify';
import { getStatusColor } from '../../../../utils/helpers';
import { EMPTY_ANSWER } from '../../types';

const { Text } = Typography;
const IMAGE_FIELDS = ['Signature', 'ImageUpload'];

export const NoImage = () => (
  <Empty description={false} imageStyle={{ height: 60, width: 60 }} />
);

const shrunkImageWidth = 500;

const SimpleImages = ({
  images,
  compress = true,
}: {
  images: string[] | undefined;
  compress?: boolean;
}) => {
  const [compressedImages, setCompressedImages] = useState<string[] | null>(
    null
  );

  useEffect(() => {
    if (!images || !compress) return;
    Promise.all(images.map((i) => compressImage(i, shrunkImageWidth))).then(
      setCompressedImages
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, setCompressedImages]);

  if (!images) {
    return <NoImage />;
  }

  const displayedImages = compressedImages ?? images;

  return (
    <div className={styles.answerContainer}>
      {displayedImages.map((image, i) => {
        return (
          <div className={styles.pageBreak} key={`compressed-img-${i}`}>
            <img
              className={styles.simpleImage}
              src={image}
              alt="thumbnail - answer to question"
            />
          </div>
        );
      })}
    </div>
  );
};

const renderAnswer = (answers: string[], type: string) =>
  answers ? (
    IMAGE_FIELDS.includes(type) ? (
      <SimpleImages images={answers} compress={type !== 'Signature'} />
    ) : (
      answers?.map((answer: any, answerIndex: number) => (
        <Text type="secondary" key={answerIndex}>
          {type === 'Input' ? (
            <div
              id="html-marked-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(answer || EMPTY_ANSWER),
              }}
            />
          ) : (
            answer || EMPTY_ANSWER
          )}
        </Text>
      ))
    )
  ) : (
    <Text type="secondary">{EMPTY_ANSWER}</Text>
  );

export const FieldValue = ({
  question,
  showAllSubmissions,
}: {
  question: Question;
  showAllSubmissions: boolean;
}) => (
  <div>
    {question?.legalText && (
      <Text
        type="secondary"
        key={question?.legalText}
        className={styles.submissionContainer}
      >
        {question?.legalText}
      </Text>
    )}
    {showAllSubmissions ? (
      question.allAnswers?.reverse().map((item: any, i: number) => {
        const hasMultipleAnswers =
          question.allAnswers && question.allAnswers?.length > 1;

        return (
          <div className={styles.submissionContainer} key={crypto.randomUUID()}>
            {hasMultipleAnswers && (
              <Space>
                <Text>
                  <FormattedMessage
                    values={{
                      answerNumber: i + 1,
                    }}
                    id="Submission {answerNumber}"
                  />
                </Text>
                <Badge color={getStatusColor(item.status)} />
              </Space>
            )}
            {renderAnswer(item?.answer, question.type)}
          </div>
        );
      })
    ) : (
      <div className={styles.submissionContainer} key={crypto.randomUUID()}>
        {renderAnswer(question.answer!, question.type)}
      </div>
    )}
  </div>
);
